<template>
    <v-container fluid class="app-container">
        <app-title-bar
                :title="page.title" :items="page.items">
        </app-title-bar>
        <v-card class="mt-6"
                v-if="!waitResultFlag && calculation && calculation.result && !calculation.error">
            <v-container class="mt-6" fluid>
            <AceEditor
                    class="jsonEditor mt-4"
                    ref="editorEl"
                    v-model="calculationResult"
                    @init="editorInit"
                    lang="json" theme="chrome"
                    height="450px"
            ></AceEditor>
            </v-container>
        </v-card>
        <v-card class="mt-6" v-if="!waitResultFlag && calculation && calculation.result && !calculation.error">
            <v-row align="center">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="ml-6 mr-4 mt-2 mb-2 primary" style="color: #FFFFFF" icon @click="jsonDownload()"
                               v-bind="attrs"
                               v-on="on">
                            <v-icon>
                                mdi-download
                            </v-icon>
                        </v-btn>
                    </template>
                    {{$t('ui.general.downloadJSON')}}
                </v-tooltip>
                <h5 class="mt-2">
                    {{$t('ui.general.downloadJSON')}}</h5>
            </v-row>
        </v-card>
        <v-card class="mt-6" v-if="calculation && calculation.result && !calculation.error && graphFlag">
            <v-container class="mt-6" fluid>
                <v-row class="ma-4">
                    <h3>{{$t('ui.calculation.graph')}}</h3>
                </v-row>
                <v-row>
                    <v-col lg="12" md="12" sm="12">
                        <apexchart id="chart1" :height="372" type="line" :options="chartOptions" :series="series"></apexchart>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card class="mt-6" v-if="calculation && calculation.result && !calculation.error && graphFlag">
            <v-container class="mt-6" fluid>
                <v-row class="ma-4">
                    <h3>{{$t('ui.calculation.graph')}}</h3>
                </v-row>
                <v-row>
                    <v-col lg="12" md="12" sm="12">
                        <apexchart id="chart2" :height="372" type="area" :options="chartOptions1" :series="series1"></apexchart>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card v-if="waitResultFlag" class="mt-6">
            <v-row align="center" class="pa-2">
                <v-container fluid>
                    <v-alert text outlined
                             height="100px"
                             prominent
                             type="warning"
                             icon="mdi-clock-fast">
                        <v-row align="center" no-gutters class="mt-2">
                            <v-col class="grow">
                                {{$t('ui.calculation.waitResult')}}
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="shrink">
                                <v-btn class="mt-2 mr-5" color="warning"
                                       outlined icon @click="getCalculationDetail()">
                                    <v-icon>
                                        refresh
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                    <v-progress-linear v-if="resultLoading"
                                       indeterminate
                                       color="yellow darken-2"
                    ></v-progress-linear>
                </v-container>
            </v-row>
        </v-card>
        <v-card v-if="!waitResultFlag && errorMessage" class="mt-6">
            <v-row align="center" class="pa-2">
                <v-container fluid>
                    <v-alert text outlined
                             height="100px"
                             prominent
                             type="error">
                        <v-row align="center" no-gutters class="mt-2">
                            <v-col class="grow">
                                {{errorMessage}}
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                    </v-alert>
                </v-container>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    import moment from "moment";
    import AceEditor from 'vue2-ace-editor';
    // import {LineChart} from "@/utils/Charts"

    export default {
        components: {
            AceEditor,
            // LineChart
        },
        data() {
            return {
                userInfo: this.$store.getters['core/getUserInfo'],
                calculationPid: this.$route.params.calculationPid,
                userPid: this.$route.params.userPid,
                apiSecret: null,
                isSystemAdmin: this.$store.getters['core/getUserInfo'].isSystemAdmin,
                apiKey: null,
                waitResultFlag: false,
                resultLoading: false,
                urlPrefix: (this.$route.meta && this.$route.meta.permission === 'admin') ? '/admin' : '',
                calculation: null,
                calculationResult: null,
                items: [],
                flag: false,
                graphFlag: false,
                loading: false,
                errorMessage: null,
                editorOptions: {
                    mode: 'code'
                },

                //graph

                series: [{
                    name: "Rate",
                    data: []
                }],
                chartOptions: {
                    chart: {
                        id: 'fb',
                        group: 'social',
                        type: 'line',
                        height: 160
                    },
                    // stroke: {
                    //     width: [5],
                    //     curve: 'straight',
                    //     dashArray: [5]
                    // },
                    colors: ['#5D92F4'],
                    yaxis: {
                        show: true,
                        tickAmount: 10,
                        labels: {
                            show: true,
                            formatter: function (val) {
                                return (val).toFixed(6) + '...';
                            },
                        }

                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            show: true
                        }
                    },
                    tooltip: {
                        shared: true,
                        y: {
                            formatter: function (val) {
                                return val
                            },
                        }
                    }
                },

                series1: [{
                    name: "Rate",
                    data: []
                }],
                chartOptions1: {
                    chart: {
                        id: 'fdd',
                        group: 'social',
                        type: 'line',
                        height: 160
                    },
                    dataLabels: {
                        enabled: true,
                        // style: {
                        //     colors: ['#333']
                        // },
                        formatter: function () {
                            return "-"
                        },
                        textAnchor: 'middle',

                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            show: true,
                        },
                    },
                    yaxis: {
                        show: true,
                        tickAmount: 10
                    },
                    tooltip: {
                        shared: true,
                        y: {
                            formatter: function (val) {
                                return val
                            },
                        }
                    },
                    colors: ['#5D92F4']
                },

                headers: [],
                page: {
                    title: null,
                    items: [
                        {
                            text: this.$t('ui.calculation.header'),
                            exact: true,
                            to: this.$route.meta.permission === 'admin' ? `/admin/users/${this.$route.params.userPid}` : '/calculations'
                        }, {
                            text: this.$t('ui.calculation.detail'),
                        }
                    ]
                },
            }
        },

        methods: {

            editorInit: function (editor) {
                require("brace/mode/json");
                require("brace/theme/chrome");
                require('brace/ext/searchbox');
                editor.focus();
                editor.setShowPrintMargin(false);
                editor.setOptions({
                    fontSize: "18px",
                    readOnly: true
                });
            },

            async getCalculationDetail() {
                try {
                    this.resultLoading = true;
                    let result = this.isSystemAdmin ? await this.$http.get(`${this.urlPrefix}/users/${this.userPid}/tasks/${this.calculationPid}`) :
                        await this.$http.get(`${this.urlPrefix}/tasks/${this.calculationPid}`, {
                            headers: {
                                'ekx-api-secret': this.apiSecret,
                                'ekx-api-key': this.apiKey
                            }
                        });

                    result.data.requestTime = moment.utc(result.data.requestTime, "YYYY-MM-DD H:mm")
                        .toISOString();
                    result.data.requestTime = moment(String(result.data.requestTime)).format(
                        "DD.MM.YYYY"
                    );

                    this.calculation = result.data;
                    if (this.calculation.result) {
                        this.calculationResult = JSON.stringify(JSON.parse(this.calculation.result), null, 4);
                    }
                    this.headers = [];
                    this.items = [];
                    this.flag = false;
                    this.graphFlag = false;

                    let temData = {
                        data: [{
                            'date': (new Date(new Date(2021, 3, 12).getTime())).toLocaleDateString("en-US"),
                            'rate': 0.0009598797001007738
                        },
                            {
                                'date': (new Date(new Date(2021, 6, 14).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.0009598797001007738
                            },
                            {
                                'date': (new Date(new Date(2021, 9, 13).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.0009597632778510886
                            },
                            {
                                'date': (new Date(new Date(2021, 12, 13).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.00114974027563775
                            },
                            {
                                'date': (new Date(new Date(2022, 3, 14).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.001259693399317657
                            },
                            {
                                'date': (new Date(new Date(2023, 3, 13).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.01267293947354674
                            },
                            {
                                'date': (new Date(new Date(2024, 3, 12).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.005045121126847666
                            }, {
                                'date': (new Date(new Date(2025, 3, 12).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.007160909242558721
                            }, {
                                'date': (new Date(new Date(2026, 3, 12).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.008773343547232493
                            }, {
                                'date': (new Date(new Date(2027, 3, 12).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.010192423690012596
                            }, {
                                'date': (new Date(new Date(2028, 3, 13).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.011238945517320739
                            }, {
                                'date': (new Date(new Date(2029, 3, 12).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.01214763885508222
                            }, {
                                'date': (new Date(new Date(2030, 3, 12).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.01288412044772724
                            }, {
                                'date': (new Date(new Date(2031, 3, 12).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.01352033916810758
                            }, {
                                'date': (new Date(new Date(2033, 3, 14).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.014561202176364403
                            }, {
                                'date': (new Date(new Date(2036, 3, 12).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.015913361166808353
                            }, {
                                'date': (new Date(new Date(2041, 3, 12).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.016504885729412527
                            }, {
                                'date': (new Date(new Date(2046, 3, 12).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.016734710832060958
                            }, {
                                'date': (new Date(new Date(2051, 3, 13).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.016816616165006047
                            }, {
                                'date': (new Date(new Date(2061, 3, 14).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.015818360807589683
                            }, {
                                'date': (new Date(new Date(2071, 3, 12).getTime())).toLocaleDateString('en-US'),
                                'rate': 0.014794078311926757
                            }]
                    };
                    this.calculation = result.data;
                    this.headers = [];
                    this.items = [];
                    this.flag = true;
                    this.graphFlag = true;

                    if (!result.data.result && !result.data.error) {
                        this.waitResultFlag = true
                    } else if (!result.data.error && result.data.result) {
                        temData.data.forEach(littleData => {
                            this.series[0].data.push(littleData.rate);
                            this.chartOptions.xaxis.categories.push(littleData.date);
                            this.series1[0].data.push(littleData.rate);
                            this.chartOptions1.xaxis.categories.push(littleData.date);
                        });
                    } else {
                        this.errorMessage = typeof result.data.error === 'object' ? JSON.parse(result.data.error) : this.$t('ui.calculation.errorMessage');
                        this.resultLoading = false;
                        this.waitResultFlag = false;
                    }

                    this.page.title = result.data.request.name

                } catch (error) {
                    this.flag = false;
                    this.waitResultFlag = false;
                    this.graphFlag = false;
                    this.resultLoading = false;
                    this.$error.notify(error)
                }
            },

            jsonDownload() {
                const data = JSON.stringify(JSON.parse(this.calculation.result), null, '\t');
                const blob = new Blob([data], {type: 'json/plain'});
                const e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = `${this.calculation.request.calculationType}-${this.calculation.requestTime}.json`;
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['json', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
            },

            async getApiKey() {
                try {
                    let userPid = this.urlPrefix === '/admin' ? this.$route.params.userPid : this.userInfo.pid;
                    let result = await this.$http.get(`${this.urlPrefix}/users/${userPid}/api-keys`);
                    this.apiSecret = result.data.items[0].secret;
                    this.apiKey = result.data.items[0].key;
                    this.getCalculationDetail();
                } catch (error) {
                    this.$error.notify(error)
                }
            }

        },
        mounted() {
            this.getApiKey()
        }
    }


</script>