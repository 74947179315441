<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-row justify="center">
        <v-dialog v-model="value" max-width="600px">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title v-if="isPromotion">{{$t('ui.code.create')}}</v-toolbar-title>
                    <v-toolbar-title v-else>{{$t('ui.code.creditCreate')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-card-text class="mt-6">
                    <ValidationObserver ref="createOrEditCodeForm">
                        <v-row>
                            <v-col>
                                <ValidationProvider rules="required|integer|min:1|max:20|" v-slot="{ errors }">
                                    <v-text-field v-model="discount.amount" min="1"
                                                  :disabled="!!(selectedCode && isPromotion)"
                                                  :maxLength="20"
                                                  :error-messages="errors"
                                                  @input="typeControl(discount)"
                                                  @keypress="numberControl($event)"
                                                  :label="$t('ui.code.amount')" type="number"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <ValidationProvider v-if="isPromotion" rules="required|whiteSpace|min:3|max:20|"
                                                    v-slot="{ errors }">
                                    <v-text-field v-model="code"
                                                  :disabled="!!selectedCode"
                                                  :maxLength="20"
                                                  :error-messages="errors"
                                                  :label="$t('ui.code.code')" type="text"></v-text-field>
                                </ValidationProvider>
                                <ValidationProvider v-else rules="required|whiteSpace|min:3|max:50|"
                                                    v-slot="{ errors }">
                                    <v-text-field v-model="code"
                                                  :disabled="!!selectedCode"
                                                  :maxLength="50"
                                                  :error-messages="errors"
                                                  :label="$t('ui.code.code')" type="text"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-btn :disabled="!!selectedCode" class="mx-1 mt-6" color="primary" :loading="generateLoading" @click="generateCode()">
                                {{$t('ui.general.generate')}}
                            </v-btn>
                        </v-row>
                        <v-row>
                            <v-col>
                                <ValidationProvider rules="integer|min:1|max:7|" v-slot="{ errors }">
                                    <v-text-field v-model="discount.quantity"
                                                @keypress="numberControl($event)"
                                                :error-messages="errors" min="1"
                                                max="1000000"
                                                :label="$t('ui.code.quantity')" type="number"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <ValidationProvider v-if="!isPromotion" rules="integer|min:1|max:20|" v-slot="{ errors }">
                                    <v-text-field v-model="discount.userQuantity"
                                                  :disabled="!discount.quantity"
                                                  :maxLength="20"
                                                  @input="userQuantityControl(discount)"
                                                  :error-messages="errors" min="1" :max="discount.quantity"
                                                  :label="$t('ui.code.userQuantity')" type="number"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row v-if="isPromotion">
                            <v-col>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <v-select
                                            :error-messages="errors"
                                            :disabled="!!(selectedCode && isPromotion)"
                                            v-model="amountType"
                                            :items="amountTypes"
                                            @input="typeControl(discount)"
                                            :label="$t('ui.code.amountType')"
                                            return-object
                                    ></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                        :return-value.sync="date"
                                        transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatDate(date)"
                                                      :label="$t('ui.code.datePicker')"
                                                      prepend-icon="mdi-calendar"
                                                      readonly
                                                      v-bind="attrs"
                                                      v-on="on"
                                                      clearable
                                                      @click:clear="date = null"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-1" color="warning" @click="menu = false">
                                            {{$t('ui.general.cancel')}}
                                        </v-btn>
                                        <v-btn class="mx-1" color="primary" @click="$refs.menu.save(date)">
                                            {{$t('ui.general.ok')}}
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </ValidationObserver>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
                    <v-btn class="mx-1" color="primary" :loading="loading" @click="createOrEditCode()">
                        {{$t('ui.general.save')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        props: ['value', 'selectedCode', 'isPromotion'],
        data() {
            return {
                loading: false,
                generateLoading: false,
                discount: {},
                code: null,
                amountType: null,
                codeType: null,
                isDiscount: false,

                //flags
                userQuantityFlag: false,
                typeFlag: false,

                date: null,
                menu: false,
                amountTypes: [{
                    text: this.$t('ui.code.percent'),
                    value: 'percent'
                }, {
                    text: this.$t('ui.code.flat'),
                    value: 'flat'
                }],
            };
        },

        watch: {
            codeType: function (code) {
                this.isDiscount = code.value === 0;
            },

          value: function (val) {
                if (val && this.selectedCode) {
                    this.discount = {
                        amount: this.selectedCode ? this.selectedCode.amount : null,
                        quantity: this.selectedCode ? this.selectedCode.quantity : null,
                        userQuantity: this.selectedCode ? (this.isPromotion ? this.selectedCode.user_quantity : this.selectedCode.userQuantity ): 1
                    };
                    this.code = this.selectedCode ? this.selectedCode.code : null;
                    this.amountType = this.selectedCode ? this.selectedCode.amount_type : null;
                    this.date = this.selectedCode ? (this.isPromotion ? (this.selectedCode.end_date!==this.$t('ui.code.limitless') ?  this.selectedCode.end_date  : null ) :
                        this.selectedCode.expirationDate!==this.$t('ui.code.limitless') ?  this.selectedCode.expirationDate  : null): null;
                }
                this.typeControl();
                this.userQuantityFlag = this.selectedCode.userQuantity <= this.selectedCode.quantity;
            }
        },

        methods: {
            close() {
                this.clear();
                this.$emit("close", true);
            },

            async createOrEditCode() {
                this.discount.code = this.code;
                this.discount.amountType = this.amountType;
                this.discount.expirationDate = this.date ? new Date(this.date).toISOString() : null;
                this.discount.pid = this.selectedCode ? this.selectedCode.pid : null;
                this.discount.id =  this.selectedCode ? this.selectedCode.id : null;

                this.loading = true;

                if (await this.$refs.createOrEditCodeForm.validate() && ((this.isPromotion && this.typeFlag) || (!this.isPromotion && this.userQuantityFlag))) {
                    try {
                        this.selectedCode ? await this.$http.patch(`/admin/codes`, {code: this.discount, promotion: this.isPromotion}) : await this.$http.post(`/admin/codes`, {code: this.discount, promotion: this.isPromotion});
                        this.selectedCode ? this.$snotify.success(this.$t('ui.code.editSuccess')) : this.$snotify.success(this.$t('ui.code.createSuccess'));
                        this.$emit("refresh", true);
                        await this.clear();
                    } catch (error) {
                        this.loading = false;
                        this.$error.notify(error)
                    }
                } else {
                    if(!(await this.$refs.createOrEditCodeForm.validate())){
                        this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                    }
                    else if(!this.typeFlag && this.isPromotion){
                        this.$snotify.warning(this.$t('ui.code.typeWarning'));
                    }
                    else if(!this.userQuantityFlag && !this.isPromotion){
                        this.$snotify.warning(this.$t('ui.code.userQuantityWarning'));
                    }
                    this.loading = false
                }
            },

            userQuantityControl(discount){
                this.userQuantityFlag = discount.userQuantity <= discount.quantity;
            },

            typeControl(discount){
                if(!this.selectedCode){
                    this.typeFlag = !(this.amountType.value === 'percent' && discount.amount > 100);
                }else{
                    this.typeFlag = true
                }
            },

            numberControl(event){
                let charCode = (event.which) ? event.which : event.keyCode;
                if (charCode === 101 && (charCode < 48 || charCode > 57)) {
                    event.preventDefault();
                }
            },

            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },

            async generateCode() {
                this.generateLoading = true;
                let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
                let result = '';
                let length = this.isPromotion ? 20 : 30;
                for (let i = 0; i < length; i++) {
                    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
                    if (i === length - 1) {
                        this.generateLoading = false;
                    }
                }
                this.code = result;
            },

            async clear() {
                this.loading = false;
                this.code = null;
                this.discount = {};
                this.amountType = null;
                this.codeType = null;
                this.isDiscount = false;
                this.date = null;
                this.menu = false;

                await this.$refs.createOrEditCodeForm.reset();

            },
        }
    };
</script>
}
