<template>
    <v-container fluid>
        <v-row class="mt-2">
            <v-col sm="6" lg="6">
                <p>{{$t('ui.notification.walletNotification')}}</p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
                <v-row justify="end" class="mr-1">
                    <v-switch v-model="notificationWallet" dense hide-details></v-switch>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6" lg="6">
                <p>{{$t('ui.notification.calculationNotification')}}</p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
                <v-row justify="end" class="mr-1">
                    <v-switch v-model="notificationCalculation" dense hide-details></v-switch>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6" lg="6">
                <p>{{$t('ui.notification.userTransactionNotification')}}</p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
                <v-row justify="end" class="mr-1">
                    <v-switch v-model="notificationUserTransaction" dense hide-details></v-switch>
                </v-row>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col class="font-weight-bold text-right mt-4">
                <v-btn
                        :loading="loading"
                        @click="saveNotification()"
                        color="primary">
                    {{$t('ui.general.save')}}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    export default {

        props: ["user"],
        data() {
            return {
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                notificationWallet: this.user.notificationWallet,
                notificationCalculation: this.user.notificationCalculation,
                notificationUserTransaction:  this.user.notificationUserTransaction,
                loading: false,
            }
        },
        methods: {
            async saveNotification() {
                this.loading = true;
                try{
                    let notificationOptions = {
                        notificationWallet: this.notificationWallet,
                        notificationCalculation: this.notificationCalculation,
                        notificationUserTransaction: this.notificationUserTransaction
                    };
                    await this.$http.patch(`${this.urlPrefix}/users/${this.user.pid}`, {notificationOptions: notificationOptions});
                    this.$snotify.success(this.$t('ui.general.snotify.changeNotificationOptions'));
                    this.$emit("refresh", true);
                    this.loading = false;
                }catch (error) {
                    this.loading = false;
                    this.$error.notify(error)
                }
            },
        },
    }
</script>

<style scoped>

</style>