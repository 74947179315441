<template>
    <v-container fluid class="app-container">
        <app-title-bar v-if="!adminFlag"
                       :title="$t('ui.sidebar.apiKeys')">
        </app-title-bar>

        <v-card :class="this.$route.meta && this.$route.meta.permission === 'admin' ? 'elevation-0' : 'mt-6' ">
            <v-col>
                <v-row no-gutters align="center">
                    <v-col v-if="urlPrefix!== '/admin'">
                        <v-btn large color="primary" class="white--text" @click="apiKeyOperationDialog=true">
                            {{$t('ui.apiKey.create')}}
                        </v-btn>
                    </v-col>
                    <v-col class="mr-2">
                        <search-bar
                                :searchText.sync="nameSearch"
                                :searchLabel="searchLabel"
                                :noSort="true"
                                @cleanAllFilters="cleanAllFilters"
                                @refresh="getApiKeyList()"
                        ></search-bar>
                    </v-col>
                </v-row>
            </v-col>
            <v-row class="pa-0">
                <v-container fluid>
                    <v-data-table
                            :headers="headers"
                            :items="apiKeyList"
                            item-key="name"
                            class="app-list b-r-3"
                            :footer-props="tableFooterProps"
                            :page.sync="query.pagination.currentPage"
                            :items-per-page.sync="query.pagination.limit"
                            :server-items-length="query.pagination.totalCount"
                            :options.sync="tableOptions"
                    >
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <template v-slot:item.wallet.name="{ item }">
                            <v-span v-if="item.wallet"> {{item.wallet.name}}</v-span>
                            <v-span v-else>-</v-span>
                        </template>
                        <template v-slot:item.key="{ item }">
                            <v-row no-gutters class="show-on-hover-opacity" style="height:32px;min-width:250px">
                                <v-col class="mt-2">
                                    <simplebar class="simplebar-force-block" style="height:30px;overflow-y: hidden">
                                        <span style="white-space: nowrap" class="secret-text">{{item.key}}</span>
                                    </simplebar>
                                </v-col>
                                <div style="width: 40px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon class="show-on-hover-slot" v-on="on"
                                                   @click="onCopyKey(item.key)">
                                                <v-icon>
                                                    mdi-clipboard-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{$t('ui.apiKey.copyKey')}}</span>
                                    </v-tooltip>
                                </div>
                            </v-row>
                        </template>
                        <template v-slot:item.secret="{ item }">
                            <v-row>
                                <v-col cols="9" class="mt-2">
                                    <span class="text-font">{{ item.showSecret ? item.secret : '&#8727;'.repeat(item.secret.length)}}</span>
                                </v-col>
                                <v-col cols="2" style="width:80px ">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon class="show-on-hover-slot" v-on="on"
                                                   @click="item.showSecret = !item.showSecret">
                                                <v-icon>
                                                    {{ item.showSecret ? 'mdi-eye-off' : 'mdi-eye'}}
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{item.showSecret ? $t('ui.general.dontShow') : $t('ui.general.show') }}</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on"
                                                   @click="onCopySecret(item.secret)">
                                                <v-icon>mdi-clipboard-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{$t('ui.apiKey.copySecret')}}</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.canSeeOld="{ item }">
                    <span v-if="item.canSeeOld===true">
                                <v-icon v-on="on" color="green">mdi-check</v-icon>
                    </span>
                            <span v-else-if="item.canSeeOld===false">
                                <v-icon v-on="on" color="red">mdi-close</v-icon>
                        </span>
                        </template>
                        <template v-slot:item.allowedIpList="{ item }">
                            <span v-if="item.allowedIpList.length===0">-</span>
                            <span v-else>
                                 <v-btn width="105px"
                                        outlined
                                        @click="viewIPList(item)"
                                        color="primary">{{$t('ui.apiKey.viewIPList')}}</v-btn>
                            </span>
                        </template>
                        <template v-slot:item.canQuery="{ item }">
                    <span v-if="item.canQuery===true">
                                <v-icon v-on="on" color="green">mdi-check</v-icon>
                    </span>
                            <span v-else-if="item.canQuery===false">
                                <v-icon v-on="on" color="red">mdi-close</v-icon>
                        </span>
                        </template>
                        <template v-slot:item.monteCarlo="{ item }">
                    <span v-if="item.monteCarlo===true">
                                <v-icon v-on="on" color="green">mdi-check</v-icon>
                    </span>
                            <span v-else-if="item.monteCarlo===false">
                                <v-icon v-on="on" color="red">mdi-close</v-icon>
                        </span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="editApiKey(item)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.edit')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="urlPrefix!== '/admin'">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on"
                                           @click="openDeleteItemDialog(item)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.delete')}}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-container>
            </v-row>
        </v-card>
        <v-row v-if="apiKeyList && userInfo">
            <api-key-operations-dialog v-model="apiKeyOperationDialog" :userInfo="userInfo"
                                       :selectedApiKey="apiKey" @close="close()"
                                       @refresh="refresh()"></api-key-operations-dialog>
            <delete-item-dialog
                    v-model="deleteItemDialogStatus"
                    :loading="deleteItemDialogLoading"
                    :title="$t('ui.apiKey.delete')"
                    :text="$t('ui.apiKey.deleteText')"
                    @delete="deleteApiKey">
            </delete-item-dialog>
            <v-row v-if="selectedApiKey">
                <allowed-ip-list-view-dialog v-model="viewIPListDialog" :apiKey="selectedApiKey"
                                             @close="close()"></allowed-ip-list-view-dialog>
            </v-row>
        </v-row>
    </v-container>
</template>

<script>
    import SearchBar from "@/modules/core/components/SearchBar";
    import Pagination from "@/modules/core/mixins/pagination.js";
    import ApiKeyOperationsDialog from "@/modules/apiKey/components/dialogs/ApiKeyOperationsDialog";
    import AllowedIpListViewDialog from "@/modules/apiKey/components/dialogs/AllowedIPListViewDialog";
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";
    import {store} from "ekx-vue-base";

    export default {
        props: ['adminFlag', 'user'],
        components: {
            SearchBar,
            ApiKeyOperationsDialog,
            DeleteItemDialog,
            AllowedIpListViewDialog
        },
        mixins: [
            new Pagination({
                methodName: "getApiKeyList",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    name: "=lk="
                },
                searchMirror: {
                    name: {
                        path: "searchName"
                    }
                }
            })
        ],
        data() {
            return {
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                apiKeyList: [],
                hide: false,
                showSecret: false,
                walletPid: this.$route.params.wallet_pid,
                selectedApiKey: null,
                apiKey: null,
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,
                apiKeyOperationDialog: false,
                viewIPListDialog: false,
                //search
                nameSearch: "",
                tableOptions: {},
                sortKeyList: [{value: "name", name: this.$t('ui.apiKey.name')}],
                searchLabel: this.$t('ui.searchBar.apiKey'),
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                headers: [
                    {
                        text: this.$t('ui.apiKey.name'),
                        value: "name",
                        align: "start",
                        width: "100"
                    },
                    {
                        text: "Key",
                        value: "key",
                        sortable: false,
                        width: "300"
                    },
                    {
                        text: "Secret",
                        value: "secret",
                        width: "500",
                        sortable: false,
                    },
                    {
                        text: this.$t('ui.apiKey.wallet'),
                        value: "wallet.name",
                        sortable: false,
                        width: "200",
                    },
                    {
                        text: this.$t('ui.apiKey.allowedIpList'),
                        value: "allowedIpList",
                        sortable: false,
                        width: "200",
                    },
                    {
                        text: this.$t('ui.apiKey.canQuery'),
                        value: "canQuery",
                        sortable: false,
                        width: "200",
                    },
                    {
                        text: this.$t('ui.apiKey.canSeeOld'),
                        value: "canSeeOld",
                        sortable: false,
                        width: "200",
                    },
                    {
                        text: this.$t('ui.apiKey.monteCarlo'),
                        value: "monteCarlo",
                        sortable: false,
                        width: "200",
                    },
                    {text: this.$t('ui.general.action'), value: "actions", width: "100", sortable: false},
                ],
            };
        },
        watch: {
            "tableOptions.sortBy"() {
                this.query.sort.field = this.tableOptions.sortBy[0];
                this.query.sort.order = this.tableOptions.sortDesc[0] ? "desc" : "asc"
            }
        },
        created() {
            EkxDebounce.watch(this, "nameSearch", 500, () => {
                this.query.search.name.value = this.nameSearch;
            });
        },
        methods: {
            async getApiKeyList() {
                this.apiKey = null;
                let query = this.createQuery();
                try {
                    let response = await this.$http.get(`${this.urlPrefix}/users/${this.userPid}/api-keys?${query}`);
                    this.apiKeyList = response.data.items;
                    this.apiKeyList = response.data.items.map(item => {
                        return {
                            ...item,
                            showSecret: false,
                        }
                    });
                    this.query.pagination = response.data.meta.pagination;
                } catch (error) {
                    this.$error.notify(error);
                }
            },

            openDeleteItemDialog(item) {
                this.selectedApiKey = item;
                this.deleteItemDialogStatus = true;
            },

            editApiKey(item) {
                this.apiKey = item;
                this.apiKeyOperationDialog = true;
            },

            onCopyKey: function (key) {
                const el = document.createElement('textarea');
                el.value = key;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.$snotify.success(this.$t('ui.apiKey.copySuccess'));
            },

            onCopySecret: function (secret) {
                const el = document.createElement('textarea');
                el.value = secret;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.$snotify.success(this.$t('ui.apiKey.copySuccessSecret'));
            },
            async deleteApiKey() {
                try {
                    this.deleteItemDialogLoading = true;
                    await this.$http.delete(`${this.urlPrefix}/api-keys/${this.selectedApiKey.pid}`);
                    this.refresh();
                    this.$snotify.success(this.$t('ui.apiKey.deleteSuccess'));
                } catch (error) {
                    this.$error.notify(error);
                } finally {
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                }
            },
            viewIPList(item) {
                this.selectedApiKey = item;
                this.viewIPListDialog = true;
            },
            refresh() {
                this.apiKeyOperationDialog = false;
                this.getApiKeyList();
            },

            close() {
                this.selectedApiKey = null;
                this.apiKeyOperationDialog = false;
                this.getApiKeyList();
                this.viewIPListDialog = false;
            },

            cleanAllFilters() {
                this.searchName = "";
                this.query.sort.field = "";
                this.getApiKeyList();
            },

        },
        computed: {
            userInfo() {
                return this.adminFlag ? this.user : this.$store.getters["core/getUserInfo"]
            },
            userPid(){
                return this.$route.params.userPid ? this.$route.params.userPid : this.userInfo.pid;
            }
        },
        mounted() {
            this.getApiKeyList();
        },
    };
</script>
<style lang="scss" scoped>
    .text-font {
        font-size: 12px;
    }
</style>
