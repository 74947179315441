/**
 * Created by ali.turan
 */
import XLSX from "xlsx";

export default {
    parseFile: (data, options) => {
        return new Promise((resolve) => {
            var reader = new FileReader();
            reader.onload = function (event) {
                var data = event.target.result;
                var workbook = XLSX.read(data, {
                    type: 'array'
                });

                var result = {};
                workbook.SheetNames.forEach(function (sheetName) {
                    var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                        header: 1
                    });
                    if (roa.length) result[sheetName] = roa;
                });

                var ret = [];
                Object.keys(result).forEach((key) => {
                    if(options.dropLine > 0){
                        for(let i=0;i<options.dropLine;i++){
                            result[key].shift();
                        }
                    }

                    var headers = [];
                    var headerString = options.firstRowHeader ? result[key].shift() : [];
                    if(headerString.length == 0){
                        for(let i=0;i<result[key][0].length;i++){
                            headers.push({
                                name : `Column ${i+1}`,
                                index : i
                            });
                        }
                    }
                    else {
                        for(let i=0;i<headerString.length;i++){
                            headers.push({
                                name : headerString[i],
                                index : i
                            });
                        }
                    }
                    ret.push({
                        name : key,
                        // columnSize : header.length ? header.length : result[key][0].length,
                        headers: headers,
                        data: result[key]
                    });
                });


                resolve(ret);
            }
            reader.readAsArrayBuffer(data);
        })
    }
};