import { render, staticRenderFns } from "./UserNotificationOptions.vue?vue&type=template&id=2c07802c&scoped=true&"
import script from "./UserNotificationOptions.vue?vue&type=script&lang=js&"
export * from "./UserNotificationOptions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c07802c",
  null
  
)

export default component.exports