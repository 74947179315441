// Pagination

import debounce from "lodash.debounce"

export default function Pagination({pagination, sort, search, searchMirrors, methodName, vuetifySort} = {}) {
    return {
        data() {
            return {
                query: {
                    pagination: {
                        currentPage: 1,
                        limit: parseInt(this.$route.query.limit) || pagination.limit || this.$store.state.core.pageCount,
                        offset: parseInt(this.$route.query.offset) || pagination.offset || 0,
                    },

                    sort: sort ? sort : this.$route.query.sort ? parseSortText(this.$route.query.sort) : {
                        field: vuetifySort ? [] : null,
                        order: vuetifySort ? true : 'desc'
                    },
                    search: this._createSearchObjectFromOptions(),
                    //
                    isLimitChanged: false,
                    isOrderChanged: false
                }

            }
        },
        watch: {
            "query.pagination.currentPage": {
                handler: function (value) {
                    this.query.pagination.offset = this.query.pagination.limit * (value - 1);
                }
            },
            "query.pagination.limit": {
                handler: function (value) {
                    this.$store.commit(`core/setPageCount`, value);
                    pagination.limit = this.$store.state.core.pageCount;
                    this._onPaginationChange("pagination", "limit", value);
                    this.query.isLimitChanged = true;
                    this.query.pagination.offset = 0;
                    this._callQueryHandlerMethod();
                }
            },
            "query.pagination.offset": {
                handler: function (value) {
                    this.query.isLimitChanged = false;
                    this.$nextTick().then(() => {
                        if (!this.query.isLimitChanged) {
                            this._onPaginationChange("pagination", "offset", value);
                            this._callQueryHandlerMethod();
                        }
                    })
                }
            },
            "query.sort.field": {
                handler: function () {
                    if (this.query.sort && this.$route.query.sort !== this.createSortQuery()) {
                        this.query.isOrderChanged = false;
                        this.$nextTick().then(() => {
                            if (!this.query.isOrderChanged) {
                                this._updateQuerySortParameter();
                            }
                        });
                    }
                }
            },
            'query.sort.order': {
                handler: function () {
                    if (this.query.sort && this.$route.query.sort != this.createSortQuery()) {
                        this.query.isOrderChanged = true;
                        this._updateQuerySortParameter();
                    }

                }
            },
            'query.search': {
                deep: true,
                handler: function () {
                    let searchQuery = this.createSearchQuery();
                    // != kalması gerekir. Bilerek ve isteyerek koydum.
                    if (searchQuery !== this.$route.query.q) {
                        if (searchQuery) {
                            this.$router.push({
                                name: this.$route.name,
                                query: {...this.$route.query, q: searchQuery}
                            }).catch(() => {
                            });
                        } else {
                            let query = Object.assign({}, this.$route.query);
                            delete query['q'];
                            //TODO:düzenlenmeli ,catch duplicate hata mesajından kaçınmak için eklendi
                            this.$router.push({
                                name: this.$route.name,
                                query
                            }).catch(() => {
                            })

                        }
                        this._callQueryHandlerMethod();
                    }

                }
            },
            "$route.query": {
                handler: function (value) {
                    this.query.pagination.limit = value.limit ? parseInt(value.limit) : pagination.limit || 10;
                    this.query.pagination.offset = value.offset ? parseInt(value.offset) : 0;
                    this.query.sort = value.sort ? parseSortText(value.sort) : {
                        field: vuetifySort ? [] : null,
                        order: vuetifySort ? true : 'desc'
                    };

                    if (this.$route.query.q) {
                        if (this.$route.query.q !== this.createSearchQuery()) {
                            this._setQuerySearchParameters();
                        }
                    } else {
                        this._setQuerySearchParameters();
                    }

                }

            }
        },
        methods: {
            createQuery() {
                let query = `limit=${this.query.pagination.limit}&offset=${this.query.pagination.offset}`;
                if (this.query.sort && ((vuetifySort && this.query.sort.field.length > 0) || (!vuetifySort && this.query.sort.field))) {
                    query += `&sort=${this.createSortQuery()}`
                }

                let searchQuery = this.createSearchQuery();
                if (searchQuery) {
                    query += '&q=' + searchQuery;
                }
                return query;
            },
            _onPaginationChange(unit, field, value) {
                if (!this.$route.query[field] || this.$route.query[field] !== value.toString()) {
                    if (this.query[unit][field] === (field === 'limit' ? 4 : 0)) {
                        if (this.$route.query[field] == null) {
                            return;
                        }
                    }
                }
                this._updateUrlPaginationParameter(field, value);
            },
            _updateUrlPaginationParameter() {
                this.$router.push({
                    name: this.$route.name,
                    query: {
                        ...this.$route.query,
                        limit: this.query.pagination.limit,
                        offset: this.query.pagination.offset
                    }
                }).catch(() => {
                });
            },
            createSortQuery() {
                if (vuetifySort) {
                    if (this.query.sort.field.length > 0) {
                        return `${this.query.sort.field[0]} ${this.query.sort.order ? 'desc' : 'asc'}`
                    } else {
                        return null;
                    }
                } else {
                    if (this.query.sort.field) {
                        return `${this.query.sort.field} ${this.query.sort.order}`
                    } else {
                        return null;
                    }
                }
            },
            _updateQuerySortParameter() {
                if ((vuetifySort && this.query.sort.field.length > 0) || (!vuetifySort && this.query.sort.field)) {
                    this.$router.push({
                        name: this.$route.name,
                        query: {...this.$route.query, sort: this.createSortQuery()}
                    }).catch(() => {
                    });
                } else {

                    let params = JSON.parse(JSON.stringify(this.$route.query));
                    delete params.sort;

                    this.$router.push({
                        name: this.$route.name,
                        query: params
                    }).catch(() => {
                    });
                }
                this._callQueryHandlerMethod();
            },
            toggleSortOrder() {
                if (vuetifySort) {
                    this.query.sort.order = !this.query.sort.order;
                } else {
                    this.query.sort.order = this.query.sort.order === 'asc' ? 'desc' : 'asc';
                }
            },
            _setQuerySearchParameters() {
                if (this.$route.query.q) {
                    let fromUrl = this._parseSearchQuery(this.$route.query.q);
                    this.query.search = {
                        ...this._createSearchObjectFromOptions(),
                        ...fromUrl
                    }
                } else {
                    this.query.search = {
                        ...this._createSearchObjectFromOptions()
                    };
                }

                this._setSearchQueryMirrors();

            },
            _parseSearchQuery(text) {

                let operators = ['==', '!=', '=lt=', '=le=', '=gt=', '=ge=', '=bt=', '=lk=', '=il='];
                let conditions = text.split(";");

                let queryItems = {};
                conditions.forEach((conditionText) => {
                    for (let i = 0; i < operators.length; i++) {
                        let operator = operators[i];
                        if (conditionText.indexOf(operator) > 0) {
                            let [field, value] = conditionText.split(operator);
                            queryItems[field] = {
                                operator,
                                value
                            };
                            break;
                        }

                    }
                });

                return queryItems;

            },
            _setSearchQueryMirrors() {
                if (searchMirrors) {
                    Object.keys(this.query.search).forEach((key) => {
                        let search = this.query.search[key];
                        if (searchMirrors[key]) {
                            this.$set(this, searchMirrors[key].path, searchMirrors[key].parser ? searchMirrors[key].parser(search.value) : search.value);
                        }
                    });
                }
            },
            createSearchQuery() {
                let q = '';
                let count = 0;
                Object.keys(this.query.search).forEach((key) => {
                    let condition = this.query.search[key];
                    if (condition.value != null && condition.value !== '') {
                        q += `${key}${condition.operator}${condition.value}`;
                        if (count > 0) {
                            q += ';';
                        }
                    }
                });
                return q === '' ? null : q;
            },
            _createSearchObjectFromOptions() {
                let searchObject = {};

                Object.keys(search).forEach((key) => {
                    searchObject[key] = {
                        operator: search[key],
                        value: null
                    }
                });

                return searchObject;
            },
            _callQueryHandlerMethod() {
                this.bouncyCallback();
            }
        },
        created() {
            this.query.paginationMirror = {
                ...this.query.pagination
            };
            if (this.$route.query.q) {
                this._setQuerySearchParameters();
            }
            this.bouncyCallback = debounce(this[methodName], 100)
        }
    };

    function parseSortText(text) {
        let items = text.split(" ");
        if (items.length > 2 || items.length < 1) {
            throw new Error("Wrong Sort Parameters");
        } else {
            return {
                field: vuetifySort ? [items[0]] : items[0],
                order: items.length === 2 ? (vuetifySort ? items[1].toLowerCase() === 'desc' : items[1]) : (vuetifySort ? true : "desc")
            }
        }
    }

}