<template>
    <v-app>
        <template v-if="renderApp">
            <global-loading-view v-if="$store.state.core.ui.loading"></global-loading-view>
            <router-view v-else></router-view>
        </template>
        <vue-snotify></vue-snotify>
    </v-app>
</template>

<script>

    import GlobalLoadingView from "@/modules/core/components/GlobalLoadingView"

    export default {
        name: 'app',
        components: {
            GlobalLoadingView
        },
        data() {
            return {
                renderApp: true
            }
        },
        methods: {
            forceRerender() {
                this.renderApp = false;
                this.$nextTick(() => {
                    this.renderApp = true;
                });
            }
        },
        created() {
            this.$bus.$on("force-render", this.forceRerender);
            this.$store.dispatch("core/getAppData");
            document.documentElement.lang = this.$store.state.core.ui.lang;
        }
    }
</script>


<style lang="scss">
    @import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
</style>
