<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="app-default-layout">
        <app-header :sidebar.sync="sidebar"></app-header>
        <app-sidebar :sidebar="sidebar"></app-sidebar>
        <!-- App Main Content -->
        <v-content>
            <!-- App Router -->
            <transition name="router-anim" :enter-active-class="'animated slideInUp'">
                <router-view></router-view>
            </transition>
        </v-content>
    </div>
</template>

<script>

    import AppHeader from "./AppHeader"
    import AppSidebar from "./AppSidebar"


    export default {
        components: {
            AppHeader,
            AppSidebar
        },
        props: {},
        data() {
            return {
                sidebar: true
            }
        },
        methods: {},
        beforeCreate() {

        }
    }
</script>


<style scoped>
    .app-default-layout {
        height: 100vh;
    }
</style>
