<template>
    <v-container fluid class="app-container">
        <app-title-bar :title="$t('ui.sidebar.wallet')">
        </app-title-bar>
        <v-card class="pt-4 mt-6" v-if="userInfo && !userInfo.parent">
            <v-col>
                <v-row no-gutters>
                    <v-btn large color="primary" @click="goBalancePackets()">
                        <span class="white--text mt-1" align="center">
                            {{$t('ui.wallet.loadBalance')}}
                        </span>
                    </v-btn>
                    <v-btn class="ml-2" large color="primary" @click="goCards()">
                        <span class="white--text mt-1" align="center">
                            {{$t('ui.card.myCards')}}
                        </span>
                    </v-btn>
                </v-row>
            </v-col>
            <v-divider class="mt-4"></v-divider>
            <v-card-title v-if="wallet">
                <span>{{wallet.name}} {{$t('ui.wallet.header')}}</span>
            </v-card-title>
            <v-card-title>
                <span>{{$t('ui.wallet.balance')}}</span>
            </v-card-title>
            <v-card-text v-if="wallet">
                <span>{{wallet.balance}} {{$t('ui.wallet.credit')}}</span>
            </v-card-text>
        </v-card>
        <sub-wallet-list @refresh="refresh()"></sub-wallet-list>

    </v-container>
</template>

<script>

    import SubWalletList from "@/modules/wallet/components/SubWalletList";

    export default {
        components: {
          SubWalletList
        },

        data() {
            return {
                userPid: this.$route.meta.permission === 'admin' ? this.$route.params.userPid
                    : (this.$store.getters['core/getUserInfo']).pid,
                userInfo: null,
                urlPrefix: (this.$route.meta && this.$route.meta.permission === 'admin') ? '/admin' : '',
                loadBalanceDialog: false,
                wallet: null,
            }
        },

        methods: {
            async getUser() {
                try {
                    this.userInfo = (await this.$http.get(`${this.urlPrefix}/users/${this.userPid}`)).data;
                    this.wallet = this.userInfo.wallet.filter(function (item) {
                        return item.parentId === null
                    })[0];
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async getWalletDetail() {
                try {
                    this.wallet = (await this.$http.get(`${this.urlPrefix}/wallets/${this.wallet.pid}`)).data;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async goBalancePackets() {
                this.$router.push(
                    `/packets`
                ).catch(() => {
                });
            },

            async goCards() {
                this.$router.push(
                    `/cards`
                ).catch(() => {
                });
            },

            refresh() {
                this.loadBalanceDialog = false;
                this.getWalletDetail();
            },

            close() {
                this.loadBalanceDialog = false
            }
        },
        mounted() {
            this.getUser();
        }
    }


</script>