export default {
    messages: {
        required: "Bu alan zorunludur",
        bucketName: "Bu isim uygun değildir",
        whiteSpace : "Boşluk ile başlayamaz yada bitemez",
        cardNumber: "Geçerli bir kart numarası girin",
        min: (name, rule) => `En az ${rule.length} karakter girilmelidir`,
        max: (name, rule) => `En fazla ${rule.length} karakter girilebilir`,
        email : "Lütfen geçerli bir mail adresi girin"
    }
}