export default {
    messages: {
        required: "This field is required",
        bucketName: "Not a valid bucket name",
        whiteSpace : "Can't start or end with whitespace",
        cardNumber: "Not a valid card number",
        min: (name, rule) => `Min ${rule.length} character required`,
        max: (name, rule) => `Max ${rule.length} character allowed`,
        email : "Please provide a valid email address"
    }
}