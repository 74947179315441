<template>
    <v-dialog v-model="value" max-width="600px" :persistent="true">
        <v-card>
            <v-toolbar color="primary" dark class="elevation-1">
                <v-toolbar-title>{{$t('ui.card.table.edit')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon @click="close()" v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    {{$t('ui.general.close')}}
                </v-tooltip>
            </v-toolbar>
            <v-container class="pa-0">
                <v-col class="pa-6">
                    <form>
                        <ValidationObserver ref="changeCardNameForm">
                            <ValidationProvider rules="required|whiteSpace|min:3|max:50|" v-slot="{ errors }">
                                <v-text-field v-model="name"
                                              :maxLength="50"
                                              :error-messages="errors"
                                              :label="$t('ui.card.dialog.name')" type="text"></v-text-field>
                            </ValidationProvider>
                        </ValidationObserver>
                    </form>

                </v-col>
                <v-divider></v-divider>
                <v-col>
                    <v-row justify="end" no-gutters>
                        <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
                        <v-btn class="mx-1" color="primary" :loading="nameLoading" @click="changeCardName()">{{$t('ui.general.save')}}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>

    export default {
        props: ["value", "card", "user"],

        data() {
            return {
                name: this.card.alias,
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                nameLoading: false
            }
        },

        watch: {
            value: function (value) {
                if(value===true){
                    this.name = this.card.alias;
                }
            }
        },
        
        methods: {
            async changeCardName() {
                this.nameLoading = true;
                if (await this.$refs.changeCardNameForm.validate()) {
                    try {
                        await this.$http.patch(`/customers/${this.user.customerId}/payment-sources/${this.card.id}`, {alias: this.name, customerId: this.user.customerId});
                        this.$snotify.success(this.$t('ui.card.successEdit'));
                        this.$emit('refresh');
                        this.nameLoading = false;
                    } catch (error) {
                        this.nameLoading = false;
                        this.$error.notify(error);
                    }
                } else {
                    this.nameLoading = false;
                    this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                }
            },

            close() {
                this.$refs.changeCardNameForm.reset();
                this.$emit("close", true);
            },
        }
    };
</script>
