var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"px-3",attrs:{"app":"","fixed":""}},[_c('v-app-bar-nav-icon',{staticClass:"v-step-0",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('update:sidebar',!_vm.sidebar)}}}),_c('create-feedback-dialog',{on:{"close":function($event){return _vm.close()}},model:{value:(_vm.createFeedbackDialog),callback:function ($$v) {_vm.createFeedbackDialog=$$v},expression:"createFeedbackDialog"}}),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [( _vm.$lang.getLocale())?_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-translate")]),_vm._v(" "+_vm._s(_vm.$t("ui.language." + _vm.$lang.getLocale()))+" ")],1):_vm._e()]}}])},[_c('v-list',{staticStyle:{"width":"250px"}},_vm._l((_vm.languages),function(lang){return _c('v-list-item',{key:lang.key,on:{"click":function($event){return _vm.selectLanguage(lang)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(lang.text))+" ")])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.updateNotification()}}},on),[_c('v-icon',[_vm._v(" notifications ")])],1)]}}])},[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-1 base primary",attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('ui.notification.notifications')))]),_c('v-spacer'),(_vm.notificationSize!==0)?_c('v-btn',{staticClass:"mx-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.notificationSize)+" "+_vm._s(_vm.$t('ui.notification.new'))+" ")]):_vm._e()],1)],1),(_vm.notificationList.length > 0)?_c('v-list',{staticClass:"pic-container",attrs:{"max-width":"600px","three-line":""}},[_vm._l((_vm.notificationList),function(notification){return _c('v-list-item',{key:notification.pid,on:{"click":function($event){return _vm.goNotificationDetail(notification.url)}}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{attrs:{"inset":""},domProps:{"innerHTML":_vm._s(_vm.$t('ui.log.'+ notification.key , {from: notification.data.from , to: notification.data.to,
                            balance:notification.data.balance, walletName:notification.data.walletName}))}}),_c('v-list-item-subtitle',{staticClass:"text-right font-weight-bold"},[_vm._v(_vm._s(notification.createdAt)+" ")])],1)],1)}),(_vm.notificationList.length !== _vm.notificationTotalCount )?_c('v-list-item',{on:{"click":[function($event){$event.stopPropagation();$event.preventDefault();},function($event){return _vm.moreNotification()}]}},[_c('v-spacer'),_c('v-list-item-subtitle',{staticClass:"text-right",domProps:{"innerHTML":_vm._s(_vm.$t('ui.general.more'))}})],1):_vm._e()],2):_vm._e(),(_vm.notificationList.length === 0 && !_vm.moreFlag)?_c('v-list',{attrs:{"min-width":"300px"}},[_c('v-list-item-subtitle',{staticClass:"ml-4",domProps:{"innerHTML":_vm._s(_vm.$t('ui.notification.noNotification'))}})],1):_vm._e()],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" person ")])],1)]}}])},[_c('v-list',{staticStyle:{"width":"250px"}},[_c('v-list-item',{on:{"click":_vm.userProfile}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("account_box")]),(this.$store.state.core.userInfo.name)?_c('span',[_vm._v(" "+_vm._s(this.$store.state.core.userInfo.name))]):_vm._e()],1)],1),(_vm.urlPrefix !=='/admin' )?_c('v-list-item',{on:{"click":function($event){_vm.createFeedbackDialog=true}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("feedback")]),_c('span',[_vm._v(_vm._s(_vm.$t('ui.feedback.feedback')))])],1)],1):_vm._e(),_c('v-divider'),_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-row',{staticClass:"py-3",attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.general.darkTheme')))]),_c('v-spacer'),_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":"","dense":""},on:{"change":_vm.themeChanged},model:{value:(_vm.$store.state.core.theme.dark),callback:function ($$v) {_vm.$set(_vm.$store.state.core.theme, "dark", $$v)},expression:"$store.state.core.theme.dark"}})],1)],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.signout}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-logout-variant")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('ui.general.signOut')))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }