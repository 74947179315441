<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-container fluid class="app-container">

        <v-row>
            <!--Statistics-->
            <v-col cols="12" sm="6" lg="3">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2> {{totalUser}} </h2>
                            <span class="fs-12">{{$t('ui.home.totalUsers')}}</span>
                        </v-col>
                        <v-icon x-large color="primary">
                            mdi-account
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2> {{ totalBalance }} {{$t('ui.general.header.balance')}} </h2>
                            <span class="fs-12">{{$t('ui.home.totalBalance')}}</span>
                        </v-col>
                        <v-icon x-large color="success">
                            mdi-wallet-outline
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2> {{ totalCalculationResult }} </h2>
                            <span class="fs-12">{{$t('ui.home.totalCalculations')}}</span>
                        </v-col>
                        <v-icon x-large color="warning">
                            mdi-database-search
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2> {{totalApiKey}} </h2>
                            <span class="fs-12">{{$t('ui.home.apiKeys')}}</span>
                        </v-col>
                        <v-icon x-large color="danger">
                            mdi-key
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="userInfo.isSystemAdmin">
            <!--Statistics-->
            <v-col cols="12" sm="6" lg="3">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2> {{dailyUploadCreditNumber}} </h2>
                            <span class="fs-12">{{$t('ui.home.dailyUploadCreditNumber')}}</span>
                        </v-col>
                        <v-icon x-large color="primary">
                            mdi-counter
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2> {{ dailyUploadCreditAmount }} </h2>
                            <span class="fs-12">{{$t('ui.home.dailyUploadCreditAmount')}}</span>
                        </v-col>
                        <v-icon x-large color="success">
                            mdi-credit-card-plus
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2> {{ dailyUsedCodeNumber }} </h2>
                            <span class="fs-12">{{$t('ui.home.dailyUsedCodeNumber')}}</span>
                        </v-col>
                        <v-icon x-large color="warning">
                            mdi-barcode
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2> {{totalDiscount}} </h2>
                            <span class="fs-12">{{$t('ui.home.totalDiscount')}}</span>
                        </v-col>
                        <v-icon x-large color="danger">
                            mdi-key
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-4">
            <!-- Graph -->
            <v-col :lg="userInfo.isSystemAdmin ? 6 : 8">
                <v-card class="pl-6 pr-6 pt-5" height="500px">
                    <v-select
                            style="height: 62px"
                            v-model="selectedPeriod"
                            :items="periods"
                            :label="$t('ui.calculation.period')"
                            return-object
                    ></v-select>
                    <v-row class="mt-0">
                        <div style="width:100%">
                            <line-chart :height="372" :chart-data="chart.data" :options="chart.options"></line-chart>
                        </div>
                    </v-row>

                </v-card>
            </v-col>
            <!-- Latest Queries -->
            <v-col cols="12" lg="4" v-if="!userInfo.isSystemAdmin">
                <v-card class="pa-6" height="500px">
                    <v-row no-gutters>
                        <span class="fs-16 fw-semi-bold">{{$t('ui.home.recentCalculations')}}</span>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row no-gutters class="mt-6">
                        <v-col>
                            <v-data-table
                                    :headers="headers"
                                    :items="calculations"
                                    class="app-simple mt-4"
                                    item-key="name"
                                    hide-default-footer
                                    append-icon="mdi-magnify"
                                    single-line
                                    hide-details>
                                <template slot="no-data">
                                    {{$t('ui.dataTable.noData')}}
                                </template>
                                <template v-slot:item.user.name="{ item }">
                                    {{ item.user.name.toUpperCase() }}
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6" v-else>
                <v-card class="pl-6 pr-6 pt-5" height="500px">
                    <v-select
                            style="height: 62px"
                            v-model="selectWalletOption"
                            :items="walletOptions"
                            :label="$t('ui.home.creditPeriod')"
                            return-object
                    ></v-select>
                    <v-row class="mt-0">
                        <div style="width:100%">
                            <line-chart :height="372" :chart-data="chartWallet.data"
                                        :options="chartWallet.options"></line-chart>
                        </div>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-4" v-if="userInfo.isSystemAdmin">
            <v-col>
                <v-card class="pl-6 pr-6 pt-5" height="500px">
                    <div style="width:100%">
                        <line-chart :height="372" :chart-data="averageCalculationTimeChart.data"
                                    :options="averageCalculationTimeChart.options"></line-chart>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    import {LineChart} from "@/utils/Charts"

    export default {
        components: {
            LineChart
        },
        data() {
            return {
                userInfo: this.$store.getters['core/getUserInfo'],

                //total
                totalUser: null,
                totalBalance: null,
                totalCalculationResult: null,
                totalApiKey: null,
                totalWallet: null,
                walletOperation: null,

                dailyUploadCreditNumber: 0,
                dailyUploadCreditAmount: 0,
                dailyUsedCodeNumber: 0,
                totalDiscount: 0,

                apiKeys: null,
                calculations: [],

                headers: [
                    {
                        text: this.$t('ui.home.user'),
                        value: "user.name",
                        align: "left"
                    },
                    {
                        text: this.$t('ui.home.calculation'),
                        value: "pid",
                        width: "10"
                    }

                ],

                //chart
                period: 0,
                selectWalletOption: 0,
                walletOptions: [{
                    text: this.$t('ui.home.loadedCredit'),
                    value: 0
                }, {
                    text: this.$t('ui.home.spentCredit'),
                    value: 1
                }],
                selectedPeriod: {
                    text: this.$t('ui.calculation.daily'),
                    value: 0
                },
                periods: [{
                    text: this.$t('ui.calculation.daily'),
                    value: 0
                }, {
                    text: this.$t('ui.calculation.weekly'),
                    value: 1
                }, {
                    text: this.$t('ui.calculation.monthly'),
                    value: 2
                }],
                chart: {},
                chartWallet: {},
                averageCalculationTimeChart: {}
            }
        },

        watch: {
            selectedPeriod: async function (period) {
                await this.renderLineChart([], []);
                this.period = period.value;
                this.getCalculationPeriod();
            },
            selectWalletOption: async function (value) {
                await this.renderLineChart([], []);
                this.getCreditPeriod(this.walletOperation, value.value);
            },

        },

        methods: {
            async getTotal() {
                try {
                    let result = this.userInfo.isSystemAdmin ? await this.$http(`/admin/totalData?excludeSystemAdmin=true`) : await this.$http(`/users/${this.userInfo.pid}/totalData`);
                    this.totalBalance = result.data.totalBalance;
                    this.totalUser = result.data.totalUser;
                    this.totalApiKey = result.data.totalApiKey;
                    this.totalCalculationResult = result.data.totalTaskResult;
                    this.totalWallet = result.data.totalWallet;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            //TODO:BUSE
            //TODO:GRAFANA ILE DUZENLENECEK
            async getWalletOperation() {
                try {
                    let totalCreditAmount = 0;
                    let result = await this.$http(`/admin/logs/wallet-operations`);
                    this.walletOperation = result.data;
                    if(this.walletOperation.uploadBalanceData  ){
                        this.dailyUploadCreditNumber = this.walletOperation.uploadBalanceData.length;
                        this.walletOperation.uploadBalanceData.forEach((data) => {
                            totalCreditAmount = totalCreditAmount + data.balance;
                        });
                        this.dailyUsedCodeNumber = this.walletOperation.dailyUsedCodeNumber;
                        this.totalDiscount = this.walletOperation.totalDiscount;
                    }
                    await this.getCreditPeriod(this.walletOperation, this.selectWalletOption);
                    this.dailyUploadCreditAmount = totalCreditAmount;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            getCreditPeriod(walletOperation, option) {
                let labels = [];

                let hours = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00",
                    "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "00:00"
                ];

                for (let i = 0; i < new Date().getHours() + 1; i++) {
                    labels.push(hours[i]);
                }
                this.setChartDataWallet(option === 0 ? walletOperation.uploadBalanceData : walletOperation.spendBalanceData, labels, hours,);
            },

            async getCalculationPeriod() {
                try {
                    let result = !this.userInfo.isSystemAdmin ? await this.$http.get(`/users/${this.userInfo.pid}/taskPeriod?period=${this.period}`)
                        : await this.$http.get(`/admin/taskPeriod?period=${this.period}`);
                    if (this.period === 0) {

                        let labels = [];

                        let hours = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00",
                            "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "00:00"
                        ];

                        for (let i = 0; i < new Date().getHours() + 1; i++) {
                            labels.push(hours[i]);
                        }

                        this.setChartData(result.data.requestTimeList, labels, hours);

                    } else if (this.period === 1) {

                        let labels = [];

                        let weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                        for (let i = 0; i < new Date(new Date()).getDay(); i++) {
                            labels.push(weekDays[i]);
                        }

                        await this.setChartData(result.data.requestTimeList, labels, weekDays);

                    } else if (this.period === 2) {

                        let labels = [];
                        let monthDays = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15",
                            "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27"];

                        for (let i = 0; i < new Date().getDate(); i++) {
                            labels.push(await this.monthDayControl(monthDays)[i]);
                        }

                        this.setChartData(result.data.requestTimeList, labels, monthDays);
                    }
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async getCalculationResults() {
                try {
                    let result = await this.$http.get(`/users/${this.userInfo.pid}/taskResults?dashboard=true`);
                    this.calculations = result.data.items;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async monthDayControl(monthDays) {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async resolve => {
                    let monthDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();

                    if (monthDay > monthDays.length - 1) {
                        monthDays.push(monthDays.length.toString());
                        monthDays = await this.monthDayControl(monthDays);
                    } else {
                        resolve(monthDays);
                    }
                })
            },

            async setChartData(requestTimeList, labels, tempDateList) {
                let data = [];
                let labelList = [];
                requestTimeList.forEach(time => {
                    labels.forEach((item, index) => {
                        if (isNaN(data[index])) {
                            data[index] = 0;
                        }
                        let dailyCond = (new Date(time).getHours()).toString().length === 1 ? '0' + (new Date(time).getHours()).toString() : (new Date(time).getHours()).toString();
                        let condition = this.period === 0 ? (dailyCond === item.split(':')[0]) :
                            (this.period === 1 ? (new Date(time).getDay() === index + 1) : (this.period === 2 ? (new Date(time).getDate() === index + 1) : null));
                        if (condition) {
                            if (this.period === 0) {
                                data[index] += 1;
                            } else {
                                data[index] += 1;
                            }
                        }
                    });
                });

                let dateList = this.period === 2 ? await this.monthDayControl(tempDateList) : tempDateList;
                for (let i = 0; i < dateList.length; i++) {
                    labelList.push(dateList[i]);
                }
                this.renderLineChart(labelList, data, 'chart')
            },

            async setChartDataWallet(wallet, labels, tempDateList,) {
                let data = [];
                let labelList = [];
                //todo:buse
                if(wallet){
                    wallet.forEach(time => {
                        labels.forEach((item, index) => {
                            if (isNaN(data[index])) {
                                data[index] = 0;
                            }
                            let dailyCond = (new Date(time.time).getHours()).toString().length === 1 ? '0' + (new Date(time.time).getHours()).toString() : (new Date(time.time).getHours()).toString();
                            let condition = dailyCond === item.split(':')[0];

                            if (condition) {
                                data[index] += time.balance
                            }
                        });
                    });
                }

                let dateList = tempDateList;
                for (let i = 0; i < dateList.length; i++) {
                    labelList.push(dateList[i]);
                }

                this.renderLineChart(labelList, data, 'chartWallet')
            },

            renderLineChart(labels, data, option) {

                this[option] = {
                    data: {
                        labels: labels,
                        datasets: [
                            {
                                label: option === 'chart' ? this.$t('ui.home.calculations') : option === 'chartWallet' ? this.$t('ui.home.credit') : this.$t('ui.home.average'),
                                borderColor: "rgb(255,152,0)",
                                borderWidth: 4,
                                backgroundColor: 'rgb(255,152,0,0.2)',
                                data: data
                            }
                        ]
                    },
                    options: {
                        maintainAspectRatio: false,
                        elements: {
                            line: {
                                tension: 0
                            }
                        }
                    }
                }
            },

            async getCalculationAverageTime() {

                let result = await this.$http.get(`/admin/taskPeriod?period=2&elapsed-time=true`);
                (result.data.calculationDataList).map(obj => {
                    obj.averageTime = obj.elapsedTime / obj.index
                });
                let labels = [];
                let dataSet = [];
                if (result.data.calculationDataList) {
                    await result.data.calculationDataList.forEach((data) => {
                        labels.push(data.name);
                        dataSet.push(data.averageTime);
                    })
                }
                this.renderLineChart(labels, dataSet, 'averageCalculationTimeChart');
            }
        },
        mounted() {
            if (!this.userInfo.isSystemAdmin) {
                this.getCalculationResults();
            } else {
                this.getWalletOperation();
                this.getCalculationAverageTime();
            }
            this.getTotal();
            this.getCalculationPeriod()
        }

    }
    ;

</script>