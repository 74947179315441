<template>
    <v-row justify="center">
    <v-dialog v-model="value" persistent max-width="600px">
        <v-card>
            <v-toolbar class="elevation-1 base primary" dark>
                <v-toolbar-title>{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon @click="close()" v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    {{$t('ui.general.close')}}
                </v-tooltip>
            </v-toolbar>
            <v-container class="pa-0">
                <v-col class="pa-6">
                    <v-row no-gutters>
                        <v-col v-html="text">
                            {{text}}
                        </v-col>
                    </v-row>
                    <v-row v-if="validator" class="mt-2">
                        <v-col>
                            <span>{{$t('ui.deleteItemDialog.please')}} <b>{{validator}}</b> {{$t('ui.deleteItemDialog.toBeAbleToDelete')}}  </span>
                            <v-text-field
                                    v-model="validatorText"
                                    :maxLength="50"
                                    :error-messages="error"
                                    @input="validate"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider></v-divider>
                <v-col>
                    <v-row justify="end" no-gutters>
                        <v-btn class="mx-1" color="warning" @click="close">
                            {{$t('ui.general.cancel')}}
                        </v-btn>
                        <v-btn class="mx-1"
                               color="danger"
                               @click="deleteItem"
                               style="color: #FFFFFF;"
                               :disabled="disabled"
                               :loading="loading">
                            <span v-if="!removeFlag">{{$t('ui.general.delete')}}</span>
                            <span v-else>{{$t('ui.general.remove')}}</span>
                        </v-btn>
                    </v-row>
                </v-col>
            </v-container>
        </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
    export default {
        props : [ "value", "loading", "title", 'text', 'validator' , "closeFlag" , "removeFlag" ],
        data() {
            return {
                disabled : true,
                error : null,
                validatorText: ""
            }
        },
        watch : {
            'value' : {
                handler (){
                    this.clear();
                }
            }
        },
        methods : {
            clear (){
                this.error = null;
                this.validatorText= "";
            },
            close() {
                if (this.closeFlag) {
                    this.$emit("close", true)
                } else {
                    this.$emit("input", false);
                }
            },
            deleteItem() {
                if((this.validator && this.validate()) || !this.validator ){
                    this.$emit("delete");
                }
            },
            validate (){
                if(this.validator !== this.validatorText){
                    this.disabled = true;
                    this.error = "Name not mismatch";
                    return false;
                }
                else {
                    this.disabled = false;
                    this.error = null;
                    return true;
                }
            }
        },
        mounted (){
            this.disabled = !!this.validator;
        }
    }
</script>

<style scoped>

</style>