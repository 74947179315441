<template>
    <v-container fluid class="app-container">
        <app-title-bar
                :title="page.title">
        </app-title-bar>

        <v-card v-if="!createOrEditRequestDialog" class="mt-4">
            <v-col>
                <v-row no-gutters align="center">
                    <v-col>
                        <v-btn large class="elevation-3" color="primary" @click="createOrEditRequestDialog=true">
                          <span class="white--text" align="center">
                           {{$t('ui.request.add')}}
                          </span>
                        </v-btn>
                    </v-col>
                    <v-col class="mr-2">
                        <search-bar
                                :searchText.sync="nameSearch"
                                :searchLabel="searchLabel"
                                :noSort="true"
                                @cleanAllFilters="cleanAllFilters"
                        ></search-bar>
                    </v-col>
                </v-row>
            </v-col>
            <v-row class="mt-4">
                <v-col>
                    <v-data-table
                            :headers="headers"
                            :items="requestList"
                            :items-per-page.sync="query.pagination.limit"
                            :page.sync="query.pagination.currentPage"
                            :footer-props="tableFooterProps"
                            :server-items-length="query.pagination.totalCount"
                            :sort-by.sync="query.sort.field"
                            :sort-desc.sync="query.sort.order"
                            :loading="listLoading"

                            class="app-list">
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <template v-slot:item.type="{ item }">
                            <span>{{$t('ui.request.'+ item.type)}}</span>
                        </template>
                        <template v-slot:item.calculationType="{ item }">
                            <span>{{item.calculationType}}</span>
                        </template>
                        <template v-slot:item.price="{ item }">
                            <span>{{item.price}} {{$t('ui.request.balance')}}</span>
                        </template>
                        <template v-slot:item.status="{ item }">
                                        <span v-if="item.status===true || item.status===1">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" color="#40C38F">mdi-circle</v-icon>
                                            </template>
                                               {{$t('ui.general.active')}}
                                            </v-tooltip>
                                        </span>
                            <span v-else-if="item.status===false || item.status===0">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" color="red">mdi-circle</v-icon>
                                                </template>
                                             {{$t('ui.general.inactive')}}
                                            </v-tooltip>
                                        </span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="editRequest(item)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span> {{$t('ui.general.edit')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="openDeleteItemDialog(item)">
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span> {{$t('ui.general.delete')}}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
        <create-or-edit-request-page v-if="createOrEditRequestDialog" :request="selectedRequest"  @refresh="refresh"
                             @close="close"></create-or-edit-request-page>
        <delete-item-dialog
                v-model="deleteItemDialogStatus"
                :loading="deleteItemDialogLoading"
                :title="$t('ui.request.delete')"
                :text="$t('ui.request.deleteText')"
                @delete="deleteRequest">
        </delete-item-dialog>
    </v-container>
</template>

<script>
    import SearchBar from "@/modules/core/components/SearchBar";
    import Pagination from "@/modules/core/mixins/pagination.js";
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";
    import CreateOrEditRequestPage from "@/modules/admin/components/CreateOrEditRequestPage";
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import {store} from "ekx-vue-base";

    export default {
        components: {
            SearchBar,
            CreateOrEditRequestPage,
            DeleteItemDialog
        },
        mixins: [
            new Pagination({
                methodName: "getRequestList",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    name: "=lk="
                },
                searchMirror: {
                    name: {
                        path: "nameSearch"
                    }
                }
            })
        ],
        data() {
            return {
                page: {
                    title: this.$t('ui.sidebar.requests'),
                },
                requestList: [],
                selectedRequest: null,
                listLoading: false,

                //dialogs
                createOrEditRequestDialog: false,
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,

                //search
                searchLabel: this.$t('ui.searchBar.request'),
                nameSearch: "",
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                tableOptions: {},
                headers: [
                    {
                        text: this.$t('ui.request.calculationType'),
                        value: "calculationType",
                        align: "start",
                        width: "220"
                    },
                    {
                        text: this.$t('ui.request.name'),
                        value: "name",
                        width: "220"
                    },
                    {
                        text: this.$t('ui.request.type'),
                        value: "type",
                        width: "200"
                    },
                    {
                        text: this.$t('ui.request.status'),
                        value: "status",
                        width: "200"
                    },
                    {
                        text: this.$t('ui.request.price'),
                        value: "price",
                        width: "200"
                    },
                    {text: this.$t('ui.general.action'), value: "actions", width: "110", sortable: false}
                ]
            };
        },
        created() {
            EkxDebounce.watch(this, "nameSearch", 500, () => {
                this.query.search.name.value = this.nameSearch;
            });
        },
        watch:{
            'query.sort.order': function (val) {
                if(typeof val === 'boolean') {
                    this.query.sort.order = val ? 'asc' : 'desc'
                }else if(typeof val === 'object') {
                    this.query.sort.order = val[0] ? 'asc' : 'desc'
                }
            }
        },


        methods: {
            async getRequestList() {
                let query = this.createQuery();
                this.selectedRequest = null;
                try {
                    this.listLoading = true;
                    let response = await this.$http.get(`/admin/requests?${query}`);
                    this.requestList = response.data.items;
                    this.query.pagination = response.data.meta.pagination;
                } catch (error) {
                    this.$error.notify(error)
                }finally {
                    this.listLoading = false;
                }
            },

            editRequest(item) {
                this.selectedRequest = {};
                this.selectedRequest.name = item.name;
                this.selectedRequest.pid = item.pid;
                this.selectedRequest.calculationType = item.calculationType;
                this.selectedRequest.price = item.price;
                this.selectedRequest.type = item.type;
                this.selectedRequest.validation = JSON.parse(item.validation);
                this.selectedRequest.status = item.status;
                this.createOrEditRequestDialog = true;
            },

            openDeleteItemDialog(item) {
                this.selectedRequest = item;
                this.deleteItemDialogStatus = true
            },

            async deleteRequest() {
                try {
                    this.deleteItemDialogLoading = true;
                    await this.$http.delete(`/admin/requests/${this.selectedRequest.pid}`);
                    this.$snotify.success(this.$t('ui.request.deleteSuccess'));
                    this.getRequestList();
                } catch (error) {
                    this.$error.notify(error);
                } finally {
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                }
            },
            // getCalculationType(type) {
            //     let calculationTypes = {
            //          0: "AMORTIZING_SWAP" ,
            //          1: "ASIAN_OPTION",
            //          2: "BASIS_SWAP" ,
            //          3: "CAP_VOLATILITY",
            //          4: "CC_SWAP",
            //          5: "DEPOSIT ",
            //          6: "EWMA",
            //          7: "FIXED_RATE_BOND",
            //          8: "FLOATING_BOND",
            //          9: "FORWARD_RATE_AGREEMENT",
            //         10: "FX_FORWARD:",
            //         11: "FX_SWAP",
            //         12: "INFLATION_CPI_BOND",
            //         13: "INTEREST_RATES_SWAP",
            //         14: "LOAN",
            //         15: "ND_FORWARD",
            //         16: "OVERNIGHT_INDEX_SWAP",
            //         17: "RISK",
            //         18: "SWAPTION",
            //         19: "VANILLA_OPTION",
            //         20: "VANNA_VOLGA",
            //         21: "VOLATILITY_SURFACE",
            //         22: "YIELD_CURVE_CALCULATION",
            //         23: "ZERO_COUPON_BOND",
            //     };
            //     return calculationTypes[type];
            // },
            refresh() {
                this.createOrEditRequestDialog = false;
                this.getRequestList()
            },

            close() {
                this.selectedRequest = null;
                this.createOrEditRequestDialog = false;
            }
        },
        mounted() {
            this.getRequestList();
        },
    };
</script>
