import debounce from "lodash.debounce"

function EkxDebounce() {

    this.watch = (instance, watchPath, wait, callback) => {
        let bouncyCallback = debounce(callback, wait);
        instance.$watch(watchPath, (value) => {
            bouncyCallback(value)
        })
    };

}

let instance = new EkxDebounce();
export default instance;