var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"app-container",attrs:{"fluid":""}},[(!_vm.adminUserList)?_c('app-title-bar',{attrs:{"title":_vm.page.title}}):_vm._e(),_c('v-card',{class:_vm.adminUserList ? 'elevation-0' : 'mt-6'},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[(_vm.urlPrefix !=='/admin')?_c('v-col',[_c('v-btn',{staticClass:"elevation-3",attrs:{"large":"","color":"primary"},on:{"click":function($event){_vm.createSubUserDialog=true}}},[_c('span',{staticClass:"white--text",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('ui.user.dialog.createSubUser'))+" ")])])],1):_vm._e(),_c('v-col',{staticClass:"mr-2"},[_c('search-bar',{attrs:{"searchText":_vm.userSearch,"searchLabel":_vm.searchLabel,"noSort":true},on:{"update:searchText":function($event){_vm.userSearch=$event},"update:search-text":function($event){_vm.userSearch=$event},"cleanAllFilters":_vm.cleanAllFilters,"refresh":function($event){return _vm.getUserList()}}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"app-simple",attrs:{"id":"table","headers":_vm.headers,"items":_vm.userList,"item-key":"name","items-per-page":_vm.query.pagination.limit,"server-items-length":_vm.query.pagination.totalCount,"page":_vm.query.pagination.currentPage,"options":_vm.tableOptions,"footer-props":_vm.tableFooterProps,"loading":_vm.loading},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.query.pagination, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.query.pagination, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.query.pagination, "currentPage", $event)},"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.goUserDetail},scopedSlots:_vm._u([{key:"item.parent.name",fn:function(ref){
var item = ref.item;
return [(item.parent)?_c('span',[_vm._v(" "+_vm._s(item.parent.name))]):_c('span',[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.assignPermission(item)}}},on),[_c('v-icon',[_vm._v("mdi-wallet-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.wallet.authorizedWallets')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.goUserDetail(item)}}},on),[_c('v-icon',[_vm._v("equalizer")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.general.detail')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openDeleteItemDialog(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('ui.general.delete')))])])]}}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('ui.dataTable.noData'))+" ")])],2)],1)],1)],1),_c('wallet-permission-dialog',{attrs:{"user":_vm.selectedUser},on:{"close":function($event){return _vm.close()}},model:{value:(_vm.walletPermissionDialog),callback:function ($$v) {_vm.walletPermissionDialog=$$v},expression:"walletPermissionDialog"}}),_c('create-sub-user-dialog',{on:{"refresh":function($event){return _vm.refresh()},"close":function($event){return _vm.close()}},model:{value:(_vm.createSubUserDialog),callback:function ($$v) {_vm.createSubUserDialog=$$v},expression:"createSubUserDialog"}}),_c('delete-item-dialog',{attrs:{"loading":_vm.deleteItemDialogLoading,"title":_vm.$t('ui.user.delete'),"text":_vm.$t('ui.user.deleteText')},on:{"delete":_vm.deleteUser},model:{value:(_vm.deleteItemDialogStatus),callback:function ($$v) {_vm.deleteItemDialogStatus=$$v},expression:"deleteItemDialogStatus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }