<template>
    <v-container fluid class="app-container">
        <app-title-bar
                :title="page.title" :items="urlPrefix==='' ? page.items : page.adminItems">
        </app-title-bar>

        <v-card class="pt-4 mt-6">
            <v-col v-if="userInfo && urlPrefix==='' && !userInfo.parent">
                <v-row no-gutters>
                    <v-btn large color="primary" @click="loadBalanceDialog=true">
                        <span class="white--text mt-1">
                            {{$t('ui.wallet.loadBalance')}}
                        </span>
                    </v-btn>
                    <v-btn large color="primary" class="ml-2" @click="balanceReset()">
                        <span class="white--text mt-1">
                            {{$t('ui.wallet.resetBalance')}}
                        </span>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="deleteItemDialogStatus=true">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-row>
            </v-col>
            <v-divider v-if="userInfo && urlPrefix==='' && !userInfo.parent" class="mt-4"></v-divider>
            <v-card-title>
                <span>{{$t('ui.wallet.name')}}</span>
                <v-spacer v-if="urlPrefix==='/admin'"></v-spacer>
                <v-btn v-if="urlPrefix==='/admin'" color="primary" @click="deleteItemDialogStatus=true">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text v-if="walletInfo">
                <span>{{walletInfo.name}}</span>
            </v-card-text>
            <v-card-title>
                <span>{{$t('ui.wallet.balance')}}</span>
            </v-card-title>
            <v-card-text v-if="walletInfo">
                <span>{{walletInfo.balance}} {{$t('ui.wallet.credit')}}</span>
            </v-card-text>

        </v-card>
        <sub-wallet-user-list v-if="walletInfo && !userInfo.parent" :wallet="walletInfo"></sub-wallet-user-list>

        <load-balance-dialog v-if="loadBalanceDialog && userInfo" @refresh="refresh()" @close="close()" :wallet="walletInfo"
                             :userInfo="userInfo"
                             :parentWallet="parentWallet"
                             v-model="loadBalanceDialog"></load-balance-dialog>
        <reset-balance-dialog v-if="resetBalanceDialog" v-model="resetBalanceDialog" :wallet="walletInfo"
                              @close="close()"
                              @refresh="refresh()"></reset-balance-dialog>
        <delete-item-dialog
                v-model="deleteItemDialogStatus"
                :loading="deleteItemDialogLoading"
                :title="$t('ui.wallet.delete')"
                :text="$t('ui.wallet.deleteText')"
                @delete="deleteSubWallet">
        </delete-item-dialog>
    </v-container>
</template>

<script>
    import LoadBalanceDialog from "@/modules/wallet/components/dialogs/LoadBalanceDialog";
    import ResetBalanceDialog from "@/modules/wallet/components/dialogs/ResetBalanceDialog";
    import SubWalletUserList from "@/modules/wallet/components/SubWalletUserList";
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"

    export default {

        components: {
            LoadBalanceDialog,
            ResetBalanceDialog,
            DeleteItemDialog,
            SubWalletUserList
        },

        data() {
            return {
                userPid: this.$route.meta.permission === 'admin' ? this.$route.params.userPid
                    : (this.$store.getters['core/getUserInfo']).pid,
                userInfo: null,
                urlPrefix: (this.$route.meta && this.$route.meta.permission === 'admin') ? '/admin' : '',
                loadBalanceDialog: false,
                walletInfo: null,
                parentWallet: null,
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,
                resetBalanceDialog: false,

                page: {
                    title: null,
                    items: [
                        {
                            text: this.$t('ui.wallet.header'),
                            exact: false,
                        }, {
                            text: this.$t('ui.wallet.myWallets'),
                            exact: true,
                            to: `/wallets`
                        },
                        {
                            text: this.$t('ui.wallet.subWalletDetail'),
                            disabled: true
                        }
                    ],
                    adminItems: [
                        {
                            text: this.$t('ui.sidebar.users'),
                            exact: true,
                            to: `/admin/users`,
                        }, {
                            text: this.$t('ui.user.subUserDetail'),
                            exact: true,
                            to: `/admin/users/${this.$route.params.userPid}`
                        },
                        {
                            text: this.$t('ui.wallet.header'),
                            exact: this.$route.meta.permission !== 'admin',
                            to: null,
                        },
                        {
                            text: this.$t('ui.wallet.walletDetail'),
                            disabled: true
                        }
                    ]
                },
            }
        },
        watch: {
            '$route.params': {
                handler() {
                    this.getWallet();
                }
            }
        },
        methods: {

            async getWallet() {
                await this.getUser();
                try {
                    let response = await this.$http.get(`${this.urlPrefix}/wallets/${this.$route.params.subwalletPid}`);
                    if (!response.data.parent && this.urlPrefix === '') {
                        this.$router.push(`${this.urlPrefix}/wallets`).catch(() => {
                        });
                    } else {
                        this.walletInfo = response.data;
                        this.page.title = response.data.name;
                    }
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async getUser() {
                try {
                    this.userInfo = (await this.$http.get(`${this.urlPrefix}/users/${this.userPid}`)).data;
                    this.parentWallet = this.userInfo.wallet.filter(function (item) {
                        return item.parentId === null
                    })[0];
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async balanceReset() {
                if (this.walletInfo.balance > 0) {
                    this.resetBalanceDialog = true
                } else {
                    this.$snotify.warning(this.$t('ui.wallet.balanceWarning'));
                }
            },

            async deleteSubWallet() {
                try {
                    this.deleteItemDialogLoading = true;
                    await this.$http.delete(`${this.urlPrefix}/wallets/${this.walletInfo.pid}`);
                    this.$snotify.success(this.$t('ui.wallet.deleteSuccess'));
                    if(this.urlPrefix === '/admin') {
                        this.$router.push(`${this.urlPrefix}/users/${this.userPid}`).catch(() => {
                        });
                    }else {
                        this.$router.push(`/wallets`).catch(() => {
                        });
                    }
                } catch (error) {
                    this.$error.notify(error);
                } finally {
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                }
            },

            refresh() {
                this.loadBalanceDialog = false;
                this.resetBalanceDialog = false;
                this.getWallet();
            },

            close() {
                this.loadBalanceDialog = false;
                this.resetBalanceDialog = false;
            }
        },

        mounted() {
            this.getWallet();
        }
    }


</script>