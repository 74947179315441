/* eslint-disable */
import Vue from "vue";
import {store} from "ekx-vue-base"
import {UserManager} from "oidc-client";
import once from 'lodash.once'

let scopeItems = [
    "rpe:user:info",
    "rpe:user:list",
    "rpe:user:update",
    "rpe:user:create",
    "rpe:user:email-control",
    "rpe:user:email-control",
    "rpe:wallet:list",
    "rpe:wallet:info",
    "rpe:wallet:create",
    "rpe:wallet:update",
    "rpe:wallet:delete",
    "rpe:feedback:create",
    "rpe:log:list",
    "rpe:api-key:delete",
    "rpe:api-key:info",
    "rpe:api-key:create",
    "rpe:api-key:list",
    "rpe:api-key:update",
    "rpe:api-key:name-control",
    "rpe:notification:list",
    "rpe:notification:update",
    "rpe:customer:create",
    "rpe:get:total",
    "rpe:packet:list",
    "rpe:subscription:create",
    "rpe:data-source:list",
    "rpe:data-source:create",
    "rpe:data-source:name-control",
    "rpe:data-source:info",
    "rpe:data-source:delete",
    "rpe:task:result",
    "rpe:userCode:create",
    "rpe:creditCode:list",
    "rpe:promotionCode:list",
    "rpe:discount:apply",
    "rpe:request:list",
    "rpe:data-source:update",
    "rpe:payment-source:update",
    "rpe:payment-source:info",
    "rpe:user:payment-source:create",
    "rpe:user:payment-source:list",
    "rpe:payment-source:delete",
    "rpe:user:delete",
    "rpe:payment:create",
    "rpe:payment:info",
    "rpe:openSource:list",
    "rpe:openSource:info",
    "rpe:permission:create",
    "rpe:permission-user:info",
    "rpe:permission:delete",
    "rpe:user:field-control",

    ////admin////
    "rpe:admin:user:field-control",
    "rpe:admin:calculation:status:update",
    "rpe:admin:task-result:list",
    "rpe:admin:wallet:info",
    "rpe:admin:wallet:delete",
    "rpe:admin:wallet:update",
    "rpe:admin:code:list",
    "rpe:admin:code:create",
    "rpe:admin:code:edit",
    "rpe:admin:code:delete",
    "rpe:admin:notification:update",
    "rpe:admin:notification:list",
    "rpe:admin:wallet:list",
    "rpe:admin:code:assign",
    "rpe:admin:get:total",
    "rpe:admin:permission-user:info",
    "rpe:admin:api-key:name-control",
    "rpe:admin:api-key:update",
    //"rpe:admin:api-key:delete",
    "rpe:admin:api-key:info",
    "rpe:admin:api-key:create",
    "rpe:admin:api-key:list",
    "rpe:admin:request:list",
    "rpe:admin:request:create",
    "rpe:admin:request:update",
    "rpe:admin:request:delete",
    "rpe:admin:request:name-control",
    "rpe:admin:request:path-control",
    "rpe:admin:task-result:info",
    "rpe:admin:log:list",
    "rpe:admin:user:info",
    "rpe:admin",
    "rpe:admin:user:list",
    "rpe:admin:user:update",
    "rpe:admin:user:create",
    "rpe:admin:user:delete",
    "rpe:admin:feedback:list",
    "rpe:admin:task:result",
];

class OidcService {
    constructor() {
        const oidcSettings = {
            authority: process.env.VUE_APP_OIDC_PROVIDER_URI,
            client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
            redirect_uri: window.location.origin + '/login-callback',
            post_logout_redirect_uri: window.location.origin,
            response_type: 'id_token token',
            automaticSilentRenew: true,
            filterProtocolClaims: true,
            loadUserInfo: true,
            scope: 'openid profile email ' + scopeItems.join(" "),
            silent_redirect_uri: window.location.origin + '/silent-renew.html',
            extraQueryParams: {
                audience: process.env.VUE_APP_OIDC_AUDIANCE
            }
        };

        this.options = {
            locale: "en"
        };

        this.mgr = new UserManager(oidcSettings);

        this.eventMap = {
            userLoaded: [],
            userUnloaded: [],
            error: [],
            accessTokenExpiring: [],
            accessTokenExpired: [],
            silentRenewError: [],
            userSignedOut: []
        };

        attach_events.call(this);
    }

    $on(eventName, cb) {
        this.getEventList(eventName).push(cb);
    }

    $emit(eventName, data) {
        this.getEventList(eventName).forEach((cb) => {
            cb(data);
        })
    }

    getEventList(eventName) {
        if (!this.eventMap[eventName]) {
            console.error(`Unknown event : ${eventName}`);
        }
        return this.eventMap[eventName]
    }

    async checkLogin(silent) {
        const user = await this.mgr.getUser();
        if (user && !user.expired) {
            return true
        } else {
            if (silent) {
                try {
                    return await this.signInSilent();
                } catch (e) {
                    return this.signIn()
                }
            } else {
                return this.signIn()
            }

        }
    }

    async renewToken() {
        return this.mgr.signinSilent()
    }

    async signIn(state) {
        const _state = {
            location: window.location.href,
            data: state
        };

        return this.mgr.signinRedirect({state: _state, ui_locales: this.options.locale});
    }

    async signInSilent(state) {
        const _state = {
            location: window.location.href,
            data: state
        };
        return this.mgr.signinSilent({state: _state, ui_locales: this.options.locale});
    }

    async signOut() {
        return this.mgr.signoutRedirect(arguments);
    }

    async signUp(state) {
        const _state = {
            location: window.location.href,
            data: state
        };

        // let extraQueryParams = {prompt: 'signup'};
        let extraQueryParams = {};

        if (state.invitePid) {
            extraQueryParams.invitePid = state.invitePid;
        }

        return this.mgr.signinRedirect({state: _state, prompt: 'signup', extraQueryParams})
    }

    async isSignedIn() {
        const user = await this.mgr.getUser();
        return !!user;
    }

    async getIdToken() {
        const user = await this.mgr.getUser();
        return user ? user.id_token : null;
    }

    async getSessionState() {
        const user = await this.mgr.getUser();
        return user ? user.session_state : null;
    }

    async getAccessToken() {
        const user = await this.mgr.getUser();
        return user ? user.access_token : null;
    }

    async getScopes() {
        const user = await this.mgr.getUser();
        return user ? user.scopes : null;
    }

}

OidcService.prototype.getUser = once(async function () {
    const user = await this.mgr.getUser();
    if (user) {
        this.user = user.profile;
        this.access_token = user.access_token;
        this.id_token = user.id_token;
        this.session_state = user.session_state;
        this.scopes = user.scopes;
        this.eventMap['userLoaded'].forEach(e => e.call(this, user));
    } else {
        console.debug(user)
    }
    return this.user;
});

function attach_events() {

    this.mgr.events.addUserLoaded((user) => {

        store.commit("core/setUser", user);
        this.eventMap['userLoaded'].forEach(e => e.call(this, user));
        this.user = user.profile;
        this.access_token = user.access_token;
        this.id_token = user.id_token;
        this.session_state = user.session_state;
        this.scopes = user.scopes;
    });

    this.mgr.events.addUserUnloaded((e) => {
        store.commit("core/dropUser");
        //console.debug('userUnload')
    });

    this.mgr.events.addAccessTokenExpiring(() => {
        this.eventMap['accessTokenExpiring'].forEach(e => e.call(this, ...arguments));
        // this.log.debug('AccessToken Expiring：', ...arguments);
    });

    this.mgr.events.addAccessTokenExpired(() => {
        this.eventMap['accessTokenExpired'].forEach(e => e.call(this, ...arguments));
    });

    this.mgr.events.addSilentRenewError(() => {
        this.eventMap['silentRenewError'].forEach(e => e.call(this, ...arguments));
        this.mgr.signoutRedirect().then((resp) => {
            //this.log.debug('signed out', resp);
        }).catch((err) => {
            //this.log.error(err)
        })
    });

    this.mgr.events.addUserSignedOut(() => {
        //console.debug('userSignOut');
        this.eventMap['userSignedOut'].forEach(e => e.call(this, ...arguments));
    });
}


let instance = new OidcService();
Object.defineProperties(Vue.prototype, {
    $oidc: {
        get: function () {
            return instance
        }
    }
});
export default instance;