<template>
    <v-row justify="center">
        <v-dialog v-model="value" persistent max-width="600px">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title>{{ $t('ui.openSource.getData') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-container class="pa-0">
                    <v-col class="pa-6">
                        <v-row no-gutters>
                            <v-col>
                                {{$t('ui.openSource.getDataText')}}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-divider></v-divider>
                    <v-col>
                        <v-row justify="end" no-gutters>
                            <v-btn class="mx-1" color="warning" @click="close">
                                {{$t('ui.general.cancel')}}
                            </v-btn>
                            <v-btn class="mx-1"
                                   color="danger"
                                   :loading="loading"
                                   @click="createOpenSourceData()"
                                   style="color: #FFFFFF;">
                                <span>{{$t('ui.general.getData')}}</span>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        props: ['value', 'openSource'],
        data() {
            return {
                loading: false
            }
        },
        methods: {
            close() {
                this.loading = false;
                this.$emit("close", true)
            },

            async createOpenSourceData() {
                this.loading = true;
                try {
                    let result = await this.$http.post(`/admin/openSources/${this.openSource.pid}`);
                    if(result.data.data){
                        this.$snotify.success(this.$t('ui.openSource.getDataSuccess'));
                    }else{
                        this.$snotify.warning(this.$t('ui.openSource.getDataWarning'));
                    }
                    this.loading = false;
                    this.$emit('refresh');
                } catch (error) {
                    this.$error.notify(error);
                    this.loading = false;
                }
            },
        }
    }
</script>

<style scoped>

</style>