<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="my-3">
        <v-row no-gutters>
            <v-text-field class="no-line"
                          dense
                          maxLength="50"
                          :value="searchText"
                          @input="$emit('update:searchText',$event)"
                          clear-icon="mdi-close-circle"
                          clearable
                          @click:clear="$emit('cleanAllFilters','')"
                          hide-details
                          :placeholder="searchLabel">
                <template slot="append">
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn icon>
                                <v-icon v-on="on">search</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.search')}}
                    </v-tooltip>
                </template>
            </v-text-field>
        </v-row>
    </div>
</template>
<script>
    export default {
        props: ["searchLabel", "sortKeyList", "sortField", "sortOrder", "searchText", "noSort"],
        data() {
            return {
                //  listView: this.$store.state.core.ui.dataView.listView || false,
                sortFieldMirror: "",
                example: false,
                sortControl: true,
                items: []
            };
        },
        watch: {
            sortField: function (val) {
                this.$emit("sortFieldChange", val);
            },
            sortFieldMirror: function () {
                this.$emit("update:sortField");
            },
        },

        created() {
            if (this.noSort) {
                this.sortControl = !this.noSort;
            } else {
                this.sortControl = true;
            }
            this.items = [];
            if (!this.noSort) {
                this.sortKeyList.forEach((item => {
                    this.items.push(item);
                }));
            }
        },
    };
</script>