//

import Vue from 'vue'
import $auth from "@/utils/AuthService"

export const EkxAuth = {
    bind(el, binding) {
        if (!$auth.checkPermission(binding.value)) {
            el.style.display =  'none';
        } else {
            el.style.display =  'initial';
        }
    }
};


Vue.directive('auth', EkxAuth);
