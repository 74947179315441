<template>
    <div class="ekx-loading" v-if="value">
        <v-row no-gutters justify="center" align="center" class="fill-height">
            <v-col>
                <v-row no-gutters justify="center">
                    <span class="primary--text headline">{{$t('ui.general.loading')}}</span>
                </v-row>
                <v-row no-gutters class="mt-4" justify="center">
                    <v-progress-circular
                            size="75"
                            width="6"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        props : ["value"]
    }
</script>

<style scoped>
    .ekx-loading {
        z-index: 1;
        opacity: .7;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
</style>