<template>
    <div>
        <div class="global-loading-box"></div>
    </div>
</template>

<script>
    export default {}
</script>

<style scoped>

    .global-loading-box {
        width: 75px;
        height: 75px;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .global-loading-box:before {
        content: '';
        width: 75px;
        height: 5px;
        background: #000;
        opacity: 0.1;
        position: absolute;
        top: 95px;
        left: 0;
        border-radius: 50%;
        animation: globalLoadingShadowAnimation .5s linear infinite;
    }

    .global-loading-box:after {
        content: '';
        width: 75px;
        height: 75px;
        background: #5D92F4;
        animation: globalLoadingAnimation .5s linear infinite;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
    }

    @keyframes globalLoadingAnimation {
        17% {
            border-bottom-right-radius: 3px;
        }
        25% {
            transform: translateY(9px) rotate(22.5deg);
        }
        50% {
            transform: translateY(18px) scale(1, 0.9) rotate(45deg);
            border-bottom-right-radius: 40px;
        }
        75% {
            transform: translateY(9px) rotate(67.5deg);
        }
        100% {
            transform: translateY(0) rotate(90deg);
        }
    }

    @keyframes globalLoadingShadowAnimation {
        0%,
        100% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.2, 1);
        }
    }

</style>