<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-row justify="center">
        <v-dialog v-model="value" scrollable max-width="350px">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title>{{$t('ui.apiKey.allowedIpList')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="$emit('close')" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text style="height: 300px;" class="mt-5">
                        <v-div class="text-center">
                            <v-chip
                                    v-for="ip in apiKey.allowedIpList"
                                    :key="ip"
                                    class="ma-1"
                                    color="primary"
                            >
                                {{ip}}
                            </v-chip>
                            <v-col></v-col>
                        </v-div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="mx-1" color="warning" @click="$emit('close')">{{$t('ui.general.close')}}</v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    export default {
        props: ['value', 'apiKey']
    }
</script>