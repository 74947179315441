import Vue from 'vue'
import Router from 'vue-router'

import { store } from "ekx-vue-base"
import $oidc from "@/utils/OidcService"
import $auth from "@/utils/AuthService"

import AppLayout from "@/modules/core/components/layout/AppLayout"
import LoginCallback from "@/modules/core/pages/LoginCallback"

import Home from "@/modules/core/pages/Home"
import NotFound from "@/modules/core/pages/NotFound"
import Unauthorized from "@/modules/core/pages/Unauthorized"

import WalletDetail from "@/modules/wallet/pages/WalletDetail"
import SubUserWallet from "@/modules/wallet/pages/SubUserWallet"
import CodePage from "@/modules/code/pages/CodePage"
import Cards from "@/modules/card/pages/Cards"
import CodeDetail from "@/modules/code/pages/CodeDetail"
import SubWalletDetail from "@/modules/wallet/pages/SubWalletDetail"
import CalculationList from "@/modules/calculation/pages/CalculationList"
import CalculationDetail from "@/modules/calculation/pages/CalculationDetail"
import LoadPackets from "@/modules/wallet/pages/LoadPackets"
import UserDetail from "@/modules/user/pages/UserDetail"
import UserList from "@/modules/user/pages/UserList"
import ApiKeyList from "@/modules/apiKey/pages/ApiKeyList"
import LogList from "@/modules/user/pages/LogList"
import FeedbackList from "@/modules/feedback/pages/FeedbackList"
import RequestList from "@/modules/admin/pages/RequestList"
import DataSourceList from "@/modules/dataSource/pages/DataSourceList"
import DataSourceDetail from "@/modules/dataSource/pages/DataSourceDetail"
import OpenSourceList from "@/modules/dataSource/pages/OpenSourceList"
import OpenSourceDetail from "@/modules/dataSource/pages/OpenSourceDetail"
import OpenSourceManagement from "@/modules/admin/pages/OpenSourceManagement"
import PersonalDataProtection from "@/modules/pdp/pages/PersonalDataProtection"
import Docs from "@/modules/core/pages/Docs"

Vue.use(Router);
let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: "/",
            name: "app-layout",
            component: AppLayout,
            children: [{
                path: "/",
                name: "home",
                component: Home
            }, {
                path: "/wallets",
                name: "wallet",
                component: WalletDetail,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/subUserWallet",
                name: "subUserWallet",
                component: SubUserWallet,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/api-keys",
                name: "apiKeyList",
                component: ApiKeyList,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/calculations",
                name: "calculations",
                component: CalculationList,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/cards",
                name: "cards",
                component: Cards,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/calculations/:calculationPid",
                name: "calculationDetail",
                component: CalculationDetail,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/wallets/:subwalletPid",
                name: "subwalletDetail",
                component: SubWalletDetail,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/packets",
                name: "loadPackets",
                component: LoadPackets,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/me",
                name: "userProfile",
                component: UserDetail,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/users",
                name: "userList",
                component: UserList,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/users/:userPid",
                name: "userDetail",
                component: UserDetail,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/logs-wallet",
                name: "userWalletLog",
                component: LogList,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/personal-data-protection",
                name: "personalDataProtection",
                component: PersonalDataProtection,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/logs-query",
                name: "userQueryLog",
                component: LogList,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/data-sources",
                name: "dataSourceList",
                component: DataSourceList,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/data-sources/:data_source_pid",
                name: "dataSourceDetail",
                component: DataSourceDetail,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/open-sources",
                name: "openSourceList",
                component: OpenSourceList,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/open-sources/:openSourcePid",
                name: "openSourceDetail",
                component: OpenSourceDetail,
                meta: {
                    permission: 'user'
                }
            }, {
                path: "/admin/users/:userPid",
                name: "adminUserDetail",
                component: UserDetail,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/users",
                name: "adminUserList",
                component: UserList,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/codes",
                name: "codes",
                component: CodePage,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/codes/:codePid",
                name: "codeDetail",
                component: CodeDetail,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/me",
                name: "adminProfile",
                component: UserDetail,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/users/:userPid/logs",
                name: "userLog",
                component: LogList,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/users/:userPid/wallets/:subwalletPid",
                name: "walletDetail",
                component: SubWalletDetail,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/feedbacks",
                name: "feedbackList",
                component: FeedbackList,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/requests",
                name: "requestList",
                component: RequestList,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/open-source-management",
                name: "openSourceManagement",
                component: OpenSourceManagement,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/logs-code",
                name: "adminCodeLog",
                component: LogList,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/open-source-management/:openSourcePid",
                name: "adminOpenSourceDetail",
                component: OpenSourceDetail,
                meta: {
                    permission: 'admin'
                }
            }, {
                path: "/admin/users/:userPid/calculations/:calculationPid",
                name: "adminCalculationDetail",
                component: CalculationDetail,
                meta: {
                    permission: 'admin'
                }
            }]
        },
        {
            path: '/login-callback',
            name: 'LoginCallback',
            component: LoginCallback,
            meta: {
                isPublic: true
            }
        },
        {
            path: "/docs",
            name: "docs",
            component: Docs,
            meta: {
                isPublic: true
            }
        },
        {
            path: "/unauthorized",
            name: "unauthorized",
            component: Unauthorized,
            meta: {
                isPublic: true
            }
        },
        {
            path: "/not-found",
            name: "not-found",
            component: NotFound,
            meta: {
                isPublic: true
            }
        }
    ]
});


router.beforeEach(async(to, from, next) => {
    if (to.meta.isPublic) {
        next()
    } else {
        try {
            store.commit("core/setGlobalLoading", true);
            let isLoggedIn = await $oidc.checkLogin(true);
            if (!isLoggedIn) {
                await $oidc.signIn();
            } else {
                await $auth.checkUserInfo();
                if (to.name === null && to.matched.length === 0) {
                    next('/not-found')
                } else {
                    if (to.meta.permission) {
                        let valid = await $auth.checkPermission(to.meta.permission);
                        if (valid) {
                            next();
                        } else {
                            next("/unauthorized");
                        }
                    } else {
                        next();
                    }
                }
            }
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
                next("unauthorized");
            } else {
                next("/error")
            }
        } finally {
            store.commit("core/setGlobalLoading", false)
        }
    }
});

export default router;