<template>
    <v-dialog v-model="value" max-width="600px" :persistent="true">
        <v-card>
            <v-toolbar color="primary" dark class="elevation-1">
                <v-icon class="mr-2" color="warning">warning</v-icon>
                <v-toolbar-title>
                    <span>{{$t('ui.user.deleteAccount')}}</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon @click="close()" v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    {{$t('ui.general.close')}}
                </v-tooltip>
            </v-toolbar>
            <v-container class="pa-0">

                <v-col class="pa-6">
                    <v-text-field  :maxLength="50" v-model="userName" :label="$t('ui.user.username')"></v-text-field>
                </v-col>
                <v-divider></v-divider>
                <v-col>
                    <v-row justify="end" no-gutters>
                        <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
                        <v-btn class="mx-1" color="danger" dark :loading="loading" @click="deleteUser">
                            {{$t('ui.general.delete')}}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: ["value", "user"],
        data() {
            return {
                userInfo: this.$store.getters["core/getUserInfo"],
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                loading: false,
                userName: null
            }
        },
        methods: {

            async deleteUser() {
                if (!this.userName || this.userName !== this.user.username) {
                    this.error = true;
                    this.$snotify.warning(this.$t('ui.user.deleteError'))
                } else {
                    this.loading = true;
                    try {
                      await this.$http.delete(`${this.urlPrefix}/users/${this.user.pid}`);
                        this.userName = null;
                        this.$snotify.success(this.$t('ui.general.snotify.deleteUsers'));
                        this.$auth.signOut();
                        this.$emit("close", true);
                        this.loading = false;
                    } catch (error) {
                        this.loading = false;
                        this.$error.notify(error)
                    }
                }
            },
            close() {
                this.userName = null;
                this.$emit("close", true);
            }
        }
    };
</script>