<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card class="pt-4 mt-6">
        <!--        <v-row class="justify-center">-->
        <v-col>
            <ValidationObserver ref="createDataSourceForm">
                <ValidationProvider rules="required|whiteSpace|min:3|max:50|" v-slot="{ errors }">
                    <v-text-field v-model="name"
                                  :maxLength="50"
                                  :error-messages="errorMessage ? errorMessage : errors"
                                  :label="$t('ui.dataSource.name')" type="text"
                                  :loading="textLoading"></v-text-field>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-select
                            style="height: 62px"
                            v-model="selectedCalType"
                            :items="typeList"
                            :label="$t('ui.dataSource.selectType')"
                            :error-messages="errors"
                            return-object
                    ></v-select>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-select
                            style="height: 62px"
                            v-model="selectedFileType"
                            :items="fileTypes"
                            :label="$t('ui.dataSource.selectFileType')"
                            :error-messages="errors"
                            return-object
                            :close-on-select="false"
                    ></v-select>
                </ValidationProvider>
                <v-text-field v-if="file" v-model="this.file.name" :label="$t('ui.excel.fileName')" readonly small
                              clear-icon="mdi-close-circle"
                              :maxLength="50"
                              clearable @click:clear="removeFile">
                </v-text-field>
                <v-row justify="center">
                    <v-col v-if="selectedFileType === 'json'">
                        <ace-editor v-model="fileData" height="400px" width="100%" class="jsonEditor"
                                    lang="json" theme="chrome" view="tree"
                                      @init="editorInit"></ace-editor>
                    </v-col>
                    <v-col v-else>
                        <file-upload-dialog class="ml-0 mr-0 mb-2"
                                            v-if="!file && selectedFileType === 'excel' || selectedFileType=== 'xml' && !file"
                                            v-model="file"
                                            @closeDrag="uploadDialog = false"
                                            @fileUploaded="processUpload"/>
                        <v-row no-gutters class="mr-6">
                            <v-switch
                                    v-if="selectedFileType === 'excel' && file " v-model="isMapping"
                                    :label="$t('ui.dataSource.mapping')">
                            </v-switch>
                        </v-row>
                        <v-row no-gutters>
                            <ekx-excel-import :data="file" v-if="isMapping && selectedFileType=== 'excel' && file && !alertFlag"
                                              :columnSet="selectedCalType.value === 0 ? columnYieldCurve : selectedCalType.value === 1 ? columnYieldData : columnSetFixedRateBond"
                                              @data="dataCallback"></ekx-excel-import>
                        </v-row>
                        <div v-if="alertFlag">
                            <v-alert text outlined
                                    type="success" dense>
                                <v-row align="center">
                                    <v-col>
                                        {{$t('ui.dataSource.mappingData')}}
                                    </v-col>
                                </v-row>
                            </v-alert>
                        </div>
                    </v-col>
                </v-row>
            </ValidationObserver>
        </v-col>
        <divider></divider>
        <v-card-actions class="mb-4">
            <v-spacer></v-spacer>
            <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
            <v-btn class="mx-1" color="primary" :loading="loading" @click="create()">
                {{$t('ui.general.save')}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";
    import EkxExcelImport from "@/modules/excel/components/EkxExcelImporter.vue";
    import fileUploadDialog from "@/modules/dataSource/components/dialogs/FileUploadDialog.vue";
    import AceEditor from 'vue2-ace-editor';

    export default {
        props: ['typeList'],
        components: {
            EkxExcelImport,
            fileUploadDialog,
            AceEditor
        },
        data() {
            return {
                loading: false,
                textLoading: false,
                uploadFileFlag: false,
                uploadDialog: false,
                name: null,
                file: null,
                errorMessage: null,
                selectedCalType: null,
                fileTypes: ['json', 'excel', 'xml'],
                fileData: null,
                selectedFileType: null,
                isMapping: false,
                fileName: null,
                alertFlag: false,
                editorOptions: {
                    mode: 'code'
                },
                columnSetFixedRateBond: [{
                    type: "macth",
                    key: "redemption",
                    name: "redemption",
                    required: true
                }, {
                    type: "macth",
                    key: "period",
                    name: "period",
                    required: true
                }
                    , {
                        type: "macth",
                        key: "maturityDate",
                        name: "maturityDate",
                        required: true
                    }
                    , {
                        type: "macth",
                        key: "issueDate",
                        name: "issueDate",
                        required: true
                    }
                    , {
                        type: "macth",
                        key: "businessDayConv",
                        name: "businessDayConv",
                        required: true
                    }
                    , {
                        type: "macth",
                        key: "maturityDateConv",
                        name: "maturityDateConv",
                        required: true
                    }
                    , {
                        type: "macth",
                        key: "dateGeneration",
                        name: "dateGeneration",
                        required: true
                    }
                    , {
                        type: "macth",
                        key: "calendar",
                        name: "calendar",
                        required: true
                    }, {
                        type: "macth",
                        key: "dayCountConvention",
                        name: "dayCountConvention",
                        required: true
                    }, {
                        type: "macth",
                        key: "couponRate",
                        name: "couponRate",
                        required: true
                    }
                    , {
                        type: "macth",
                        key: "endOfMonth",
                        name: "endOfMonth",
                        required: true
                    }
                ],
                columnYieldData: [{
                    type: "macth",
                    key: "valuationDate",
                    name: "valuationDate",
                    required: true
                }, {
                    type: "macth",
                    key: "instrument",
                    name: "instrument",
                    required: true
                }, {
                    type: "macth",
                    key: "tenor",
                    name: "tenor",
                    required: true
                }, {
                    type: "macth",
                    key: "value",
                    name: "value",
                    required: true
                }, {
                    type: "macth",
                    key: "currency",
                    name: "currency",
                    required: true
                }
                ],
            };
        },
        created() {
            EkxDebounce.watch(this, "name", 500, async () => {
                if (this.name && this.name.length > 2) {
                    this.errorMessage = null;
                    this.textLoading = true;
                    let available = await this.isNameAvailable(this.name);
                    if (available) {
                        this.textLoading = false;
                        this.errorMessage = null;
                    } else {
                        this.errorMessage = this.$t('ui.general.nameError');
                        this.textLoading = false;
                    }
                }
            });
        },
        watch: {
          selectedFileType: function () {
                this.file = null;
                this.fileData = null;
            },

            isMapping: function () {
                this.alertFlag = false;
                this.fileData = null;
            }
        },
        methods: {

            close() {
                this.clear();
                this.$emit("close", true);
            },

            async create() {
                this.loading = true;
                const formData = new FormData();
                if ((this.selectedFileType === 'json' && this.fileData && Object.keys(this.fileData).length > 0) || this.selectedFileType !== 'json') {
                    if (this.selectedFileType === 'json' || (this.isMapping && this.fileData) || (!this.isMapping && this.file)) {
                        formData.append('file', this.fileData ? this.fileData : this.file);
                        formData.append('name', this.name);
                        formData.append('type', this.selectedCalType.value);
                        formData.append('isMapping', this.isMapping);
                        if (await this.$refs.createDataSourceForm.validate() && this.errorMessage === null) {
                            try {
                                await this.$http.post(`/data-sources`, formData);
                                this.$snotify.success(this.$t('ui.dataSource.createSuccess'));
                                this.clear();
                                this.$emit("refresh", true);
                            } catch (error) {
                                this.loading = false;
                                this.$error.notify(error)
                            }
                        } else {
                            this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                            this.loading = false
                        }
                    } else {
                        this.$snotify.warning(this.$t('ui.dataSource.warningFile'));
                        this.loading = false
                    }
                } else {
                    this.$snotify.warning(this.$t('ui.dataSource.warningJSON'));
                    this.loading = false
                }
            },

            handleFileUpload: function (files) {
                this.file = files
            },

            dataCallback(data, name) {
                this.fileName = name;
                this.fileData = this.selectedCalType.value === 1 ? JSON.stringify(data) : JSON.stringify(data[0]);
                if (this.fileData) {
                    this.alertFlag = true;
                    this.$snotify.success(this.$t('ui.dataSource.dataMappingSuccess'));
                }
            },

            processUpload(file) {
                this.file = file[0];
                if (this.file) {
                    this.$snotify.success(this.$t('ui.dataSource.fileUploaded'));
                }
            },
            editorInit:function (editor) {
              require("brace/mode/json");
              require("brace/theme/chrome");
              require('brace/ext/searchbox');
              editor.focus();
              editor.setShowPrintMargin(false);
              editor.setOptions({
                fontSize: "18px",
              });
            },
            async clear() {
                this.file = null;
                this.loading = false;
                this.name = null;
                this.textLoading = false;
                this.errorMessage = null;
                this.selectedCalType = null;
                this.selectedFileType = null;
                this.fileData = null;
                this.fileName = null;
                this.alertFlag = false;
                await this.$refs.createDataSourceForm.reset();
            },

            removeFile() {
                this.file = null;
                this.fileData = null;
                this.fileName = null;
                this.alertFlag = false;
            },

            async isNameAvailable(name) {
                try {
                    let response = await this.$http.get(`/data-sources/names/${name}`);
                    return !!response.data;
                } catch (error) {
                    this.$error.notify(error)
                }
            },
        },
    };
</script>
<style>
    .card-actions {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
</style>
