/**
 *
 */

import {
    store,
    http
} from "ekx-vue-base";
import $oidc from "./OidcService";
import Vue from "vue";

class AuthService {

    constructor() {
        if (store.state.core.user.access_token) {
            http.defaults.headers["authorization"] = store.state.core.user.access_token;
            http.defaults.headers['Access-Control-Allow-Origin'] = '*';
        }
    }

    checkUserInfo() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            if (!store.state.core.userInfo || !(store.state.core.userInfo.length>0)) {
                try {
                    let result = await http.get("/users/me");
                    store.commit("core/setUserInfo", result.data);
                    resolve()
                } catch (error) {
                    reject(error);
                }
            } else {
                resolve();
            }
        });
    }

    checkPermission(permission) {
        return new Promise((resolve) => {
            if (permission === 'admin') {
                if (store.state.core.userInfo.isSystemAdmin) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            } else if (permission === 'user') {
                if (!store.state.core.userInfo.isSystemAdmin) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        })
    }

    signOut() {
        store.commit("core/dropUser");
        $oidc.signOut();
    }

    signUp({invitePid}) {
        $oidc.signUp({invitePid});
    }
}

let instance = new AuthService();
Object.defineProperties(Vue.prototype, {
    $auth: {
        get: function () {
            return instance
        }
    }
});
export default instance;