<template>
    <v-row justify="center">
        <v-dialog v-model="value" persistent max-width="600px">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title>{{$t('ui.code.uploadCredit')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-container class="pa-0">
                    <v-col class="pa-3">
                        <v-row no-gutters>
                            <v-col>
                                {{$t('ui.wallet.balance')}}: {{credit.amount}} TRY
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="pa-3">
                        <v-row no-gutters>
                            <v-col>
                                {{$t('ui.code.uploadTextAmount')}}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="pa-3">
                        <v-row no-gutters>
                            <v-col>
                              <b>{{$t('ui.code.uploadText')}}</b>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-divider></v-divider>
                    <v-col>
                        <v-row justify="end" no-gutters>
                            <v-btn class="mx-1" color="warning" @click="close">
                                {{$t('ui.general.cancel')}}
                            </v-btn>
                            <v-btn class="mx-1"
                                   color="success"
                                   :loading="loading"
                                   @click="uploadCredit()"
                                   style="color: #FFFFFF;">
                                <span>{{$t('ui.general.yes')}}</span>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        props: ['value', 'credit'],
        data() {
            return {
                data: {
                    loading: false
                }
            }
        },

        methods: {
            close() {
                this.$emit("close", true)
            },

            uploadCredit() {
                this.loading=true;
                this.$emit("submit", this.loading);
            },
        }
    }
</script>

<style scoped>

</style>