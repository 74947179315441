<template>
    <approval-dialog v-model="approvalDialog" @close="close()" @refresh="refresh()" :versionList="versionList" ></approval-dialog>
</template>

<script>

    import ApprovalDialog from "@/modules/pdp/ApprovalDialog"

    export default {
        components: {
            ApprovalDialog
        },
        data() {
            return {
                approvalDialog: false,
                versionList: []
            }
        },
        methods: {
            async getVersions() {
                let result = await this.$http.get("/agreements");
                if (result.data.length > 0) {
                    this.versionList = result.data;
                    this.approvalDialog = true
                }else {
                    this.$router.push('/').catch(() => {});
                }
            },
            close() {
                this.approvalDialog = false;
                this.$router.push('/').catch(() => {});
            }
        },
        mounted() {
            //this.getVersions();
        }
    }
</script>
