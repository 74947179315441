var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-1 base primary",attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('ui.user.dialog.editUserInfo')))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('ui.general.close'))+" ")])],1),_c('v-container',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-6"},[_c('ValidationObserver',{ref:"editUserForm"},[_c('ValidationProvider',{attrs:{"rules":"required|whiteSpace|min:3|max:50|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxLength":50,"error-messages":errors,"label":_vm.$t('ui.user.username'),"type":"text"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|whiteSpace|min:3|max:50|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxLength":50,"error-messages":errors,"label":_vm.$t('ui.user.name'),"type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|whiteSpace|min:3|max:50|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('ui.user.surname'),"maxLength":50,"error-messages":errors,"type":"text"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})]}}])})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('ui.general.cancel')))]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.updateUser()}}},[_vm._v(_vm._s(_vm.$t('ui.general.save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }