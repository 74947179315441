<template>
    <v-card class="mt-4" >
        <v-col>
                    <v-select v-model="selectedRequest" :items="requestList" item-text="name"
                              return-object
                              :label="$t('ui.calculation.requestName')"
                              :loading="loadingCalculationList"
                              :error-messages="errors" class="mb-4"
                              :search-input.sync="search">
                    </v-select>
                <v-row v-if="selectedRequest" class="mt-4">
                    <v-col v-if="selectedRequest.status" class="mb-4">
                        <span style="font-size: large">{{$t('ui.calculation.price')}} : <b>{{selectedRequest.price}}</b></span>
                        {{$t('ui.request.balance')}}
                    </v-col>
                    <v-col v-else class="mb-4">
                        <v-alert
                                outlined
                                type="warning"
                                prominent
                                border="left"
                        >
                            {{$t('ui.request.statusText')}}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row v-if="(selectedRequest && selectedRequest.status)">

                    <v-col>
                            <v-switch
                                    v-model="field.fileUploadSwitch"
                                    @change="changeSwitchOption(item)"
                                    :label="$t('ui.dataSource.uploadFile')"
                            ></v-switch>
                            <AceEditor
                                    class="jsonEditor mt-5 mb-7"
                                    ref="editorEl"
                                    v-if="(showJson || (field.calculationBody && Object.entries(field.calculationBody).length >0)) "
                                    v-model="content"
                                    @init="editorInit"
                                    lang="json" theme="chrome"
                                    height="450px"
                                    @onChange="onChange"
                            ></AceEditor>
                            <file-upload-dialog
                                    v-if="!showJson  && !field.fileName && (!field.calculationBody || (field.calculationBody && Object.keys(field.calculationBody)).length === 0)"
                                    v-model="file"
                                    onlyJson="true"
                                    class="mb-7"
                                    @fileUploaded="processUpload"></file-upload-dialog>
                    </v-col>
                </v-row>
        </v-col>
        <!--                </v-row>-->
        <v-divider></v-divider>
        <v-card-actions class="mb-4 " v-if="selectedRequest && selectedRequest.status">
<!--                <v-checkbox v-model="seeOption" :label="$t('ui.calculation.seeOption')" ></v-checkbox>-->
            <v-spacer></v-spacer>
            <v-btn class="mx-1 mb-4 mt-4" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
            <v-btn class="mx-1 mb-4 mt-4" color="primary" :loading="loading"
                   @click="createCalculation()">
                {{$t('ui.general.send')}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>

    import FileUploadDialog from "@/modules/dataSource/components/dialogs/FileUploadDialog.vue";
    import FileReader from "@/modules/calculation/services/FileReader";
    import AceEditor from 'vue2-ace-editor';

    export default {
        components: {
            FileUploadDialog,
            AceEditor
        },
        data() {
            return {
                userInfo: this.$store.getters['core/getUserInfo'],
                loading: false,
                seeOption: false,
                loadingCalculationList: true,
                selectedRequest: null,
                field: {
                    fileUploadSwitch: false,
                    calculationBody: null
                },
                content: null,
                showUploadEditor: false,
                showJson: true,
            };
        },

        watch: {
            selectedRequest: function () {
                this.field.calculationBody = null;
                if (this.$refs.editorEl) {
                    this.$refs.editorEl.editor.setValue("",0);
                }
            },
            'field.calculationBody': {
                handler() {
                    if (this.field.calculationBody)
                    this.content =
                        JSON.stringify(this.field.calculationBody, null, '\t')
                }
            }
        },
        methods: {
            close() {
                this.clear();
                this.$emit('close')
            },
            editorInit:function (editor) {
                require("brace/mode/json");
                require("brace/theme/chrome");
                require('brace/ext/searchbox');
                editor.focus();
                editor.setShowPrintMargin(false);
                editor.setOptions({
                    fontSize: "18px",
                });
            },
            async createCalculation() {
                await this.getApiKey();
                this.loading = true;
                if ((this.field.calculationBody || this.content )&& typeof this.field.calculationBody === 'object'
                && this.$refs.editorEl.editor.getSession().getAnnotations().length === 0) {
                    if (this.content){
                        this.field.calculationBody = JSON.parse(this.content)
                    }
                    try {
                        await this.$http.post(`/tasks/${this.selectedRequest.calculationType}?client=false`, this.field.calculationBody, {
                            headers: {
                                'ekx-api-secret': this.apiSecret,
                                'ekx-api-key': this.apiKey
                            }
                        });
                        this.$snotify.success(this.$t('ui.calculation.createSuccess'));
                        this.clear();
                        this.$emit('refresh');
                    } catch (error) {
                        this.loading = false;
                      if (error.response.data.errors && error.response.data.errors[0].metadata && error.response.data.errors[0].metadata.errorLine ) {
                        this.errorFocus(error.response.data.errors[0].metadata.errorLine);
                      }

                        this.$error.notify(error);

                    }
                } else {
                    this.errorFocus();
                    this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                    this.loading = false
                }
            },
          errorFocus(errorLine) {
                this.$refs.editorEl.editor.focus();
                let line = errorLine ? errorLine : (this.$refs.editorEl.editor.getSession().getAnnotations())[0].row;
                this.$refs.editorEl.editor.gotoLine(line);
            },

            async getRequestList() {
                this.loadingCalculationList = true;
                this.selectedRequest = null;
                try {
                    let response = await this.$http.get(`/requests?excludeInactive=true`);
                    this.requestList = response.data.items;
                } catch (error) {
                    this.$error.notify(error)
                } finally {
                    this.loadingCalculationList = false;
                }
            },

            async getApiKey() {
                try {
                    let result = await this.$http.get(`/users/${this.userInfo.pid}/api-keys`);
                    this.apiSecret = result.data.items[0].secret;
                    this.apiKey = result.data.items[0].key;
                } catch (error) {
                    this.$error.notify(error)
                }
            },


          clear() {
                this.loading = false;
                this.selectedRequest = null;
                this.content = null;
            },

            changeSwitchOption() {
                this.field.calculationBody= null;
                this.showJson = this.showJson !== true
            },
            onChange() {
                this.$refs.editorEl.editor.focus()
            },
            processUpload(file) {
                FileReader.parseFile(file[0]).then((result) => {
                    this.field.calculationBody = JSON.parse(result);
                }).catch((error) => {
                    this.field.calculationBody = {};
                    this.$error.notify(error);
                });
                this.showJson = true;
                this.field.fileUploadSwitch = false;
                if (file[0]) {
                    this.$snotify.success(this.$t('ui.dataSource.fileUploaded'));
                }
            },

            removeFile() {
                this.field.calculationBody = {};
                this.field.fileName = null;
                this.showJson = !this.field.fileUploadSwitch;
                this.showUploadEditor = true;
            },
            // getCalculationType(type) {
            //     let calculationTypes = {
            //         0: "amortizing_swap_calculator" ,
            //         1: "asian_option_calculator",
            //         2: "basis_swap_calculator" ,
            //         3: "cap_volatility_calculator",
            //         4: "cc_swap_calculator",
            //         5: "DEPOSIT ",
            //         6: "ewma_calculator",
            //         7: "fixed_rate_bond",
            //         8: "floating_bond_calculator",
            //         9: "forward_rate_agreement_calculator",
            //         10: "fx_forward_calculator:",
            //         11: "fx_swap_calculator",
            //         12: "inflation_cpi_bond_calculator",
            //         13: "interest_rates_swap_calculator",
            //         14: "loan_calculator",
            //         15: "nd_forward_calculator",
            //         16: "overnight_index_swap_calculator",
            //         17: "risk_calculator",
            //         18: "swaption_calculator",
            //         19: "vanilla_option_calculator",
            //         20: "vanna_volga_calculator",
            //         21: "VOLATILITY_SURFACE",
            //         22: "yield_curve_calculator",
            //         23: "zero_coupon_bond_calculator",
            //     };
            //     return calculationTypes[type];
            // },
        },

        mounted() {
            this.getApiKey();
            this.getRequestList();
        }

    };
</script>