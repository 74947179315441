<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-row justify="center">
        <v-dialog v-model="value" max-width="800px" height="1900px" :persistent="true">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title>{{$t('ui.wallet.permissionDialog')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                    <v-col class="mt-2 pl-6 pr-6" v-if="walletOrUserList.length>0 && !walletDetail">
                        <span style="font-size: large" v-if="wallet">
                            {{$t('ui.wallet.authorizedUsers')}} :
                        </span>
                        <span style="font-size: large" v-else>
                            {{$t('ui.wallet.authorizedWallets')}} :
                        </span>
                        <v-divider class="mt-4"></v-divider>
                        <v-row no-gutters>
                            <v-card v-for="data in walletOrUserList" class="mr-4 mt-4" :key="data">
                                <v-list-item :key="data.name">
                                    <v-list-item-avatar v-if="wallet">
                                        <v-avatar color="primary">
                                            <v-icon color="white">mdi-account
                                            </v-icon>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-avatar v-else>
                                        <v-avatar color="primary">
                                            <v-icon color="white">mdi-wallet-outline
                                            </v-icon>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="$t(data.name)"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-tooltip bottom v-if="!userInfo.isSystemAdmin">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="openDeleteItemDialog(data)" v-bind="attrs"
                                                v-on="on">
                                                <v-icon color="red">
                                                    mdi-delete
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{$t('ui.wallet.deletePermission')}}</span>
                                    </v-tooltip>
                                </v-list-item>
                            </v-card>
                        </v-row>                        
                    </v-col>
                     <v-col class="pl-6 pr-6" v-if="!userInfo.isSystemAdmin">
                        <v-alert v-if="user" text outlined color="primary">
                            {{ $t('ui.wallet.permissionWallet') }}
                        </v-alert>
                        <v-alert v-else text outlined color="primary">
                            {{ $t('ui.wallet.permissionUser') }}
                        </v-alert>
                        <ValidationObserver ref="permissionForm">
                            <ValidationProvider rules="required" v-slot="{  }">
                                        <v-autocomplete
                                                v-model="selectedList"
                                                :items="user ? walletList : userList"
                                                :search-input.sync="searchText"
                                                :loading="searching"
                                                :label="user ?  $t('ui.wallet.header') : $t('ui.user.header')"
                                                chips
                                                deletable-chips
                                                item-text="name"
                                                no-filter
                                                return-object
                                                multiple
                                                hide-no-data
                                        >
                                            <template v-if="user" v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <v-avatar color="success">
                                                        <v-icon color="white">mdi-wallet-outline
                                                        </v-icon>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ item.balance }} TL
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                            <template v-else v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <v-avatar color="success">
                                                        <v-icon color="white">mdi-account
                                                        </v-icon>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.username }}</v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ item.name }} {{item.surname}}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                            </ValidationProvider>
                        </ValidationObserver>
                    </v-col>  
                    <v-col class="pl-6 pr-6 mt-4" v-if="userInfo.isSystemAdmin && walletOrUserList.length===0 && !walletDetail">
                        <v-alert v-if="user" text outlined color="warning">
                            {{ $t('ui.wallet.noUserPermission') }}
                        </v-alert>
                        <v-alert v-else text outlined color="warning">
                            {{ $t('ui.wallet.noWalletPermission') }}
                        </v-alert>
                    </v-col>    
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" color="warning" @click="close()"> {{$t('ui.general.close')}}</v-btn>
                    <v-btn v-if="!userInfo.isSystemAdmin" class="mx-1" color="primary" :loading="saveButtonLoading"
                           @click="savePermission()">
                        {{$t('ui.general.save')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <delete-item-dialog
                v-model="deleteItemDialogStatus"
                :loading="deleteItemDialogLoading"
                :title="$t('ui.wallet.deletePermission')"
                :text="$t('ui.wallet.deletePermissionText')"
                @delete="deletePermission">
        </delete-item-dialog>
    </v-row>
</template>

<script>
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"

    export default {
        props: ['value', 'wallet', 'user', 'walletDetail'],
        components: {
            DeleteItemDialog
        },
        data() {
            return {
                userInfo: this.$store.getters['core/getUserInfo'],
                saveButtonLoading: false,
                textLoading: false,
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,
                selectedItem: null,
                walletList: [],
                userList: [],
                walletOrUserList: [],
                searchText: "",
                selectedList: [],
                errorMessage: null,
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : ''
            };
        },

        created() {

            EkxDebounce.watch(this, 'searchText', 500, () => {
                this.textLoading = true;
                this.user ? this.getWalletList(this.searchText) : this.getUserList(this.searchText);
            });

        },
        watch: {

            'selectedList': {
                handler() {
                    this.searchText = "";
                }
            },

            'value': {
                handler(value) {
                    if (value === true) {
                        this.walletList = [];
                        this.userList = [];
                        this.walletOrUserList = [];
                        if (!this.userInfo.isSystemAdmin) {
                            this.user ? this.getWalletList() : this.getUserList();
                        } else {
                            this.getWalletsOrUsers()
                        }
                    }
                }
            }
        },
        methods: {
            async clear() {
                this.walletList = [];
                this.userList = [];
                this.saveButtonLoading = false;
                this.textLoading = false;
                this.walletOrUserList = [];
                this.searchText = "";
                this.selectedList = [];
                if (!this.userInfo.isSystemAdmin) {
                    this.user ? this.getWalletList() : this.getUserList();
                    await this.$refs.permissionForm.reset();
                } else {
                    this.getWalletsOrUsers()
                }
            },

            async close() {
                this.clear();
                this.$emit("close", false);
            },

            async savePermission() {
                if (await this.$refs.permissionForm.validate()) {
                    this.saveButtonLoading = true;
                    let body = this.user ? {
                        userPid: this.user.pid,
                        walletPidList: this.selectedList.map(item => item.pid)
                    } : {
                        userPidList: this.selectedList.map(item => item.pid),
                        walletPid: this.wallet.pid
                    };
                    try {
                        await this.$http
                            .post(`/permissions`, body);
                        this.$snotify.success(this.$t('ui.wallet.permissionSave'));
                        this.saveButtonLoading = false;
                        // this.$emit("refresh");
                        this.selectedList = [];
                        this.userList = [];
                        if (this.walletDetail) {
                            this.clear();
                            this.$emit("refresh", false);
                        } else {
                            this.userList = [];
                            this.walletList = [];
                            this.user ? await this.getWalletList() : await this.getUserList();
                        }
                    } catch (error) {
                        this.$error.notify(error)
                    } finally {
                        this.saveButtonLoading = false;
                    }
                } else {
                    this.user ? this.$snotify.warning(this.$t('ui.wallet.permissionWarningWallet')) : this.$snotify.warning(this.$t('ui.wallet.permissionWarningUser'))
                }
            },

            async deletePermission() {
                try {
                    this.user ? await this.$http.delete(`/users/${this.user.pid}/wallets/${this.selectedItem.pid}`)
                        : await this.$http.delete(`/wallets/${this.wallet.pid}/users/${this.selectedItem.pid}`);
                    this.$snotify.success(this.$t('ui.wallet.successDeletePermission'));
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                    await this.getWalletsOrUsers();
                    this.user ? await this.getWalletList() : await this.getUserList();
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            openDeleteItemDialog(item) {
                this.selectedItem = item;
                this.deleteItemDialogStatus = true
            },

            async getWalletsOrUsers() {
                try {
                    let response = this.user ? await this.$http.get(`${this.urlPrefix}/users/${this.user.pid}/wallets?permission=true`) :
                        await this.$http.get(`${this.urlPrefix}/wallets/${this.wallet.pid}/users`);
                    this.walletOrUserList = response.data.items;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async getWalletList(searchText) {
                await this.getWalletsOrUsers();
                try {
                    let response = await this.$http.get(`/users/${this.userInfo.pid}/wallets?only-subWallets=true&limit=100&q=${(searchText ? "name==" + encodeURIComponent(searchText) : "")}`);
                    response.data.items.forEach(item => {
                        if (!this.walletOrUserList.find(wallet => wallet.pid === item.pid)) {
                            this.walletList.push(item);
                        }
                    });
                    this.textLoading = false;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async getUserList(searchText) {
                await this.getWalletsOrUsers();
                try {
                    let response = await this.$http.get(`/users?limit=100&q=${(searchText ? "name==" + encodeURIComponent(searchText) : "")}`);
                    response.data.items.forEach(item => {
                        if (!this.walletOrUserList.find(user => user.pid === item.pid)) {
                            this.userList.push(item);
                        }
                    });
                    this.textLoading = false;
                } catch (error) {
                    this.$error.notify(error);
                }
            },

            async isMailAvailable(mail) {
                try {
                    let result = await this.$http
                        .get(`${this.urlPrefix}/users/emails/${mail}`);
                    this.loading = false;
                    return !result.data;
                } catch (error) {
                    this.$error.notify(error)
                }
            }
        }
    };
</script>
