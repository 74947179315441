<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-container fluid class="app-container">
        <app-title-bar
                :title="$t('ui.code.codes')">
        </app-title-bar>
        <v-card class="pt-4 mt-6">
            <v-tabs grow v-model="tabs">
                <v-tab>
                    <span class="font-weight-bold">{{$t('ui.sidebar.promotionCodes')}}</span>
                </v-tab>
                <v-tab>
                    <span class="font-weight-bold">{{$t('ui.sidebar.creditCodes')}}</span>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs" class="base">
                <v-tab-item>
                    <v-col>
                        <v-row no-gutters class="mt-6">
                            <v-col>
                                <v-btn large color="primary" class="white--text"
                                       @click="createCode()">
                                    {{$t('ui.code.create')}}
                                </v-btn>
                            </v-col>
                            <v-col class="mr-2">
                                <search-bar
                                        :searchText.sync="promotionCodeSearch"
                                        :searchLabel="searchLabel"
                                        :noSort="true"
                                        @cleanAllFilters="cleanAllFilters"
                                        @toggleSortOrder="toggleSortOrder"
                                        @refresh="getPromotionCodes()"
                                ></search-bar>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-row v-if="loading" justify="center" class="mt-6 mb-6">
                        <v-progress-linear indeterminate
                                           color="primary darken-2"
                        ></v-progress-linear>
                    </v-row>
                    <v-data-table v-if="!loading" :headers="headersPromotion"
                                  :items="promotionCodes"
                                  item-key="name"
                                  :footer-props="tableFooterProps"
                                  :page.sync="query.pagination.currentPage"
                                  :items-per-page.sync="query.pagination.limit"
                                  :server-items-length="query.pagination.totalCount"
                                  class="app-list" disable-sort>
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>

                        <template v-slot:item.quantity="{ item }">
                            <span v-if="item.quantity===null">{{$t('ui.code.limitless')}}</span>
                            <span v-else>{{ item.quantity }}</span>
                        </template>
                        <template v-slot:item.code="{ item }">
                            <v-row no-gutters class="show-on-hover-opacity" style="height:32px;min-width:250px">
                                <v-col class="mt-2">
                                    <simplebar class="simplebar-force-block" style="height:30px;overflow-y: hidden">
                                        <span style="white-space: nowrap" class="secret-text">{{item.code}}</span>
                                    </simplebar>
                                </v-col>
                                <div style="width: 40px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon class="show-on-hover-slot" v-on="on"
                                                   @click="onCopyCode(item.code)">
                                                <v-icon>
                                                    mdi-clipboard-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{$t('ui.code.copy')}}</span>
                                    </v-tooltip>
                                </div>
                            </v-row>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-row>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="ml-4" icon @click="openDeleteItemDialog(item)"
                                               v-bind="attrs"
                                               v-on="on">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('ui.code.delete')}}</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="editCode(item)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span> {{$t('ui.general.edit')}}</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="goDetail(item)">
                                            <v-icon>equalizer</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('ui.general.detail')}}</span>
                                </v-tooltip>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item>
                    <v-col>
                        <v-row no-gutters class="mt-6" align="center">
                            <v-col>
                                <v-btn large color="primary" class="white--text"
                                       @click="createOrEditCodeDialog=true">
                                    {{$t('ui.code.creditCreate')}}
                                </v-btn>
                            </v-col>
                            <v-col class="mr-2">
                                <search-bar
                                        :searchText.sync="creditCodeSearch"
                                        :searchLabel="searchLabel"
                                        :noSort="true"
                                        @cleanAllFilters="cleanAllFilters"
                                        @toggleSortOrder="toggleSortOrder"
                                        @refresh="getCreditCodes()"
                                ></search-bar>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-data-table :headers="headersCredit"
                                  :items="creditCodes"
                                  item-key="name"
                                  :footer-props="tableFooterProps"
                                  :page.sync="query.pagination.currentPage"
                                  :items-per-page.sync="query.pagination.limit"
                                  :server-items-length="query.pagination.totalCount"
                                  class="app-list" disable-sort>
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <v-row v-if="loading" justify="center" class="mt-6 mb-6">
                            <v-progress-linear indeterminate
                                               color="primary darken-2"
                            ></v-progress-linear>
                        </v-row>
                        <template v-slot:item.quantity="{ item }">
                            <span v-if="item.quantity===null">{{$t('ui.code.limitless')}}</span>
                            <span v-else>{{ item.quantity }}</span>
                        </template>
                        <template v-slot:item.code="{ item }">
                            <v-row no-gutters class="show-on-hover-opacity" style="height:32px;min-width:250px">
                                <v-col class="mt-2">
                                    <simplebar class="simplebar-force-block" style="height:30px;overflow-y: hidden">
                                        <span style="white-space: nowrap" class="secret-text">{{item.code}}</span>
                                    </simplebar>
                                </v-col>
                                <div style="width: 40px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon class="show-on-hover-slot" v-on="on"
                                                   @click="onCopyCode(item.code)">
                                                <v-icon>
                                                    mdi-clipboard-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{$t('ui.code.copy')}}</span>
                                    </v-tooltip>
                                </div>
                            </v-row>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-row>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="openDeleteItemDialog(item)"
                                               v-bind="attrs"
                                               v-on="on">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('ui.code.delete')}}</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="editCode(item)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span> {{$t('ui.general.edit')}}</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="goDetail(item)">
                                            <v-icon>equalizer</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('ui.general.detail')}}</span>
                                </v-tooltip>
                            </v-row>
                        </template>
                    </v-data-table>

                </v-tab-item>
            </v-tabs-items>
        </v-card>

        <create-or-edit-code-dialog @refresh="refresh()" @close="close()"
                                    v-model="createOrEditCodeDialog" :selectedCode="selectedCode"
                                    :isPromotion="isPromotion"></create-or-edit-code-dialog>

        <delete-item-dialog
                v-model="deleteItemDialogStatus"
                :loading="deleteItemDialogLoading"
                :title="$t('ui.code.delete')"
                :text="$t('ui.code.deleteText')"
                @delete="deleteCode">
        </delete-item-dialog>

    </v-container>
</template>

<script>

    import SearchBar from "@/modules/core/components/SearchBar";
    import CreateOrEditCodeDialog from "@/modules/code/components/dialogs/CreateOrEditCodeDialog";
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import Pagination from "@/modules/core/mixins/pagination.js";
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";
    import {store} from "ekx-vue-base";

    export default {
        components: {
            CreateOrEditCodeDialog,
            SearchBar,
            DeleteItemDialog
        },
        mixins: [
            new Pagination({
                methodName: "getCodes",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    code: "=lk="
                },
                searchMirror: {
                    code: {
                        path: "codeSearch"
                    }
                }
            })
        ],
        data() {
            return {
                userInfo: this.$store.getters['core/getUserInfo'],
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                promotionCodes: [],
                creditCodes: [],
                wallet: null,
                selectedCode: null,
                isPromotion: false,

                //dialogs
                createOrEditCodeDialog: false,
                assignCodeDialog: false,
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,

                loading: false,
                tabs: null,
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                //search
                promotionCodeSearch: "",
                creditCodeSearch: "",
                keyList: ["code"],
                searchLabel: this.$t('ui.searchBar.search'),
                headersPromotion: [
                    {
                        text: this.$t('ui.general.header.code'),
                        value: 'code',
                        align: 'start',
                        width: '250'
                    }, {
                        text: this.$t('ui.general.header.amount'),
                        value: 'amount',
                        width: '150'
                    }, {
                        text: this.$t('ui.general.header.amountType'),
                        value: 'amount_type',
                        width: '150'
                    }, {
                        text: this.$t('ui.general.header.quantity'),
                        value: 'quantity',
                        width: '150'
                    }, {
                        text: this.$t('ui.general.header.endDate'),
                        value: 'end_date',
                        width: '150'
                    },
                    {text: this.$t('ui.general.header.actionText'), value: 'actions', width: '150'}

                ],
                headersCredit: [
                    {
                        text: this.$t('ui.general.header.code'),
                        value: 'code',
                        align: 'start',
                        width: "250"
                    }, {
                        text: this.$t('ui.general.header.amount'),
                        value: 'amount',
                        width: '150'
                    }, {
                        text: this.$t('ui.general.header.quantity'),
                        value: 'quantity',
                        width: '150'
                    }, {
                        text: this.$t('ui.code.userQuantity'),
                        value: 'userQuantity',
                        width: '150'
                    }, {
                        text: this.$t('ui.general.header.endDate'),
                        value: 'expirationDate',
                        width: '150'
                    },
                    {text: this.$t('ui.general.header.actionText'), value: 'actions', width: '150'}

                ],
            };
        },

        created() {
            EkxDebounce.watch(this, "promotionCodeSearch", 500, () => {
                if (this.promotionCodeSearch !== "") {
                    this.query.search.code.value = this.promotionCodeSearch;
                } else {
                    this.query.search.code.value = "";
                }
            });
            EkxDebounce.watch(this, "creditCodeSearch", 500, () => {
                if (this.creditCodeSearch !== "") {
                    this.query.search.code.value = this.creditCodeSearch;
                } else {
                    this.query.search.code.value = "";
                }
            });
        },
        watch: {
            tabs: function (val) {
                this.promotionCodeSearch = "";
                this.creditCodeSearch = "";
                this.isPromotion = val !== 1;
                this.getCodes();
            }
        },
        methods: {
            async getPromotionCodes() {
                this.loading = true;
                try {
                    this.promotionCodes = [];
                    let query = this.createQuery();
                    let result = await this.$http.get(`/admin/promotionCodes?${query}`);
                    this.loading = false;
                    result.data.items.forEach(item => {
                        item.end_date = item.end_date ? new Date(item.end_date).toISOString().substr(0, 10) : this.$t('ui.code.limitless');
                        item.amount_type = item.type ? item.type : 'flat';
                    });
                    this.promotionCodes = result.data.items;
                    this.query.pagination = result.data.meta.pagination;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async getCreditCodes() {
                this.loading = true;
                try {
                    this.creditCodes = [];
                    let query = this.createQuery();
                    let result = await this.$http.get(`/admin/creditCodes?${query}`);
                    this.loading = false;
                    result.data.items.forEach(item => {
                        item.expirationDate = item.expirationDate === "" ? this.$t('ui.code.limitless') : new Date(item.expirationDate).toISOString().substring(0, 10);
                    });
                    this.creditCodes = result.data.items;
                    this.query.pagination = result.data.meta.pagination;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            createCode() {
                this.selectedCode = null;
                this.createOrEditCodeDialog = true
            },

            getCodes() {
                if (this.tabs === 1) {
                    this.getCreditCodes();
                } else {
                    this.getPromotionCodes();
                }
            },

            goDetail(item) {
                this.$router.push(`/admin/codes/${item.pid ? item.pid : item.id}?isPromotion=${this.isPromotion}`).catch(() => {
                });
            },

            editCode(item) {
                this.selectedCode = item;
                this.createOrEditCodeDialog = true;
            },

            openDeleteItemDialog(item) {
                this.selectedCode = item;
                this.deleteItemDialogStatus = true
            },

            async deleteCode() {
                let pid = this.selectedCode.pid ? this.selectedCode.pid : this.selectedCode.id;
                try {
                    this.deleteItemDialogLoading = true;
                    await this.$http.delete(`admin/codes/${pid}`);
                    this.$snotify.success(this.$t('ui.code.deleteSuccess'));
                    this.getCodes();
                    this.$emit('refresh');
                } catch (error) {
                    this.$error.notify(error);
                } finally {
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                }
            },

            onCopyCode: function (code) {
                const el = document.createElement('textarea');
                el.value = code;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.$snotify.success(this.$t('ui.code.copySuccess'));
            },

            cleanAllFilters() {
                this.promotionCodeSearch = "";
                this.creditCodeSearch = "";
                this.getCodes();
            },

            async refresh() {
                this.createOrEditCodeDialog = false;
                this.assignCodeDialog = false;
                this.selectedCode = null;
                this.getCodes();
            },
            close() {
                this.createOrEditCodeDialog = false;
                this.assignCodeDialog = false;
                this.selectedCode = null;
            },
        },

        mounted() {
            this.getCreditCodes();
            this.getPromotionCodes();
        }
    };
</script>

<style scoped>
</style>