var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px","persistent":true},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-1 base primary",attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('ui.user.dialog.changePassword')))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('ui.general.close'))+" ")])],1),(_vm.user)?_c('v-container',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-6"},[_c('ValidationObserver',{ref:"changePasswordForm"},[_c('ValidationProvider',{attrs:{"rules":_vm.currentUser.parent || _vm.user.pid === _vm.currentUser.pid ? 'required|min:5|max:12' : false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.currentUser.parent || _vm.user.pid === _vm.currentUser.pid)?_c('v-text-field',{attrs:{"error-messages":errors,"maxLength":12,"type":_vm.showCurrent ? 'text' : 'password',"label":_vm.$t('ui.user.dialog.currentPassword')},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){_vm.showCurrent = !_vm.showCurrent}}},on),[_vm._v(" "+_vm._s(_vm.showCurrent ? 'mdi-eye' : 'mdi-eye-off')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.showCurrent ? _vm.$t('ui.general.show') : _vm.$t('ui.general.dontShow')))])])],1)],2):_vm._e()]}}],null,false,1334460478)}),_c('ValidationProvider',{attrs:{"rules":"required|min:4|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"maxLength":12,"type":_vm.showNew ? 'text' : 'password',"rules":_vm.newPassword ? _vm.passwordRules : false,"label":_vm.$t('ui.user.dialog.newPassword')},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){_vm.showNew = !_vm.showNew}}},on),[_vm._v(" "+_vm._s(_vm.showNew ? 'mdi-eye' : 'mdi-eye-off')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.showNew ? _vm.$t('ui.general.show') : _vm.$t('ui.general.dontShow')))])])],1)],2)]}}],null,false,2767309144)}),_c('ValidationProvider',{attrs:{"rules":"required|min:4|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxLength":12,"rules":_vm.confirmPassword ? _vm.passwordRules : false,"error-messages":_vm.errorMessage ? _vm.errorMessage : errors,"type":_vm.showConfirm ? 'text' : 'password',"label":_vm.$t('ui.user.dialog.confirmPassword')},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){_vm.showConfirm = !_vm.showConfirm}}},on),[_vm._v(" "+_vm._s(_vm.showConfirm ? 'mdi-eye' : 'mdi-eye-off')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.showConfirm ? _vm.$t('ui.general.show') : _vm.$t('ui.general.dontShow')))])])],1)],2)]}}],null,false,1803626493)})],1)],1)],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('ui.general.cancel')))]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v(" "+_vm._s(_vm.$t('ui.general.change'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }