<template>
    <div>
        <rapi-doc
                :spec-url="baseURL + '/docs/swagger.json'"
                allow-spec-file-load= "false"
                allow-spec-url-load="false"
                render-style="read"
                style="height:100vh; width:100%"
        ></rapi-doc>
    </div>

</template>

<script>

import "rapidoc"

export default {
    data() {
        return {
            baseURL: process.env.VUE_APP_SERVER_URL
        }
    },
}
</script>

<style scoped>

</style>