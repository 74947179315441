<template>
    <v-row justify="center">
        <v-dialog v-model="value" persistent max-width="600px">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title>{{ $t('ui.wallet.resetBalance') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-container class="pa-0">
                    <v-col class="pa-6">
                        <v-row no-gutters>
                            <v-col>
                                {{$t('ui.wallet.resetBalanceDialog')}}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-divider></v-divider>
                    <v-col>
                        <v-row justify="end" no-gutters>
                            <v-btn class="mx-1" color="warning" @click="close">
                                {{$t('ui.general.cancel')}}
                            </v-btn>
                            <v-btn class="mx-1"
                                   color="danger"
                                   @click="resetBalance()"
                                   style="color: #FFFFFF;">
                                <span>{{$t('ui.general.reset')}}</span>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        props: ['value', 'wallet'],
        data() {
            return {}
        },
        methods: {
            close() {
                this.$emit("close", true)
            },

            async resetBalance() {
                try {
                    await this.$http.patch(`/wallets/${this.wallet.pid}`, {reset: true});
                    this.$snotify.success(this.$t('ui.wallet.loadSuccess'));
                    this.$emit("refresh", true)
                } catch (error) {
                    this.$error.notify(error)
                }
            },

        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>