<template>
    <v-card class="pa-6 app-title-bar">
        <v-row no-gutters align="center">
            <h2 class="mt-2" v-text="$t(title)"></h2>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items" class="py-0"></v-breadcrumbs>
        </v-row>
    </v-card>
</template>

<script>
    export default {
        props: ["title", "items"]
    }
</script>

<style scoped>

</style>