<template>
    <v-dialog v-model="value" max-width="600px" :persistent="true">
        <v-card>
            <v-toolbar color="primary" dark class="elevation-1">
                <v-toolbar-title>{{$t('ui.card.table.detail')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon @click="close()" v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    {{$t('ui.general.close')}}
                </v-tooltip>
            </v-toolbar>
            <v-container class="pa-0">
                <v-col class="pa-6">
                    <v-row dense>
                        <v-col align="right">
                            <h5 class="font-weight-bold mt-2" style="opacity: 0.5">
                                {{$t('ui.card.dialog.name')}} :</h5>
                        </v-col>
                        <v-col align="left">
                            <h5 class="font-weight-bold mt-2">{{ card.alias }}</h5>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col align="right">
                            <h5 class="font-weight-bold mt-2" style="opacity: 0.5">
                                {{$t('ui.card.dialog.bankName')}} :</h5>
                        </v-col>
                        <v-col align="left">
                            <h5 class="font-weight-bold mt-2">{{ card.bank_name }}</h5>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col align="right">
                            <h5 class="font-weight-bold mt-2" style="opacity: 0.5">
                                {{$t('ui.card.dialog.number')}} :</h5>
                        </v-col>
                        <v-col align="left">
                            <h5 class="font-weight-bold mt-2">**** **** **** {{ card.last_four }}</h5>
                        </v-col>
                    </v-row>  <v-row dense>
                    <v-col align="right">
                        <h5 class="font-weight-bold mt-2" style="opacity: 0.5">
                            {{$t('ui.card.dialog.expirationDate')}} :</h5>
                    </v-col>
                    <v-col align="left">
                        <h5 class="font-weight-bold mt-2">{{ card.card.expiry_month}}/{{card.card.expiry_year}}</h5>
                    </v-col>
                </v-row>
                </v-col>
                <v-divider></v-divider>
                <v-col>
                    <v-row justify="end" no-gutters>
                        <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.close')}}</v-btn>
                    </v-row>
                </v-col>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: ["value", "card"],
        data() {},
        methods: {

            close() {
                this.$emit("close", true);
            }
        },
    };
</script>