<template>
    <div>
        <v-col align="center" class="mt-2">
            <span style="font-size: xx-large; font-weight: bold;">{{$t('ui.card.toBePaid')}} {{ amount }} TRY</span>
        </v-col>
        <v-col align="center">
            <v-expansion-panels focusable v-model="cardPanel">
                <v-expansion-panel v-if="cards.length!==0" v-model="cardPanel">
                    <v-expansion-panel-header style="min-height: 80px;background-color: #FFFFFF;color: #000000;">
                        <template v-slot:actions>
                            <v-icon color="primary">
                            $expand
                            </v-icon>
                        </template>
                        <span style="font-size: large">{{$t('ui.card.registeredCards')}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="background-color: #FFFFFF;color: #000000;">
                        <v-row class="mt-4 pb-2">
                            <v-col align="center" v-for="(card, i) in cards" :key="i" cols="3">
                                <v-card style="border:1px solid black; " @click="selectCard(card)" :class="(selectedCard && selectedCard.id===card.id) ? `elevation-2 primary pa-4` : `elevation-2 pa-4`">
                                    <div>
                                        <div style="text-align: center">
                                            <span :class="(selectedCard && selectedCard.id===card.id) ? `white--text`: ``"
                                            >{{card.bank_name}}</span>
                                            <br>
                                            <span :class="(selectedCard && selectedCard.id===card.id) ? `white--text`: ``" >**** **** **** {{ card.last_four}}</span>
                                            <br>
                                            <span :class="(selectedCard && selectedCard.id===card.id) ? `white--text`: ``" > {{ card.card.expiry_month}}/{{ card.card.expiry_year}}</span>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-if="(cards.length>0) && selectedCard" justify="end" no-gutters>
                            <v-col align="right">
                                <v-btn :loading="payLoading" :disabled="!selectedCard" color="primary"
                                       @click="payWithSelectedCard()">{{$t('ui.card.pay')}} {{amount }} TRY
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="mt-4" v-model="cardPanel"  style="background-color: #FFFFFF;color: #000000;">
                    <v-expansion-panel-header style="min-height: 80px;background-color: #FFFFFF;color: #000000;">
                        <template v-slot:actions>
                            <v-icon color="primary">
                            $expand
                            </v-icon>
                        </template>
                        <span style="font-size: large">{{$t('ui.card.anotherCard')}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card width="800px" class="mt-5">
                            <v-toolbar color="primary" dark class="elevation-1">
                                <v-toolbar-title>{{$t('ui.card.table.detail')}}</v-toolbar-title>
                            </v-toolbar>
                                <v-col class="pl-6 pr-6">
                                    <ValidationObserver ref="createNuksPaymentForm">
                                        <ValidationProvider rules="required|min:4|whiteSpace|max:50"
                                                            v-slot="{ errors }">
                                            <v-text-field v-model="cardOwnerName"
                                                        :error-messages="errors"
                                                        :label="$t('ui.card.dialog.ownerName')"
                                                        :loading="textLoading"></v-text-field>
                                        </ValidationProvider>
                                        <ValidationProvider rules="required|min:4|whiteSpace|max:50"
                                                            v-slot="{ errors }">
                                            <v-text-field v-model="cardName"
                                                          :maxLength="50"
                                                          :error-messages="errors"
                                                          :label="$t('ui.card.dialog.name')"
                                                          :loading="textLoading"></v-text-field>
                                        </ValidationProvider>
                                        <ValidationProvider rules="required|cardNumber" v-slot="{ errors }">
                                            <v-text-field v-model="cardNumber"
                                                          :maxLength="50"
                                                          :error-messages="errors"
                                                          @keypress="cardNumberControl($event)"
                                                          max="14"
                                                          :label="$t('ui.card.dialog.number')"></v-text-field>
                                        </ValidationProvider>
                                        <v-row>
                                            <v-col>
                                                <ValidationProvider rules="required|"
                                                                    v-slot="{ errors }">
                                                    <v-text-field v-model="cvv"
                                                                  :maxLength="3"
                                                                  :error-messages="errors"
                                                                  @keypress="isGreaterThanThree($event)"
                                                                  :label="$t('ui.card.dialog.cvv')"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col>
                                                <ValidationProvider rules="required"
                                                                    v-slot="{ errors }">
                                                    <v-text-field v-model="expirationDate"
                                                                  :error-messages="errors"
                                                                  :error="!!dateError"
                                                                  @keypress="cardDateControl($event)"
                                                                   max="7"
                                                                  :label="$t('ui.card.dialog.expirationDate')"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!userInfo.isSystemAdmin">
                                            <v-col>
                                                <ValidationProvider rules="required:true" v-slot="{ errors }">
                                                    <v-checkbox
                                                            v-model="saveCardInfo"
                                                            :error-messages="errors"
                                                            :label="$t('ui.card.saveCardInfo')"
                                                    ></v-checkbox>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </ValidationObserver>
                                </v-col>
                                <v-col>
                                    <v-row justify="end" no-gutters>
                                        <v-btn class="mx-1" color="primary" :loading="payNuksLoading"
                                            @click="withNuksCard=true">
                                            {{$t('ui.card.pay')}} {{amount }} TRY
                                        </v-btn>
                                    </v-row>
                                </v-col>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
        <payment-approval-dialog v-model="approvePaymentDialog" :amount="amount"
                                 @approvePayment="approvePayment()" @close="clearForm"></payment-approval-dialog>
    </div>
</template>
<script>
    import PaymentApprovalDialog
        from "@/modules/wallet/components/dialogs/PaymentApprovalDialog";

    export default {
        props: ['value', 'paymentId', 'planId', 'amount', 'cards', 'customerId', 'balance', "wallet"],
        components: {
            PaymentApprovalDialog
        },
        data() {
            return {
                userInfo: this.$store.getters['core/getUserInfo'],
                paymentSourceForm: false,
                awaitNuks: true,
                paymentSourceId: null,
                selectedCard: null,
                payLoading: false,
                approvePaymentDialog: false,
                cardPanel: this.cards.length > 0 ? [0, 1] : 0,
                dialog: false,
                loading: true,
                 urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                cardOwnerName: null,
                cardName: null,
                cardNumber: "",
                cvv: "",
                expirationDate: "",
                errorMessage: null,
                date: null,
                textLoading: false,
                dateError: null,
                payNuksLoading: false,
                withNuksCard: false,
                saveCardInfo: false
            }
        },

        watch: {
            withNuksCard: function (value) {
                if (value) {
                    this.payWithCard();
                }
            },
            'value': {
                handler() {
                    this.clearForm();
                }
            },
        },
        methods: {

            async payWithSelectedCard() {
                this.payLoading = true;
                this.approvePaymentDialog = true;
            },

            async payWithCard() {

                if (this.withNuksCard) {
                    if (await this.$refs.createNuksPaymentForm.validate() && this.expirationDateControl(this.expirationDate)) {
                        this.payLoading = true;
                        this.approvePaymentDialog = true;
                    } else {
                        if (await this.$refs.createNuksPaymentForm.validate() && !this.expirationDateControl(this.expirationDate)) {
                            this.$snotify.warning(this.$t('ui.card.validDateWarning'));

                        } else {
                            this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                        }
                        this.withNuksCard = false;
                    }
                } else {
                    this.payLoading = true;
                    this.approvePaymentDialog = true;
                }

            },

            async approvePayment() {
                try {
                    if (this.withNuksCard) {
                        if (await this.$refs.createNuksPaymentForm.validate() && this.expirationDateControl(this.expirationDate)) {
                            this.payNuksLoading = true;

                            await this.$http.patch(`/payments/${this.paymentId}`, {
                                paymentSourceInfo: {
                                    cvv: this.cvv,
                                    expiry_month: this.expirationDate.substring(0, 2),
                                    expiry_year: this.expirationDate.substring(3, 7),
                                    fullname: this.cardOwnerName,
                                    number: this.cardNumber,
                                    alias: this.cardName
                                },
                                customerId: this.customerId,
                                userPid: this.userInfo.pid,
                                walletPid: this.wallet.pid,
                                promotionCode: this.promotionCode,
                                saveCardInfo: this.saveCardInfo,
                                balance: this.balance
                            });

                            this.$emit("refresh");
                        } else {
                            this.withNuksCard = false;
                            if (await this.$refs.createNuksPaymentForm.validate() && !this.expirationDateControl(this.expirationDate)) {
                                this.$snotify.warning(this.$t('ui.card.validDateWarning'));

                            } else {
                                this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                            }
                        }
                    } else {

                        await this.$http.patch(`/payments/${this.paymentId}`, {
                            paymentSourceId: this.selectedCard.id,
                            customerId: this.customerId,
                            userPid: this.userInfo.pid,
                            walletPid: this.wallet.pid,
                            promotionCode: this.promotionCode,
                            balance: this.balance
                        });

                        this.$emit("refresh");
                    }
                } catch (error) {
                    this.$error.notify(error);
                    this.$bus.$emit('removeLoading');
                    this.payLoading = false;
                    this.approvePaymentDialog = false;
                    this.payNuksLoading = false;
                    this.withNuksCard = false;
                }
            },

            expirationDateControl(date) {
                let month = parseInt(date.substring(0, 2));
                let year = parseInt(date.substring(3, 7));
                if ((year === (new Date()).getFullYear() && month < 13 && month > (new Date()).getMonth()) || (month > 0 && month < 13 && year < 2099 && year > (new Date()).getFullYear())) {
                    this.dateError = null;
                    return true;
                } else {
                    this.dateError = true;
                    return false
                }
            },

            isGreaterThanThree(event) {

                let charCode = (event.which) ? event.which : event.keyCode;

                if (this.cvv.length === 3) {
                    event.preventDefault();
                } else {
                    if ((charCode < 48 || charCode > 57)) {
                        event.preventDefault();
                    } else {
                        return true
                    }
                }
            },

            cardNumberControl(event) {
                let charCode = (event.which) ? event.which : event.keyCode;

                if (this.cardNumber.length === 19) {
                    event.preventDefault();
                } else {
                    if (this.cardNumber.length === 4) {
                        this.cardNumber += String.fromCharCode(32);
                    }
                    if (this.cardNumber.length === 9) {
                        this.cardNumber += String.fromCharCode(32);
                    }
                    if (this.cardNumber.length === 14) {
                        this.cardNumber += String.fromCharCode(32);
                    }
                    if ((charCode < 48 || charCode > 57) && charCode !== 32) {
                        event.preventDefault();
                    } else {
                        return true
                    }
                }
            },

            cardDateControl(event) {
                let charCode = (event.which) ? event.which : event.keyCode;

                if (this.expirationDate.length === 7) {
                    event.preventDefault();
                } else {
                    if (this.expirationDate.length === 2) {
                        this.expirationDate += String.fromCharCode(47);
                    }
                    if ((charCode < 48 || charCode > 57) && charCode !== 47) {
                        event.preventDefault();
                    } else {
                        return true
                    }
                }
            },

            formatDate(date) {
                if (!date) return null;
                const [year, month] = date.split('-');
                return `${month}/${year}`
            },


            selectCard(card) {
                if (this.selectedCard) {
                    this.selectedCard = null;
                } else {
                    this.selectedCard = card;
                }
            },

            closePayment() {
                this.payLoading = false;
                this.approvePaymentDialog = false;
                this.$bus.$emit('removeLoading');
            },

            async clearForm(val) {
                this.payLoading = false;
                this.approvePaymentDialog = false;
                this.withNuksCard = false;
                this.$bus.$emit('removeLoading');
                this.paymentSourceId = null;
                this.selectedCard = null;
                this.cardPanel = this.cards.length > 0 ? [0, 1] : 0;
                if (!val) {
                    this.cvv = "";
                    this.expirationDate = "";
                    this.cardNumber = "";
                    this.cardOwnerName = null;
                    this.cardName = null;
                    this.dateError = null;
                    if (this.$refs.createNuksPaymentForm) {
                        await this.$refs.createNuksPaymentForm.reset();
                    }
                }
            }
        },
        mounted() {
            let _this = this;

            this.$bus.$on('closePayment', function (cards) {
                _this.cards = cards;
                _this.clearForm();
            });
        }
    };
</script>
