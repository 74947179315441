var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"app-container",attrs:{"fluid":""}},[(!_vm.adminFlag)?_c('app-title-bar',{attrs:{"title":_vm.$t('ui.sidebar.apiKeys')}}):_vm._e(),_c('v-card',{class:this.$route.meta && this.$route.meta.permission === 'admin' ? 'elevation-0' : 'mt-6'},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[(_vm.urlPrefix!== '/admin')?_c('v-col',[_c('v-btn',{staticClass:"white--text",attrs:{"large":"","color":"primary"},on:{"click":function($event){_vm.apiKeyOperationDialog=true}}},[_vm._v(" "+_vm._s(_vm.$t('ui.apiKey.create'))+" ")])],1):_vm._e(),_c('v-col',{staticClass:"mr-2"},[_c('search-bar',{attrs:{"searchText":_vm.nameSearch,"searchLabel":_vm.searchLabel,"noSort":true},on:{"update:searchText":function($event){_vm.nameSearch=$event},"update:search-text":function($event){_vm.nameSearch=$event},"cleanAllFilters":_vm.cleanAllFilters,"refresh":function($event){return _vm.getApiKeyList()}}})],1)],1)],1),_c('v-row',{staticClass:"pa-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"app-list b-r-3",attrs:{"headers":_vm.headers,"items":_vm.apiKeyList,"item-key":"name","footer-props":_vm.tableFooterProps,"page":_vm.query.pagination.currentPage,"items-per-page":_vm.query.pagination.limit,"server-items-length":_vm.query.pagination.totalCount,"options":_vm.tableOptions},on:{"update:page":function($event){return _vm.$set(_vm.query.pagination, "currentPage", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.query.pagination, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.query.pagination, "limit", $event)},"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.wallet.name",fn:function(ref){
var item = ref.item;
return [(item.wallet)?_c('v-span',[_vm._v(" "+_vm._s(item.wallet.name))]):_c('v-span',[_vm._v("-")])]}},{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"show-on-hover-opacity",staticStyle:{"height":"32px","min-width":"250px"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-2"},[_c('simplebar',{staticClass:"simplebar-force-block",staticStyle:{"height":"30px","overflow-y":"hidden"}},[_c('span',{staticClass:"secret-text",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.key))])])],1),_c('div',{staticStyle:{"width":"40px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"show-on-hover-slot",attrs:{"icon":""},on:{"click":function($event){return _vm.onCopyKey(item.key)}}},on),[_c('v-icon',[_vm._v(" mdi-clipboard-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.apiKey.copyKey')))])])],1)],1)]}},{key:"item.secret",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"9"}},[_c('span',{staticClass:"text-font"},[_vm._v(_vm._s(item.showSecret ? item.secret : '∗'.repeat(item.secret.length)))])]),_c('v-col',{staticStyle:{"width":"80px"},attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"show-on-hover-slot",attrs:{"icon":""},on:{"click":function($event){item.showSecret = !item.showSecret}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(item.showSecret ? 'mdi-eye-off' : 'mdi-eye')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.showSecret ? _vm.$t('ui.general.dontShow') : _vm.$t('ui.general.show')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.onCopySecret(item.secret)}}},on),[_c('v-icon',[_vm._v("mdi-clipboard-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.apiKey.copySecret')))])])],1)],1)]}},{key:"item.canSeeOld",fn:function(ref){
var item = ref.item;
return [(item.canSeeOld===true)?_c('span',[_c('v-icon',_vm._g({attrs:{"color":"green"}},_vm.on),[_vm._v("mdi-check")])],1):(item.canSeeOld===false)?_c('span',[_c('v-icon',_vm._g({attrs:{"color":"red"}},_vm.on),[_vm._v("mdi-close")])],1):_vm._e()]}},{key:"item.allowedIpList",fn:function(ref){
var item = ref.item;
return [(item.allowedIpList.length===0)?_c('span',[_vm._v("-")]):_c('span',[_c('v-btn',{attrs:{"width":"105px","outlined":"","color":"primary"},on:{"click":function($event){return _vm.viewIPList(item)}}},[_vm._v(_vm._s(_vm.$t('ui.apiKey.viewIPList')))])],1)]}},{key:"item.canQuery",fn:function(ref){
var item = ref.item;
return [(item.canQuery===true)?_c('span',[_c('v-icon',_vm._g({attrs:{"color":"green"}},_vm.on),[_vm._v("mdi-check")])],1):(item.canQuery===false)?_c('span',[_c('v-icon',_vm._g({attrs:{"color":"red"}},_vm.on),[_vm._v("mdi-close")])],1):_vm._e()]}},{key:"item.monteCarlo",fn:function(ref){
var item = ref.item;
return [(item.monteCarlo===true)?_c('span',[_c('v-icon',_vm._g({attrs:{"color":"green"}},_vm.on),[_vm._v("mdi-check")])],1):(item.monteCarlo===false)?_c('span',[_c('v-icon',_vm._g({attrs:{"color":"red"}},_vm.on),[_vm._v("mdi-close")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editApiKey(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.general.edit')))])]),(_vm.urlPrefix!== '/admin')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openDeleteItemDialog(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.general.delete')))])]):_vm._e()]}}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('ui.dataTable.noData'))+" ")])],2)],1)],1)],1),(_vm.apiKeyList && _vm.userInfo)?_c('v-row',[_c('api-key-operations-dialog',{attrs:{"userInfo":_vm.userInfo,"selectedApiKey":_vm.apiKey},on:{"close":function($event){return _vm.close()},"refresh":function($event){return _vm.refresh()}},model:{value:(_vm.apiKeyOperationDialog),callback:function ($$v) {_vm.apiKeyOperationDialog=$$v},expression:"apiKeyOperationDialog"}}),_c('delete-item-dialog',{attrs:{"loading":_vm.deleteItemDialogLoading,"title":_vm.$t('ui.apiKey.delete'),"text":_vm.$t('ui.apiKey.deleteText')},on:{"delete":_vm.deleteApiKey},model:{value:(_vm.deleteItemDialogStatus),callback:function ($$v) {_vm.deleteItemDialogStatus=$$v},expression:"deleteItemDialogStatus"}}),(_vm.selectedApiKey)?_c('v-row',[_c('allowed-ip-list-view-dialog',{attrs:{"apiKey":_vm.selectedApiKey},on:{"close":function($event){return _vm.close()}},model:{value:(_vm.viewIPListDialog),callback:function ($$v) {_vm.viewIPListDialog=$$v},expression:"viewIPListDialog"}})],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }