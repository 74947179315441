var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pt-4 mt-4"},[_c('v-stepper',{model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 1,"step":"1"}},[_vm._v(" "+_vm._s(_vm.$t('ui.request.requests'))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v(" "+_vm._s(_vm.$t('ui.request.validation'))+" ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('ValidationObserver',{ref:"createOrEditRequestForm"},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"lg":"9","md":"9"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-8",attrs:{"items":_vm.typeList,"item-text":"text","item-value":"value","error-messages":errors,"label":_vm.$t('ui.request.pleaseSelect'),"dense":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})]}}])})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"lg":"9","md":"9"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-8",attrs:{"items":_vm.statusList,"item-text":"text","item-value":"value","error-messages":errors,"label":_vm.$t('ui.request.pleaseStatus'),"dense":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}}])})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"lg":"9","md":"9"}},[_c('ValidationProvider',{attrs:{"rules":"required|whiteSpace|min:3|max:50|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxLength":50,"error-messages":_vm.errorMessage ? _vm.errorMessage : errors,"label":_vm.$t('ui.request.name'),"type":"text","loading":_vm.textLoading},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"lg":"9","md":"9"}},[_c('ValidationProvider',{attrs:{"rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxLength":100,"error-messages":_vm.calTypeErrorMessage ? _vm.calTypeErrorMessage : errors,"loading":_vm.calculationTypeLoading,"label":_vm.$t('ui.request.calculationType'),"type":"text"},model:{value:(_vm.calculationType),callback:function ($$v) {_vm.calculationType=$$v},expression:"calculationType"}})]}}])})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"lg":"9","md":"9"}},[_c('ValidationProvider',{attrs:{"rules":"required|whiteSpace|min:0|max:50|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxLength":50,"error-messages":errors,"min":"0","label":_vm.$t('ui.request.price'),"type":"number"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})]}}])})],1)],1)],1)],1),_c('v-divider'),(_vm.e1===1)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('ui.general.cancel'))+" ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.next()}}},[_vm._v(" "+_vm._s(_vm.$t('ui.general.next'))+" ")])],1):_vm._e(),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationObserver',{ref:"createOrEditRequestSecondForm"},[_c('ace-editor',{staticClass:"jsonEditor",attrs:{"height":"500px","lang":"json","theme":"chrome"},on:{"init":_vm.editorInit},model:{value:(_vm.validation),callback:function ($$v) {_vm.validation=$$v},expression:"validation"}})],1)],1)],1)],1)],1)],1),_c('v-divider'),(_vm.e1===2)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"warning"},on:{"click":function($event){_vm.e1=1}}},[_vm._v(_vm._s(_vm.$t('ui.general.back')))]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.createOrEditRequest()}}},[_vm._v(" "+_vm._s(_vm.$t('ui.general.save'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }