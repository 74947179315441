<template>
    <v-row justify="center">
        <v-dialog v-model="value" max-width="800px" max-height="500px" persistent>
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title>{{$t('ui.feedback.header')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-container class="pic-container pa-0">
                    <v-col class="pa-6">
                        <ValidationObserver ref="feedbackForm">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <v-autocomplete
                                        v-model="subject"
                                        :items="subjectList"
                                        item-text="text"
                                        item-value="value"
                                        :error-messages="errors"
                                        :label="$t('ui.feedback.pleaseSelect')"
                                        dense
                                ></v-autocomplete>
                            </ValidationProvider>
                            <ValidationProvider rules="required|min:3|max:1000" v-slot="{ errors }">
                                <v-textarea
                                        class="mt-4"
                                        outlined
                                        v-model="message"
                                        :error-messages="errors"
                                        :label="$t('ui.feedback.messageText')"
                                        max-height="250px"
                                ></v-textarea>
                            </ValidationProvider>
                        </ValidationObserver>
                    </v-col>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
                    <v-btn class="mx-1" color="primary" :loading="loading" @click="createFeedback()">
                        {{$t('ui.general.send')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    export default {
        props: ['value'],
        data() {
            return {
                subject: null,
                message: null,
                loading: false,
                subjectList: [{
                    text: this.$t('ui.feedback.bugFix'),
                    value: "BUG_FIX"
                }, {text: this.$t('ui.feedback.feature'), value: "FEATURE"}, {
                    text: this.$t('ui.feedback.improvement'),
                    value: "IMPROVEMENT"
                }],
            }
        },
        methods: {
            async createFeedback() {
                if (await this.$refs.feedbackForm.validate()) {
                    let body = {
                        key: this.subject,
                        message: this.message,
                    };
                    try {
                        this.loading = true;
                        await this.$http.post('/feedbacks', body);
                        this.$emit("close");
                        this.close();
                        this.$snotify.success(this.$t('ui.feedback.successFeedback'));
                        this.loading = false;
                    } catch (error) {
                        this.$error.notify(error);
                        this.loading = false;
                    }
                } else {
                    this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                }

            },
            close() {
                this.$refs.feedbackForm.reset();
                this.subject = null;
                this.message = null;
                this.$emit("close", true);
            },

        },
    };
</script>
<style>
    .required h4::after {
        content: "*";
        color: red;
    }
     .pic-container {
         max-height: 500px;
         overflow: auto;
     }
</style>