<template>
    <v-row justify="center">
        <v-dialog v-model="value" max-width="600px">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title> {{$t('ui.user.dialog.editUserInfo')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
            <v-container class="pa-0">
                <v-col class="pa-6">
                    <ValidationObserver ref="editUserForm">
                        <ValidationProvider rules="required|whiteSpace|min:3|max:50|" v-slot="{ errors }">
                            <v-text-field v-model="username"
                                          :maxLength="50"
                                          :error-messages="errors"
                                          :label="$t('ui.user.username')" type="text"></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider rules="required|whiteSpace|min:3|max:50|" v-slot="{ errors }">
                            <v-text-field v-model="name"
                                          :maxLength="50"
                                          :error-messages="errors"
                                          :label="$t('ui.user.name')" type="text"></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider rules="required|whiteSpace|min:3|max:50|" v-slot="{ errors }">
                            <v-text-field v-model="surname" :label="$t('ui.user.surname')"
                                          :maxLength="50"
                                          :error-messages="errors"
                                          type="text"></v-text-field>
                        </ValidationProvider>
                    </ValidationObserver>
                </v-col>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
                <v-btn class="mx-1" color="primary" :loading="loading" @click="updateUser()">{{$t('ui.general.save')}}
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>

    export default {
        props: ["value", "user"],
        data() {
            return {
                errorMessage: null,
                errors: null,
                loading: false,
                name: null,
                surname: null,
                username: null,
                userInfo: this.$store.getters['core/getUserInfo'],
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
            }
        },
        watch: {
            value: function (val) {
                if (val === true) {
                    this.name = this.user.name;
                    this.surname = this.user.surname;
                    this.username = this.user.username;
                }
            },
        },
        methods: {
            async updateUser() {
                this.loading = true;
                if (await this.$refs.editUserForm.validate() && this.errorMessage === null) {
                    let user = {
                        username: this.username,
                        surname: this.surname,
                        name: this.name,
                    };
                    try {
                        await this.$http
                            .patch(
                                `${this.urlPrefix}/users/${this.user.pid}`, {userInfo:user});
                        this.loading = false;
                        this.$snotify.success(this.$t('ui.general.snotify.editUser'));
                        this.$refs.editUserForm.reset();
                        this.$emit('refresh');
                    } catch (error) {
                        this.$error.notify(error)
                    }
                } else {
                    this.loading = false;
                    this.$snotify.warning("Please fill in the fields correctly");
                }
            },

            close() {
                this.loading = false;
                this.$refs.editUserForm.reset();
                this.$emit("close", true);
            },

            clear() {
                this.loading = false;
            },

        },
    };
</script>