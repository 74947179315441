<template>
    <v-container fluid class="app-container">
        <app-title-bar v-if="!isAdmin"
                       :title="$t('ui.calculation.header')" :items="navBarItems">
        </app-title-bar>
        <v-card v-if="!createCalculationDialog" :class=" isAdmin ? 'elevation-0': ' pt-4 mt-6'">
            <v-col>
                <v-row no-gutters>
                    <v-col v-if="!isAdmin">
                        <v-btn large color="primary" class="white--text" @click="calculationCreate()">
                            {{$t('ui.calculation.create')}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row no-gutters>
                    <v-col class="mr-4">
                        <v-select
                                v-model="selectedType"
                                :items="typeList"
                                :label="$t('ui.calculation.type')"
                                :search-input.sync="search"
                                return-object
                                persistent-hint
                        ></v-select>
                    </v-col>
                    <v-col class="mr-4">
                        <v-select v-model="selectedStatus" :items="statusList"
                                  :label="$t('ui.calculation.status')"
                                  return-object>
                        </v-select>
                    </v-col>
                    <v-col class="mr-4">
                        <v-select v-model="userOption" :items="userOptionList"
                                  :label="$t('ui.user.select')"
                                  return-object>
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="timeInterval"
                                :items="timeIntervals"
                                :label="$t('ui.general.timeInterval')"
                                return-object
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-row class="pa-0">
                <v-container fluid>
                    <v-data-table :headers="headers"
                                  :items="calculations"
                                  item-key="name"
                                  :footer-props="tableFooterProps"
                                  :page.sync="query.pagination.currentPage"
                                  :items-per-page.sync="query.pagination.limit"
                                  :server-items-length="query.pagination.totalCount"
                                  :options.sync="tableOptions"
                                  :loading="listLoading"
                                  class="app-list" @click:row="calculationDetail">
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <template v-slot:item.pid="{ item }">
                            <v-row no-gutters class="show-on-hover-opacity" style="height:32px;width:350px">
                                <v-col class="mt-2">
                                    <simplebar class="simplebar-force-block" style="height:30px;overflow-y: hidden">
                                        <span style="white-space: nowrap" class="secret-text">{{item.pid}}</span>
                                    </simplebar>
                                </v-col>
                                <div style="width: 40px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon class="show-on-hover-slot" v-on="on"
                                                   @click="onCopyPid(item.pid)">
                                                <v-icon>
                                                    mdi-clipboard-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{$t('ui.dataSource.copyPid')}}</span>
                                    </v-tooltip>
                                </div>
                            </v-row>
                        </template>
                        <template v-slot:item.status="{ item }">
                            <span v-if="item.status===0">{{$t('ui.general.success')}}</span>
                            <span v-else-if="item.status===1">{{$t('ui.general.error')}}</span>
                            <span v-else-if="item.status===3">{{$t('ui.general.processing')}}</span>
                            <span v-else>{{$t('ui.general.refund')}}</span>
                        </template>
                        <template v-slot:item.api="{ item }">
                            <span v-if="item.api">API Client</span>
                            <span v-else>Web</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="calculationDetail(item)">
                                        <v-icon>equalizer</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.detail')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="isAdmin && item.status=== 0 " icon v-on="on"
                                           @click="refundBalance(item)">
                                        <v-icon> sync</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.refund')}}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-container>
            </v-row>

        </v-card>
        <create-calculation-page v-if="!isAdmin && createCalculationDialog" @close="close()"
                                 @refresh="refresh()"></create-calculation-page>

        <v-row>
            <refund-balance-dialog v-model="refundBalanceDialog" :calculation="selectedCalculation"
                                   @refresh="refreshUserDetail" @close="close"></refund-balance-dialog>
        </v-row>

    </v-container>
</template>

<script>

    import CreateCalculationPage from "@/modules/calculation/components/CreateCalculationPage";
    import RefundBalanceDialog from "@/modules/calculation/components/dialogs/RefundBalanceDialog";
    import Pagination from "@/modules/core/mixins/pagination.js";
    import moment from "moment";
    import {store} from "ekx-vue-base";

    export default {
        props: ['isAdmin'],
        components: {
            CreateCalculationPage,
            RefundBalanceDialog
        },
        mixins: [
            new Pagination({
                methodName: "getCalculations",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    name: "=lk="
                },
                searchMirror: {
                    name: {
                        path: "nameSearch"
                    }
                }
            })
        ],
        data() {
            return {
                userInfo: this.$store.getters['core/getUserInfo'],
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                calculations: [],
                listLoading: false,
                selectedCalculation: null,
                refundBalanceDialog: false,
                rowClick: true,
                wallet: null,
                apiKey: null,
                apiSecret: null,
                createCalculationDialog: false,
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                tableOptions: {},
                selectedStatus: this.$store.getters['core/getCalculationStatus'] === null ? {
                    text: this.$t('ui.calculation.allStatus'),
                    value: null
                } : this.$store.getters['core/getCalculationStatus'],
                selectedType: {text: this.$t('ui.calculation.allTypes'), value: null},
                userOption: {
                    text: this.$t('ui.calculation.allUsers'),
                    value: null
                },
                //dataList
                statusList: [{
                    text: this.$t('ui.calculation.allStatus'),
                    value: null
                }, {text: this.$t('ui.general.success'), value: 0}, {text: this.$t('ui.general.error'), value: 1},
                    {text: this.$t('ui.general.processing'), value: 3}],
                userOptionList: [{
                    text: this.$t('ui.calculation.allUsers'),
                    value: null
                }, {text: this.$t('ui.user.parent'), value: 0}, {text: this.$t('ui.user.subUser'), value: 1}],
                timeInterval: this.$store.getters['core/getCalculationTimeInterval'] === null ? {
                    text: this.$t('ui.calculation.thisWeek'),
                    value: 0
                } : this.$store.getters['core/getCalculationTimeInterval'],
                timeIntervals: [{text: this.$t('ui.calculation.thisWeek'), value: 0},
                    {text: this.$t('ui.calculation.thisMonth'), value: 1},
                    {text: this.$t('ui.general.all'), value: null}],
                typeList: [{text: this.$t('ui.calculation.allTypes'), value: null}],
                headers: [
                    {
                        text: this.$t('ui.general.header.requestName'),
                        value: 'request.name',
                        align: "start",
                        sortable: false,
                        width: "100"
                    },
                    {
                        text: this.$t('ui.calculation.id'),
                        value: 'pid',
                        sortable: false,
                        width: "150"
                    },
                    {
                        text: this.$t('ui.user.username'),
                        value: 'user.username',
                        sortable: false,
                        width: "150"
                    },
                    {
                        text: this.$t('ui.general.header.requestTime'),
                        value: 'requestTime',
                        sortable: false,
                        width: "150"
                    }, {
                        text: this.$t('ui.general.header.requestPrice'),
                        value: 'request.price',
                        sortable: false,
                        width: "150"
                    }, {
                        text: this.$t('ui.general.header.status'),
                        value: 'status',
                        sortable: false,
                        width: "150"
                    },
                    {
                        text: "WEB/API Client",
                        value: 'api',
                        sortable: false,
                        width: "200"
                    },
                    {
                        text: this.$t('ui.general.header.actionText'),
                        value: 'actions',
                        sortable: false,
                        width: "120"
                    }
                ],


            };
        },
        methods: {
            async getCalculations() {
                try {
                    this.listLoading = true;
                    let query = this.createQuery();
                    let userPid = this.isAdmin ? this.$route.params.userPid : this.userInfo.pid;
                    let result = await this.$http.get(`${this.urlPrefix}/users/${userPid}/taskResults?userOption=${this.userOption.value}&${query}&timeInterval=${this.timeInterval.value}&status=${this.selectedStatus.value}&calculationType=${this.selectedType.value}`);
                    result.data.items.forEach(calculation => {
                        if (calculation.request && this.typeList.find(s => s.text !== calculation.request.name)) {
                            this.typeList.push({text: calculation.request.name, value: calculation.request.calculationType});
                        }
                        calculation.requestTime = moment.utc(calculation.requestTime, "YYYY-MM-DD H:mm")
                            .toISOString();
                        calculation.requestTime = moment(String(calculation.requestTime)).format(
                            "DD.MM.YYYY H:mm:ss"
                        );

                    });
                    this.calculations = result.data.items;
                    this.query.pagination = result.data.meta.pagination
                } catch (error) {
                    this.$error.notify(error)
                } finally {
                    this.listLoading = false;
                }

            },

            calculationDetail(item) {
                if (this.rowClick) {
                    if (this.isAdmin) {
                        this.$router.push(`${this.urlPrefix}/users/${this.$route.params.userPid}/calculations/${item.pid}`).catch(() => {
                        });
                    } else {
                        this.$router.push(`${this.urlPrefix}/calculations/${item.pid}`).catch(() => {
                        });
                    }
                }
                this.rowClick = true;
            },

            calculationCreate() {
                if (!this.apiKey && !this.apiSecret) {
                    this.$snotify.warning(this.$t('ui.apiKey.notFound'));
                } else {
                    this.createCalculationDialog = true
                }
            },

            refundBalance(item) {
                this.rowClick = false;
                this.selectedCalculation = item;
                this.refundBalanceDialog = true;
            },

            refresh() {
                this.rowClick = true;
                this.createCalculationDialog = false;
                this.getCalculations();
            },

            async getApiKey() {
                try {
                    let result = await this.$http.get(`${this.urlPrefix}/users/${this.userInfo.pid}/api-keys`);
                    if (result.data.items.length > 0) {
                        this.apiSecret = result.data.items[0].secret;
                        this.apiKey = result.data.items[0].key;
                    }
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            refreshUserDetail() {
                this.rowClick = true;
                this.refundBalanceDialog = false;
                this.getCalculations();
                this.$emit("refreshWallet", true);
            },
            onCopyPid: function (key) {
                this.rowClick = false;
                const el = document.createElement('textarea');
                el.value = key;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.$snotify.success(this.$t('ui.calculation.copySuccess'));
            },
            close() {
                this.createCalculationDialog = false;
                this.refundBalanceDialog = false;
            },
        },
        watch: {
            timeInterval: function (val) {
                store.commit("core/setCalculationTimeInterval", val);

                this.getCalculations();
            },

            selectedStatus: function (val) {
                store.commit("core/setCalculationStatus", val);
                this.getCalculations();
            },

            selectedType: function () {
                this.getCalculations();
            },

            userOption: function () {
                this.getCalculations();
            },

            "tableOptions.sortBy"() {
                this.query.sort.field = this.tableOptions.sortBy[0];
                this.query.sort.order = this.tableOptions.sortDesc[0] ? "desc" : "asc"
            }
        },
        mounted() {
            if (this.isAdmin && this.typeList.find(s => s.value !== 2)) {
                this.statusList.push({text: this.$t('ui.general.refund'), value: 2})
            }
            this.getCalculations();
            this.getApiKey();
        }

    };
</script>

<style scoped>
</style>