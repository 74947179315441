<template>
    <v-dialog v-model="value" max-width="600px" :persistent="true">
        <v-card>
            <v-toolbar color="primary" dark class="elevation-1">
                <v-toolbar-title>{{$t('ui.card.dialog.approval')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon @click="close()" v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    {{$t('ui.general.close')}}
                </v-tooltip>
            </v-toolbar>
            <v-container class="pa-0">
                <v-col class="pa-6">
                    {{$t('ui.card.dialog.approvalText')}} ( {{ amount }} TRY) ?
                </v-col>
                <v-divider></v-divider>
                <v-col>
                    <v-row justify="end" no-gutters>
                        <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
                        <v-btn class="mx-1" color="primary" :loading="loading" @click="apply()">
                            {{$t('ui.general.apply')}}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: ["value", "amount"],
        data() {
            return {
                loading: false
            };
        },
        methods: {
            async apply() {
                this.loading = true;
                this.$emit('approvePayment')
            },

            close() {
                this.loading = false;
                this.$emit("close", true);
            }
        },

        mounted() {
            let _this = this;
            this.$bus.$on('removeLoading', function () {
                _this.loading = false;
            })
        }
    };
</script>