<template>
    <v-row justify="center">
        <v-dialog v-model="value" max-width="600px">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title> {{$t('ui.dataSource.editDialog')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-container class="pa-0">
                    <v-col class="pa-6">
                        <ValidationObserver ref="editNameForm">
                            <ValidationProvider rules="required|whiteSpace|min:3|max:50" v-slot="{ errors }">
                                <v-text-field v-model="name"
                                              :maxLength="50"
                                              :error-messages="errorMessage ? errorMessage : errors"
                                              :loading="textLoading"
                                              :label="$t('ui.user.name')"
                                              type="text"></v-text-field>
                            </ValidationProvider>
                        </ValidationObserver>
                    </v-col>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
                    <v-btn class="mx-1" color="primary" :loading="loading" @click="updateName()">
                        {{$t('ui.general.save')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";

    export default {
        props: ["value", "data"],
        data() {
            return {
                errorMessage: null,
                errors: null,
                loading: false,
                name: null,
                textLoading: false,
            }
        },
        watch: {
            value: function () {
                if(this.data){
                    this.name = this.data.name;
                }
            },
        },
        created() {
            EkxDebounce.watch(this, "name", 500, async () => {
                if (this.name && this.name.length > 2 && this.name !== this.data.name && this.name.length < 51) {
                    this.errorMessage = null;
                    this.textLoading = true;
                    let available = await this.isNameAvailable(this.name);
                    if (available) {
                        this.textLoading = false;
                        this.errorMessage = null;
                    } else {
                        this.errorMessage = this.$t('ui.general.nameError');
                        this.textLoading = false;
                    }
                }
            });
        },
        methods: {
            async updateName() {
                this.loading = true;
                if(this.data && this.data.name===this.name){
                    this.$emit("close", true);
                }else{
                    if (await this.$refs.editNameForm.validate() && this.errorMessage === null) {
                        try {
                            await this.$http
                                .patch(
                                    `data-sources/${this.data.pid}`, {name: this.name});
                            this.loading = false;
                            this.$snotify.success(this.$t('ui.dataSource.editDataName'));
                            this.$refs.editNameForm.reset();
                            this.$emit('refresh');
                        } catch (error) {
                            this.loading = false;
                            this.$error.notify(error)
                        }
                    } else {
                        this.loading = false;
                        this.$snotify.warning("Please fill in the fields correctly");
                    }
                }

            },

            close() {
                this.loading = false;
                this.$refs.editNameForm.reset();
                this.$emit("close", true);
            },

            clear() {
                this.loading = false;
            },
            async isNameAvailable(name) {
                try {
                    let response = await this.$http.get(`/data-sources/names/${name}`);
                    return !!response.data;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

        },
    };
</script>