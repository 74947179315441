var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px","persistent":true},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-1 base primary",attrs:{"dark":""}},[(_vm.selectedApiKey)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('ui.apiKey.edit')))]):_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('ui.apiKey.create')))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('ui.general.close'))+" ")])],1),_c('v-card-text',{staticClass:"mt-6"},[_c('ValidationObserver',{ref:"apiKeyOperationForm"},[_c('ValidationProvider',{attrs:{"rules":"required|whiteSpace|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxLength":50,"error-messages":_vm.errorMessage ? _vm.errorMessage : errors,"label":_vm.$t('ui.apiKey.name'),"type":"text","loading":_vm.textLoading},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.walletList)?_c('v-select',{attrs:{"items":_vm.walletList,"item-text":"name","item-value":"pid","return-object":"","error-messages":errors,"label":_vm.$t('ui.apiKey.selectWallet')},model:{value:(_vm.wallet),callback:function ($$v) {_vm.wallet=$$v},expression:"wallet"}}):_vm._e()]}}])}),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-combobox',{attrs:{"chips":"","error-messages":_vm.errorIPMessage,"label":_vm.$t('ui.apiKey.allowedIpList'),"multiple":""},on:{"change":function($event){return _vm.IPRule(_vm.ipList)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-chip',_vm._b({attrs:{"color":"primary","close":""},on:{"click:close":function($event){return _vm.removeIp(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}],null,true),model:{value:(_vm.ipList),callback:function ($$v) {_vm.ipList=$$v},expression:"ipList"}})]}}])})],1),_c('v-row',{staticClass:"ml-0"},[_c('v-row',{attrs:{"no-gutters":"","hide-details":""}},[_c('v-checkbox',{attrs:{"label":_vm.$t('ui.apiKey.canQuery')},model:{value:(_vm.canQuery),callback:function ($$v) {_vm.canQuery=$$v},expression:"canQuery"}})],1),_c('v-row',{attrs:{"no-gutters":"","hide-details":""}},[_c('v-checkbox',{attrs:{"label":_vm.$t('ui.apiKey.canSeeOld')},model:{value:(_vm.canSeeOld),callback:function ($$v) {_vm.canSeeOld=$$v},expression:"canSeeOld"}})],1),_c('v-row',{attrs:{"no-gutters":"","hide-details":""}},[_c('v-checkbox',{attrs:{"label":_vm.$t('ui.apiKey.monteCarlo')},model:{value:(_vm.monteCarlo),callback:function ($$v) {_vm.monteCarlo=$$v},expression:"monteCarlo"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('ui.general.cancel')))]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.apiKeyOperation()}}},[(_vm.selectedApiKey)?_c('span',[_vm._v(_vm._s(_vm.$t('ui.general.save')))]):_c('span',[_vm._v(_vm._s(_vm.$t('ui.general.create')))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }