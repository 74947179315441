/**
 *
 */
import {i18n} from "ekx-vue-base"
import Vue from "vue";
import instance from "../../../main"

class ErrorHandler {

    static notify(error) {
        if (error.response && error.response.data) {
            if(error.response.data.errors){
                error.response.data.errors.forEach((errorObject) => {
                    if (errorObject.detailKey === 'errors.request.validation') {
                        instance.$snotify.error(i18n.t(errorObject.detailKey, {line: errorObject.metadata.errorLine, detail: errorObject.metadata.error[0].dataPath, message: errorObject.metadata.error[0].message})),
                            {options: {bodyMaxLength: 80,   closeOnClick: false}};
                    } else{
                        instance.$snotify.error(i18n.t(errorObject.detailKey));
                    }
                });
            }
            else{
                instance.$snotify.error(i18n.t(error.message));
            }
        }
    }

}

Object.defineProperties(Vue.prototype, {
    $error: {
        get: function () {
            return ErrorHandler
        }
    }
});

export default ErrorHandler;



