<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card class="pt-4 mt-4">
        <v-stepper v-model="e1">
            <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">
                    {{$t('ui.request.requests')}}
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step step="2">
                    {{$t('ui.request.validation')}}
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <ValidationObserver ref="createOrEditRequestForm">
                        <v-row class="justify-center">
                            <v-col lg="9" md="9">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <v-autocomplete
                                            v-model="type"
                                            :items="typeList"
                                            item-text="text"
                                            item-value="value"
                                            :error-messages="errors"
                                            :label="$t('ui.request.pleaseSelect')"
                                            class="mt-8"
                                            dense
                                    ></v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row class="justify-center">
                            <v-col lg="9" md="9">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <v-autocomplete
                                            v-model="status"
                                            :items="statusList"
                                            item-text="text"
                                            item-value="value"
                                            :error-messages="errors"
                                            :label="$t('ui.request.pleaseStatus')"
                                            class="mt-8"
                                            dense
                                    ></v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row class="justify-center">
                            <v-col lg="9" md="9">
                                <ValidationProvider rules="required|whiteSpace|min:3|max:50|"
                                                    v-slot="{ errors }">
                                    <v-text-field v-model="name"
                                                  :maxLength="50"
                                                  :error-messages="errorMessage ? errorMessage : errors"
                                                  :label="$t('ui.request.name')" type="text"
                                                  :loading="textLoading"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row class="justify-center">
                            <v-col lg="9" md="9">
                                <ValidationProvider rules="required|"
                                                    v-slot="{ errors }">
                                    <v-text-field v-model="calculationType"
                                                  :maxLength="100"
                                                  :error-messages="calTypeErrorMessage ? calTypeErrorMessage : errors"
                                                  :loading="calculationTypeLoading"
                                                  :label="$t('ui.request.calculationType')" type="text"
                                                  ></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row class="justify-center">
                            <v-col lg="9" md="9">
                                <ValidationProvider rules="required|whiteSpace|min:0|max:50|integer"
                                                    v-slot="{ errors }">
                                    <v-text-field v-model="price"
                                                  :maxLength="50"
                                                  :error-messages="errors"
                                                  min="0"
                                                  :label="$t('ui.request.price')" type="number"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </ValidationObserver>
                </v-stepper-content>
                <v-divider></v-divider>
                <v-card-actions v-if="e1===1">
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}
                    </v-btn>
                    <v-btn class="mx-1" color="primary" outlined :loading="loading" @click="next()">
                        {{$t('ui.general.next')}}
                    </v-btn>
                </v-card-actions>
                <v-stepper-content step="2">
                        <v-row justify="center">
                            <v-col cols="10">
                                <ValidationObserver ref="createOrEditRequestSecondForm">
                                    <ace-editor v-model="validation" height="500px" class="jsonEditor"
                                                @init="editorInit"
                                                lang="json" theme="chrome"></ace-editor>
                                </ValidationObserver>
                            </v-col>
                        </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-divider></v-divider>
        <v-card-actions v-if="e1===2">
            <v-spacer></v-spacer>
            <v-btn class="mx-1" color="warning" @click="e1=1">{{$t('ui.general.back')}}</v-btn>
            <v-btn class="mx-1" color="primary" :loading="loading" @click="createOrEditRequest()">
                {{$t('ui.general.save')}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";
    import AceEditor from 'vue2-ace-editor';

    export default {
        components: {
            AceEditor
        },
        props: ['request'],
        data() {
            return {
                loading: false,
                textLoading: false,
                calculationTypeLoading: false,
                name: this.request ? this.request.name : null,
                calculationType: this.request ? this.request.calculationType : null,
                type: this.request ? this.request.type : null,
                status: this.request ? this.request.status: 1,
                editFlag: false,
                typeList: [{text: 'Risk', value: 1}, {text: "Price", value: 0}],
                statusList: [{text: this.$t('ui.general.active'), value: 1}, {text: this.$t('ui.general.inactive'), value: 0}],
                validation: this.request ? JSON.stringify(this.request.validation) : null,
                price: this.request ? this.request.price : null,
                e1: 1,
                showEditorIndex: null,
                temporaryIndex: null,
                typeValueList: ['Float', 'Date', 'DataSource', 'Integer', 'Boolean', 'Select', 'String', 'Leg'],
                errorMessage: null,
                calTypeErrorMessage: null
            };
        },
        created() {
            EkxDebounce.watch(this, "name", 500, async () => {
                 if(this.name && this.name.length > 2) {
                    if ((this.request && (this.name !== this.request.name)) || !this.request) {
                        this.errorMessage = null;
                        this.textLoading = true;
                        let available = await this.isAvailable({text:this.name, name: true});
                        if (available) {
                            this.textLoading = false;
                            this.errorMessage = null;
                        } else {
                            this.errorMessage = this.$t('ui.general.nameError');
                            this.textLoading = false;
                        }
                    }
                }

            });
            EkxDebounce.watch(this, "calculationType", 500, async () => {
                if(this.calculationType && this.calculationType.length > 2) {
                    if ((this.request && (this.calculationType !== this.request.calculationType)) || !this.request) {
                        this.calTypeErrorMessage = null;
                        this.calculationTypeLoading = true;
                        let available = await this.isAvailable({text:this.calculationType, name: false});
                        if (available) {
                            this.calculationTypeLoading = false;
                            this.calTypeErrorMessage = null;
                        } else {
                            this.calTypeErrorMessage = this.$t('ui.general.calculationTypeError');
                            this.calculationTypeLoading = false;
                        }
                    }
                }
            });
        },
        methods: {
            async next() {
                if ((this.e1 === 1 && await this.$refs.createOrEditRequestForm.validate() || this.e1 === 2 && await this.$refs.createOrEditRequestSecondForm.validate()) && this.errorMessage === null && this.calTypeErrorMessage === null) {
                   this.e1 = 2 ;
                } else {
                    this.loading = false;
                    this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                }
            },

            close() {
                this.clear();
                this.$emit("close", true);
            },

            async createOrEditRequest() {
                this.loading = true;
                if (typeof this.status === 'object') {
                    this.status = this.status.value
                }
                let request_data = {
                    name: this.name,
                    calculationType: this.calculationType,
                    price: this.price,
                    type: this.type,
                    validation:this.validation,
                    status: this.status
                };

                if (await this.$refs.createOrEditRequestSecondForm.validate() && JSON.parse(this.validation) && this.errorMessage === null && this.calTypeErrorMessage === null) {
                    try {
                        this.request ? await this.$http.patch(`/admin/requests/${this.request.pid}`, request_data)
                            : await this.$http.post(`/admin/requests`, request_data);
                        let snotifyText = this.request ? 'editSuccess' : 'createSuccess';
                        this.$snotify.success(this.$t('ui.request.' + snotifyText));
                        this.clear();
                        this.$emit("refresh", true);
                    } catch (error) {
                        this.loading = false;
                        this.$error.notify(error)
                    }
                } else {
                    this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                    this.loading = false
                }
            },
            editorInit:function (editor) {
                require("brace/mode/json");
                require("brace/theme/chrome");
                require('brace/ext/searchbox');
                editor.focus();
                editor.setShowPrintMargin(false);
               if (this.validation) {
                   this.validation = JSON.stringify(JSON.parse(this.validation), null, '\t');
               }
                editor.setOptions({
                    fontSize: "18px",
                });
            },
            async clear() {
                this.loading = false;
                this.name = null;
                this.calculationType = null;
                this.price = null;
                this.type = null;
                this.textLoading = false;
                this.validation = {};
                this.showEditorIndex = null;
                this.editFlag = false;
                this.temporaryIndex = null;
                this.status= 1;
                if (this.e1 === 1) {
                    await this.$refs.createOrEditRequestForm.reset();
                } else {
                    await this.$refs.createOrEditRequestSecondForm.reset();
                }
                this.e1 = 1;
            },

            async isAvailable({text, name}) {
                try {
                    let response = await this.$http.get(`/admin/requests/${text}/isAvailable?name=${name}&calculationType=${!name}`);
                    return !response.data;
                } catch (error) {
                    this.$error.notify(error)
                }
            },
        },
    };
</script>
