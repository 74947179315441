<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row justify="center">
    <v-dialog v-model="value" max-width="600px" :persistent="true">
      <v-card>
        <v-toolbar class="elevation-1 base primary" dark>
          <v-toolbar-title v-if="selectedApiKey">{{ $t('ui.apiKey.edit') }}</v-toolbar-title>
          <v-toolbar-title v-else>{{ $t('ui.apiKey.create') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close()" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            {{ $t('ui.general.close') }}
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="mt-6">
          <ValidationObserver ref="apiKeyOperationForm">
            <ValidationProvider rules="required|whiteSpace|min:3|max:50" v-slot="{ errors }">
              <v-text-field v-model="name"
                            :maxLength="50"
                            :error-messages="errorMessage ? errorMessage : errors"
                            :label="$t('ui.apiKey.name')" type="text" :loading="textLoading"></v-text-field>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select v-if="walletList" v-model="wallet"
                        :items="walletList"
                        item-text="name"
                        item-value="pid"
                        return-object
                        :error-messages="errors"
                        :label="$t('ui.apiKey.selectWallet')"
              ></v-select>
            </ValidationProvider>
            <ValidationProvider v-slot="{}">
              <v-combobox v-model="ipList" chips
                          :error-messages="errorIPMessage"
                          @change="IPRule(ipList)"
                          :label="$t('ui.apiKey.allowedIpList')" multiple>
                <template v-slot:selection="{ attrs, item }">
                  <v-chip color="primary" v-bind="attrs"
                          close @click:close="removeIp(item)">
                    <strong>{{ item }}</strong>&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </ValidationProvider>
          </ValidationObserver>
          <v-row class="ml-0">
            <v-row no-gutters hide-details>
              <v-checkbox v-model="canQuery" :label="$t('ui.apiKey.canQuery')"></v-checkbox>
            </v-row>
            <v-row no-gutters hide-details>
              <v-checkbox v-model="canSeeOld" :label="$t('ui.apiKey.canSeeOld')"></v-checkbox>
            </v-row>
            <v-row no-gutters hide-details>
              <v-checkbox v-model="monteCarlo" :label="$t('ui.apiKey.monteCarlo')"></v-checkbox>
            </v-row>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-1" color="warning" @click="close()">{{ $t('ui.general.cancel') }}</v-btn>
          <v-btn class="mx-1" color="primary" :loading="loading" @click="apiKeyOperation()">
            <span v-if="selectedApiKey">{{ $t('ui.general.save') }}</span>
            <span v-else>{{ $t('ui.general.create') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import EkxDebounce from "@/modules/core/utils/EkxDebounce";

export default {
  props: ['value', 'userInfo', 'selectedApiKey'],
  data() {
    return {
      urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
      loading: false,
      textLoading: false,
      errorMessage: null,
      errorIPMessage: null,
      name: null,
      wallet: null,
      ipList: null,
      walletList: [],
      canQuery: null,
      canSeeOld: null,
      monteCarlo: null
    };
  },
  watch: {
    value: async function () {
      await this.getWallets();
      if (this.selectedApiKey) {
        this.name = this.selectedApiKey.name;
        this.wallet = this.selectedApiKey.wallet ? {
          name: this.selectedApiKey.wallet.name,
          pid: this.selectedApiKey.wallet.pid
        } : null;
        this.ipList = this.selectedApiKey.allowedIpList;
        this.canSeeOld = this.selectedApiKey.canSeeOld;
        this.canQuery = this.selectedApiKey.canQuery;
        this.monteCarlo = this.selectedApiKey.monteCarlo;
      }
    },
  },
  created() {
    EkxDebounce.watch(this, "name", 500, async () => {
      if ((this.selectedApiKey && this.name !== this.selectedApiKey.name) || (!this.selectedApiKey) && (this.name && this.name.length > 2)) {
        this.errorMessage = null;
        this.textLoading = true;
        if (this.name.length > 50) {
          this.errorMessage = null;
        } else {
          let available = await this.isNameAvailable(this.name);
          if (available) {
            this.textLoading = false;
            this.errorMessage = null;
          } else {
            this.errorMessage = this.$t('ui.general.nameError');
            this.textLoading = false;
          }
        }
      }
    });
  },
  methods: {
    close() {
      this.clear();
      this.$emit("close", true);
    },

            async apiKeyOperation() {
                this.loading = true;
                let api = {
                    name: this.name,
                    walletPid: this.wallet ? this.wallet.pid : null,
                    ipList: this.ipList,
                    canQuery: this.canQuery,
                    canSeeOld: this.canSeeOld,
                    monteCarlo: this.monteCarlo
                };

                if (await this.$refs.apiKeyOperationForm.validate() && this.errorMessage === null && this.errorIPMessage=== null) {
                        try {
                            this.selectedApiKey ? await this.$http.patch(`${this.urlPrefix}/api-keys/${this.selectedApiKey.pid}`, {data:api})
                                : await this.$http.post(`${this.urlPrefix}/users/${this.userInfo.pid}/api-keys`, api);
                            let snotifyText = this.selectedApiKey ? 'editSuccess' : 'createSuccess';
                            this.$snotify.success(this.$t('ui.apiKey.' + snotifyText));
                            this.clear();
                            this.$emit("refresh", true);
                        } catch (error) {
                            this.loading = false;
                            this.$error.notify(error)
                        }
                } else {
                    this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                    this.loading = false
                }
            },

    async getWallets() {
      let response = this.userInfo.parent ? await this.$http.get(`${this.urlPrefix}/users/${this.userInfo.pid}/wallets?permission=true`) : await this.$http.get(`${this.urlPrefix}/users/${this.userInfo.pid}/wallets`);
      response.data.items.forEach((wallet) => {
        this.walletList.push({name: wallet.name, pid: wallet.pid})
      });
    },

    removeIp(item) {
      this.ipList.splice(item, 1);
      this.IPRule(this.ipList);
    },

    IPRule(ipList) {
      if (ipList.length === 0) {
        this.errorIPMessage = null;
      }
      for (let i = 0; i < ipList.length; i++) {
        let IPformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (IPformat.test(ipList[i])) {
          this.errorIPMessage = null;
        } else {
          this.errorIPMessage = 'Not a valid IP';
        }
      }
    },

    async clear() {
      this.loading = false;
      this.name = null;
      this.ipList = [];
      this.canSeeOld = false;
      this.canQuery = false;
      this.monteCarlo = false;
      this.wallet = null;
      this.walletList = [];
      this.errorMessage = null;
      this.errorIPMessage = null;
      await this.$refs.apiKeyOperationForm.reset();
    },

    async isNameAvailable(name) {
      try {
        let response = await this.$http.get(`${this.urlPrefix}/users/${this.userInfo.pid}/api-keys/names/${name}`);
        return !!response.data;
      } catch (error) {
        this.$error.notify(error)
      }
    }
  },
};
</script>
