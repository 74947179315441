<!-- Header Structure -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-app-bar app fixed class="px-3">
        <v-app-bar-nav-icon @click.stop="$emit('update:sidebar',!sidebar)" class="v-step-0"></v-app-bar-nav-icon>
        <create-feedback-dialog v-model="createFeedbackDialog" @close="close()"></create-feedback-dialog>
        <v-spacer></v-spacer>
        <v-menu>
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" v-if=" $lang.getLocale()">
                    <v-icon left>mdi-translate</v-icon>
                    {{$t("ui.language." + $lang.getLocale())}}
                </v-btn>
            </template>
            <v-list style="width: 250px;">
                <v-list-item v-for="lang in languages" :key="lang.key" @click="selectLanguage(lang)">
                    <v-list-item-title>
                        {{$t(lang.text)}}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="updateNotification()">
                    <v-icon>
                        notifications
                    </v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title> {{$t('ui.notification.notifications')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn v-if="notificationSize!==0" class="mx-1" color="warning" @click="close()">
                        {{notificationSize}}
                        {{$t('ui.notification.new')}}
                    </v-btn>
                </v-toolbar>
            </v-card>
            <v-list v-if="notificationList.length > 0" class="pic-container" max-width="600px" three-line>
                <v-list-item v-for="notification in notificationList" :key="notification.pid"
                             @click="goNotificationDetail(notification.url)">
                    <v-list-item-content>
                        <v-list-item-subtitle inset v-html="$t('ui.log.'+ notification.key , {from: notification.data.from , to: notification.data.to,
                                balance:notification.data.balance, walletName:notification.data.walletName})">
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-right font-weight-bold">{{notification.createdAt}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="notificationList.length !== notificationTotalCount " @click.stop.prevent
                             @click="moreNotification()">
                    <v-spacer></v-spacer>
                    <v-list-item-subtitle class="text-right" v-html="$t('ui.general.more')">
                    </v-list-item-subtitle>
                </v-list-item>
            </v-list>
            <v-list v-if="notificationList.length === 0 && !moreFlag" min-width="300px">
                <v-list-item-subtitle class="ml-4" v-html="$t('ui.notification.noNotification')">
                </v-list-item-subtitle>
            </v-list>
        </v-menu>
        <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon>
                        person
                    </v-icon>
                </v-btn>
            </template>
            <v-list style="width: 250px;">

                <v-list-item @click="userProfile">
                    <v-list-item-title>
                        <v-icon class="mr-2">account_box</v-icon>
                        <span v-if="this.$store.state.core.userInfo.name"> {{this.$store.state.core.userInfo.name}}</span>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="urlPrefix !=='/admin' " @click="createFeedbackDialog=true">
                    <v-list-item-title>
                        <v-icon class="mr-2">feedback</v-icon>
                        <span>{{$t('ui.feedback.feedback')}}</span>
                    </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-title @click.stop>
                        <v-row no-gutters align="center" class="py-3">
                            <span>{{$t('ui.general.darkTheme')}}</span>
                            <v-spacer></v-spacer>
                            <v-switch
                                    v-model="$store.state.core.theme.dark"
                                    hide-details
                                    class="mt-0"
                                    dense
                                    @change="themeChanged">
                            </v-switch>
                        </v-row>
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item @click="signout">
                    <v-list-item-title>
                        <v-icon class="mr-2">mdi-logout-variant</v-icon>
                        <span> {{$t('ui.general.signOut')}}</span>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

    </v-app-bar>

</template>
<script>
    import CreateFeedbackDialog from "@/modules/feedback/components/dialogs/CreateFeedbackDialog"
    import moment from "moment";

    export default {
        props: ['sidebar'],
        components: {
            CreateFeedbackDialog
        },
        data() {
            return {
                urlPrefix: this.$store.getters['core/getUserInfo'].isSystemAdmin === true ? '/admin' : '',
                createFeedbackDialog: false,
                notificationSize: 0,
                notificationList: [],
                notificationTotalCount: null,
                moreFlag: false,
                limit: 20,
                languages: [{
                    key: "tr",
                    text: "ui.language.tr"
                }, {
                    key: "en",
                    text: "ui.language.en"
                }],
            }
        },
        methods: {
            themeChanged(value) {
                this.$vuetify.theme.dark = !!value;
                this.$store.commit("core/setTheme", !!value);
            },
            signout() {
                this.$auth.signOut();
            },
            selectLanguage(lang) {
                this.$lang.setLocale(lang.key);
                // this.$bus.$emit("force-render");
                this.$store.commit('core/setLanguage', lang.key)
                document.documentElement.lang = this.$store.state.core.ui.lang;
            },
            userProfile() {
                this.$router.push(`${this.urlPrefix}/me`).catch(() => {
                });
            },
            userNotification: async function () {
                this.notificationSize = 0;
                try {
                    if (this.urlPrefix === '' && this.$store.state.core.user.isLoggedIn) {
                        let response = await this.$http.get(`${this.urlPrefix}/notifications?limit=${this.limit}&sort=createdAt desc`);
                        this.notificationList = response.data.items;
                        this.notificationTotalCount = response.data.meta.pagination.totalCount;
                        this.notificationList.forEach(async (notification) => {
                            notification.createdAt = moment.utc(notification.createdAt, "YYYY-MM-DD H:mm")
                                .toISOString();
                            notification.createdAt = moment(String(notification.createdAt)).format(
                                "DD.MM.YYYY H:mm"
                            );
                            if (!notification.read) {
                                this.notificationSize = this.notificationSize + 1;
                            }
                            if (notification.key !== 'DELETE_WALLET') {
                                notification.url = await this.getUrl(notification.type, notification.data);
                            }
                        });
                    }

                } catch (error) {
                    this.$error.notify(error)
                }
            },
            moreNotification: async function () {
                this.limit = this.limit + 10;
                this.moreFlag = true;
                await this.userNotification();
            },
            timerFunction() {
                setInterval(() => {
                    this.userNotification()
                }, 30000);
            },
            async updateNotification() {
                try {
                    await new Promise(resolve => setTimeout(resolve, 4000));
                    await this.$http.patch(`${this.urlPrefix}/notifications`);
                    this.notificationSize = 0;
                } catch (error) {
                    this.$error.notify(error)
                }
            },
            getUrl(type, data) {
                let notificationUrl = {
                    2: (`${this.urlPrefix}/wallets/${data.wallet_pid}`),
                };
                return notificationUrl[type];
            },
            goNotificationDetail(url) {
                this.$router.push(url).catch(() => {
                });
            },
            close() {
                this.createFeedbackDialog = false;
            },
            // async checkUserSecurity() {
            //     console.log("security control");
            //     if (!this.$store.state.core.userInfo.firstCome && !this.$store.state.core.userInfo.isSystemAdmin ) {
            //         let result = await this.$http.get("/agreements");
            //         if (result.data.length > 0) {
            //             this.$store.commit("core/setFirstCome", true);
            //             this.$router.push('/personal-data-protection').catch(() => {
            //             });
            //         }
            //     }
            // }
        },
        beforeCreate() {
            this.$vuetify.theme.dark = this.$store.state.core.theme.dark;
            this.$store.commit("core/setGlobalLoading", false);
            if (this.$store.state.core.ui.lang) {
                this.$lang.setLocale(this.$store.state.core.ui.lang);
            }
        },
        mounted: function () {
            // this.checkUserSecurity();
            this.userNotification();
            this.timerFunction();
        }
    };
</script>
<style>
    .pic-container {
        max-height: 400px;
        overflow: auto;
    }

</style>

