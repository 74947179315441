import {http, bus} from "ekx-vue-base"

let isLoadingOnHold = false;
export default {
    namespaced: true,
    state: {
        user: {
            isLoggedIn: false
        },
        pageCount: 10,
        userInfo: {},
        calculationTimeInterval: null,
        calculationStatus: null,
        theme: {
            dark: false
        },
        ui: {
            loading: false,
            lang: "en"
        },
        app: {
            version: "loading"
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = {
                ...user,
                isLoggedIn: true
            };
            http.defaults.headers["authorization"] = user.access_token;
        },
        setUserInfo(state, userInfo) {
            state.userInfo = {...userInfo};
        },
        dropUser(state) {
            state.user = {
                isLoggedIn: false
            }
        },
        setTheme(state, value) {
            state.theme.dark = value;
        },
        setDataViewType(state, type) {
            state.ui.dataView.type = type;
        },
        setPageCount(state, pageCount) {
            state.pageCount = pageCount;
        },
        setCalculationTimeInterval(state, val) {
            state.calculationTimeInterval = val;
        },
        setCalculationStatus(state, val) {
            state.calculationStatus = val;
        },
        setGlobalLoading(state, value) {
            if (value) {
                isLoadingOnHold = true;
                setTimeout(() => {
                    if (isLoadingOnHold) {
                        state.ui.loading = true;
                    }
                }, 250);
            } else {
                isLoadingOnHold = false;
                state.ui.loading = false;
            }
        },
        setCardId(state, data) {
            state.userInfo.cardId = data
        },
        setSubscription(state, data) {
            state.userInfo.subscription = data
        },
        setCustomer(state, customer_id) {
            state.userInfo.customerId = customer_id
        },
        setTab(state, data) {
            state.userInfo.tabs = data
        },
        setLanguage(state, value) {
            state.ui.lang = value;
            bus.$emit("force-render");
        },
        setFirstCome(state, firstCome) {
            state.userInfo.firstCome = firstCome;
        },
        setAppData(state, app) {
            state.app = app;
        }
    },
    actions: {
        async getAppData(context) {
            let response = await http.get("/app/details");
            context.commit('setAppData', response.data)
        }
    }, getters: {
        getUserInfo: (state) => {
            return state.userInfo;
        },
        getCalculationTimeInterval: (state) => {
            return state.calculationTimeInterval;
        },
        getCalculationStatus: (state) => {
            return state.calculationStatus;
        },
    }

}