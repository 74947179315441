<template>
    <v-container fluid class="app-container">
        <app-title-bar
                :title="page.title" :items="page.items">
        </app-title-bar>

        <v-card class="mt-6">
            <v-col v-if="openSourceData">
                <!--                <v-row no-gutters>-->
                <!--                    <v-select-->
                <!--                            v-model="dataType"-->
                <!--                            :items="dataTypes"-->
                <!--                            :label="$t('ui.openSource.displayType')"-->
                <!--                            return-object-->
                <!--                    ></v-select>-->
                <!--                </v-row>-->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="primary mb-4" style="color: #FFFFFF" icon @click="jsonDownload()"
                               v-bind="attrs"
                               v-on="on">
                            <v-icon>
                                mdi-download
                            </v-icon>
                        </v-btn>
                    </template>
                    {{$t('ui.general.downloadJSON')}}
                </v-tooltip>
                <v-row no-gutters>
                    <ace-editor v-if="isJson" v-model="openSourceData" :options="options"
                                  class="jsonEditor"  lang="json" theme="chrome"
                                height="800px" @init="editorInit" ></ace-editor>
                </v-row>
            </v-col>
            <v-row v-else align="center" class="pa-2">
                <v-container fluid>
                    <v-alert text outlined
                             height="100px"
                             prominent
                             type="warning"
                             icon="mdi-clock-fast">
                        <v-row align="center" no-gutters class="mt-2">
                            <v-col class="grow">
                                {{$t('ui.openSource.noData')}}
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-container>
            </v-row>
        </v-card>

    </v-container>
</template>

<script>
    import AceEditor from 'vue2-ace-editor';
    import moment from "moment";

    export default {

        components: {
            AceEditor
        },

        data() {
            return {
                openSourcePid: this.$route.params.openSourcePid,
                openSourceDetail: null,
                openSourceData: null,
                dataType: 'JSON',
                dataTypes: ['JSON', this.$t('ui.openSource.table'), this.$t('ui.openSource.graph')],
                isJson: true,
                isTable: false,
                options: {
                    readOnly: true,
                    autoScrollEditorIntoView: true
                },
                page: {
                    title: null,
                    items: [
                        {
                            text: this.$t('ui.openSource.header'),
                            exact: true,
                            to: `/open-sources`,
                        },
                        {
                            text: this.$t('ui.openSource.detail'),
                            exact: false
                        },
                    ]
                },
                headerList: [
                    'totalPresentValue',
                    'totalAccrued',
                    'totalEffectiveness',
                    'pv',
                    'leg1Pv',
                    'leg2Pv',
                    'fixedLegPv',
                    'floatingLegPv',
                    'oisLegPv',
                    'leg1Bps',
                    'leg2Bps',
                    'fixedLegBps',
                    'floatingLegBps',
                    'oisLegBps',
                    'impliedQuote',
                    'currency',
                    'baseCurrency',
                    'baseCurrencyResult',
                    'quotedCurrency',
                    'quotedCurrencyResult',
                    'spotRate',
                    'fairRate',
                    'spread',
                    'fairSpread',
                    'leg1FairRate',
                    'leg1FairSpread',
                    'leg2FairRate',
                    'leg2FairSpread',
                ],
            }
        },

        watch: {
            dataType: function (val) {
                if (val !== 'JSON') {
                    this.isJson = false;
                    this.isTable = true;
                } else {
                    this.isJson = true;
                    this.isTable = false;
                }
            }
        },

        methods: {

            async getOpenSource() {
                try {
                    let result = await this.$http.get(`/openSources/${this.openSourcePid}`);
                    result.data.createdAt = moment.utc(result.data.createdAt, "YYYY-MM-DD H:mm")
                        .toISOString();
                    result.data.createdAt = moment(String(result.data.createdAt)).format(
                        "DD.MM.YYYY"
                    );

                    this.openSourceData = null;
                    this.openSourceDetail = result.data;
                    this.page.title = this.openSourceDetail.name;
                    if (this.openSourceDetail.data) {
                        let b = Buffer.from(this.openSourceDetail.data, 'utf8');
                        this.openSourceData = JSON.stringify(JSON.parse(Buffer.from(b).toString('utf-8')), null, '\t');
                    }
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            jsonDownload() {
                const data = JSON.stringify(JSON.parse(this.openSourceData), null, '\t');
                const blob = new Blob([data], {type: 'json/plain'});
                const e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = `${this.openSourceDetail.name}-${this.openSourceDetail.createdAt}.json`;
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['json', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
            },
            editorInit:function (editor) {
                require("brace/mode/json");
                require("brace/theme/chrome");
                require('brace/ext/searchbox');
                editor.focus();
                editor.setShowPrintMargin(false);
                editor.setOptions({
                    fontSize: "18px",
                });
            },
        },

        mounted() {
            this.getOpenSource();
        }
    }


</script>