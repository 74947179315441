<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-row justify="center">
        <v-dialog v-model="value" max-width="600px">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title>{{$t('ui.wallet.loadBalance')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-card-text class="mt-6">
                    <span> {{$t('ui.wallet.balanceMessage')}} </span>
                    <br>
                    <span v-if="parentWallet">{{$t('ui.wallet.parentWallet')}} : <b>{{ parentWallet.balance }}</b> TRY</span>
                    <ValidationObserver ref="creditForm">
                        <ValidationProvider rules="required|integer" v-slot="{ errors }">
                            <v-text-field
                                v-model="credit"
                                :maxLength="50"
                                :error-messages="minusError ? $t('ui.wallet.balanceError') : errors"
                                :label="$t('ui.wallet.quantity')"
                                @keypress="creditControl($event)"
                            ></v-text-field>
                        </ValidationProvider>
                    </ValidationObserver>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
                    <v-btn class="mx-1" color="primary" :loading="loading" @click="loadBalance()">
                        {{$t('ui.wallet.load')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        props: ['value', 'wallet', 'userInfo', 'parentWallet'],
        data() {
            return {
                loading: false,
                selectionCredit: null,
                creditFlag: false,
                minusError: false,
                credit: null,
                credits: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            };
        },

        methods: {
            close() {
                this.clear();
                this.$emit("close", true);
            },

            async loadBalance() {
                this.loading = true;
                if (await this.$refs.creditForm.validate() && this.creditFlag) {
                    try {
                        await this.$http.patch(`/wallets/${this.wallet.pid}`, {balance: this.credit});
                        this.$snotify.success(this.$t('ui.wallet.loadSuccess'));
                        this.clear();
                        this.$emit("refresh", true);
                    } catch (error) {
                        this.loading = false;
                        this.$error.notify(error)
                    }
                } else {
                    this.loading = false;
                    if (await this.$refs.creditForm.validate()) {
                        this.$snotify.warning(this.$t('ui.general.notBigger'))
                    } else {
                        this.$snotify.warning(this.$t('ui.general.snotify.warning'))
                    }
                }
            },

            async creditControl(event) {

                let charCode = (event.which) ? event.which : event.keyCode;

                this.minusError = (!this.credit && charCode === 45);

                // if (parseInt(this.credit) >= parseInt(this.parentWallet.balance)) {
                //     this.creditFlag = false;
                //     event.preventDefault();
                // } else {
                    this.creditFlag = true;
                    if ((charCode < 48 || charCode > 57)) {
                        event.preventDefault();
                    } else {
                        return true
                    }
                // }
            },

            async clear() {
                this.loading = false;
                this.credit = null;
                this.selectionCredit = null;
                await this.$refs.creditForm.reset();
            },
        },

        mounted() {

        },

    };
</script>
}
