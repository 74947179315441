<template>
    <v-container fluid class="app-container">
        <app-title-bar
                :title="$t('ui.sidebar.feedbacks')">
        </app-title-bar>
        <v-card class="mt-6">
            <v-row no-gutters>
                <v-col>
                    <v-data-table :headers="headers"
                                  :items="feedbackList"
                                  item-key="pid"
                                  :page.sync="query.pagination.currentPage"
                                  :items-per-page.sync="query.pagination.limit"
                                  :footer-props="tableFooterProps"
                                  :sort-by.sync="query.sort.field"
                                  :sort-desc.sync="query.sort.order"
                                  :server-items-length="query.pagination.totalCount"
                                  @click:row="feedbackDetail"
                                  class="app-list b-r-3">
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <template v-slot:item.createdAt="{ item }">
                            {{new Date(item.createdAt).toLocaleString()}}
                        </template>
                      <template v-slot:item.key="{ item }">
                        <v-btn width="125px"
                               :color="item.key === 'BUG_FIX'? `error` : item.key === 'FEATURE' ? `info` : `success`"
                        ><span v-if="item.key === 'BUG_FIX'">{{$t('ui.feedback.bugFix')}}</span>
                          <span v-if="item.key === 'FEATURE' ">{{$t('ui.feedback.feature')}}</span>
                          <span v-if="item.key === 'IMPROVEMENT' ">{{$t('ui.feedback.improvement')}}</span>
                        </v-btn>
                      </template>

                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="feedbackDetail(item)">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.detail')}}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <feedback-detail :feedback="selectedFeedback" v-model="feedbackDetailDialog" @close="close"></feedback-detail>
        </v-card>
    </v-container>
</template>

<script>

    import Pagination from "@/modules/core/mixins/pagination.js";
    import FeedbackDetail from "@/modules/feedback/components/dialogs/FeedbackDetail";
    import {store} from "ekx-vue-base";

    export default {
        components: {
          FeedbackDetail
        },
        mixins: [
            new Pagination({
                methodName: "getFeedbackList",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    name: "=lk="
                },
                searchMirror: {
                    name: {
                        path: "nameSearch"
                    }
                }
            })
        ],
        data() {
            return {
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                feedbackDetailDialog:false,
                feedbackList: [],
                selectedFeedback: null,
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                headers: [
                    {
                        text: this.$t('ui.feedback.userName'),
                        value: 'user.username',
                        align: 'start',
                        width: "300",
                        sortable: false
                    },
                    {
                        text: this.$t('ui.feedback.subject'),
                        value: 'key',
                        width: "300",
                    },
                    {
                        text: this.$t('ui.feedback.date'),
                        value: 'createdAt',
                        width: "300",
                    },
                    {text: this.$t('ui.general.header.actionText'), sortable:false, value: 'actions', width: '10'}
                ],

                navBarItems: [
                    {
                        text: this.$t('ui.feedback.feedbackList'),
                        disabled: true
                    }
                ]

            };
        },
        watch: {
            'query.sort.order': function (value) {
              if (typeof value === 'boolean') {
                this.query.sort.order = value ? 'asc' : 'desc'
              } else if (typeof value === 'object') {
                this.query.sort.order = value[0] ? 'asc' : 'desc'
              }
            }
        },
        methods: {
            async getFeedbackList() {
              let query = this.createQuery();
                try {
                    let response = await this.$http.get(`${this.urlPrefix}/feedbacks?${query}`);
                    this.feedbackList = response.data.items;
                    this.query.pagination = response.data.meta.pagination;
                } catch (error) {
                    this.$error.notify(error)
                }
            },
            feedbackDetail(item) {
                this.selectedFeedback = item;
                this.feedbackDetailDialog = true;
            },
            close(){
              this.feedbackDetailDialog = false;
            }
        },
        mounted() {
          this.query.pagination.limit = this.$store.state.core.pageCount;
            this.getFeedbackList();
        },
    }
    ;
</script>

<style scoped>
</style>