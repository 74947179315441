<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-row justify="center">
        <v-dialog v-model="value" max-width="600px" :persistent="true">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title>{{$t('ui.user.dialog.createSubUser')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-container class="pa-0">
                    <v-col class="pa-6">
                        <ValidationObserver ref="createUserForm">
                            <ValidationProvider rules="required|min:3|max:50" v-slot="{ errors }">
                                <v-text-field
                                        :error-messages="errors "
                                        :maxLength="50"
                                        v-model="name"
                                        :label="$t('ui.user.name')"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider rules="required|min:3|max:50" v-slot="{ errors }">
                                <v-text-field
                                        :error-messages="errors "
                                        :maxLength="50"
                                        v-model="surname"
                                        :label="$t('ui.user.surname')"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider rules="required|min:3|max:50" v-slot="{ errors }">
                                <v-text-field
                                        :error-messages=" errorMessage ? errorMessage : errors "
                                        :maxLength="50"
                                        v-model="username"
                                        :label="$t('ui.user.username')"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider rules="required|min:3|max:50|email" v-slot="{ errors }">
                                <v-text-field
                                        :maxLength="50"
                                        :error-messages=" errorMailMessage ? errorMailMessage : errors "
                                        v-model="email"
                                        :loading="textLoading"
                                        :label="$t('ui.user.email')"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider rules="required|min:5|max:64" v-slot="{ errors }">
                                <v-text-field
                                        :error-messages="errors"
                                        :maxLength="64"
                                        v-model="password"
                                        :rules="password ? passwordRules : false"
                                        :type="show ? 'text' : 'password'"
                                        name="input-10-2"
                                        :label="$t('ui.user.password')"
                                        class="input-group--focused"
                                >
                                    <template slot="append-outer">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{on}">
                                                <v-icon @click="show = !show" v-on="on"> {{show ? 'mdi-eye' :
                                                    'mdi-eye-off'}}
                                                </v-icon>
                                            </template>
                                            <span>{{show ? $t('ui.general.show') : $t('ui.general.dontShow') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                            <v-row no-gutters class="mt-2">
                                <v-col>
                                    <v-alert text outlined color="warning">
                                        {{ $t('ui.wallet.permissionWallet') }}
                                    </v-alert>
                                    <v-autocomplete
                                            v-model="selectedWallets"
                                            :items="walletList"
                                            :search-input.sync="searchText"
                                            :error-messages="errors"
                                            :loading="searching"
                                            :label="$t('ui.wallet.header')"
                                            chips
                                            deletable-chips
                                            item-text="name"
                                            no-filter
                                            return-object
                                            multiple
                                            hide-no-data
                                    >
                                        <template v-slot:item="{ item }">
                                            <v-list-item-avatar>
                                                <v-avatar color="success">
                                                    <v-icon color="white">mdi-wallet-outline
                                                    </v-icon>
                                                </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ item.balance }} TL
                                                </v-list-item-subtitle>

                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </ValidationObserver>
                    </v-col>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" color="warning" @click="close()"> {{$t('ui.general.cancel')}}</v-btn>
                    <v-btn class="mx-1" color="primary" :loading="saveButtonLoading" @click="createUser()">
                        {{$t('ui.general.create')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";

    export default {
        props: ['value'],
        data() {
            return {
                userInfo: this.$store.getters['core/getUserInfo'],
                name: null,
                email: null,
                password: null,
                surname: null,
                username: null,
                saveButtonLoading: false,
                textLoading: false,
                show: false,
                walletList: [],
                searchText: "",
                selectedWallets: [],
                errorMessage: null,
                errorMailMessage: null,
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                passwordRules: [
                    v => (v && v.length >= 5) || this.$t('ui.user.passwordRules.min'),
                    v => (v && v.length <= 64) || this.$t('ui.user.passwordRules.max'),
                    v => /(?=.*[A-Z])/.test(v) || this.$t('ui.user.passwordRules.uppercase'),
                    v => /(?=.*[a-z])/.test(v) || this.$t('ui.user.passwordRules.lowercase'),
                    v => /(?=.*\d)/.test(v) || this.$t('ui.user.passwordRules.number'),
                    v => /([!@$%*./-])/.test(v) || this.$t('ui.user.passwordRules.special')
                ]
            };
        },

        created() {
            EkxDebounce.watch(this, "username", 500, async () => {
                this.errorMessage = null;
                if (this.username && this.username.length > 2) {
                    let available = await this.isUsernameAvailable(this.username);
                    if (available === true) {
                        this.errorMessage = null;
                    } else {
                        this.errorMessage = this.$t('ui.general.snotify.usernameError');
                    }
                }

            });
            EkxDebounce.watch(this, "email", 500, async () => {
                this.errorMailMessage = null;
                if (this.email && this.email.length > 2) {
                    let available = await this.isMailAvailable(this.email);
                    if (available === true) {
                        this.errorMailMessage = null;
                    } else {
                        this.errorMailMessage = this.$t('ui.general.snotify.emailAlreadyExists');
                    }
                }

            });
            EkxDebounce.watch(this, 'searchText', 500, () => {
                this.getWalletList(this.searchText);
            });

        },
        watch: {
            email: function (val) {
                if (val) {
                    this.loading = true
                }
            },

            'selected': {
                handler() {
                    this.searchText = "";
                }
            }
        },
        methods: {
            async close() {
                this.name = null;
                this.password = null;
                this.email = null;
                this.surname = null;
                this.username = null;
                this.saveButtonLoading = false;
                this.textLoading = false;
                this.walletList = [];
                this.searchText = "";
                this.selectedWallets = [];
                this.errorMessage = null;
                await this.$refs.createUserForm.reset();
                this.$emit("close", false);
            },

            async createUser() {
                if (await this.$refs.createUserForm.validate() && this.errorMessage === null) {
                    this.saveButtonLoading = true;
                    let body = {
                        user: {
                            password: this.password,
                            username: this.username,
                            user_details: {
                                name: this.name,
                                surname: this.surname,
                                email: this.email
                            }
                        },
                        walletPidList: this.selectedWallets.map(item => item.pid)
                    };
                    try {
                        await this.$http
                            .post(`${this.urlPrefix}/users`, body);
                        this.$snotify.success(this.name + " " + this.$t('ui.general.snotify.createSubUser'));
                        this.saveButtonLoading = false;
                        this.$emit("refresh");
                        this.close();
                    } catch (error) {
                        this.$error.notify(error)
                    } finally {
                        this.saveButtonLoading = false;
                    }
                } else {
                    if (!await this.$refs.createUserForm.validate()) {
                        this.$snotify.warning(this.$t('ui.general.snotify.warning'))
                    } else {
                        this.$snotify.warning(this.errorMessage)
                    }
                }
            },

            async getWalletList(searchText) {
                try {
                    let response = await this.$http.get(`${this.urlPrefix}/users/${this.userInfo.pid}/wallets?only-subWallets=true&limit=100&q=${(searchText ? "name==" + encodeURIComponent(searchText) : "")}`);
                    this.walletList = response.data.items;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async isMailAvailable(mail) {
                try {
                    let result = await this.$http
                        .get(`${this.urlPrefix}/users/${mail}/isAvailable?email=true`);
                    this.loading = false;
                    return !result.data;
                } catch (error) {
                    this.$error.notify(error)
                }
            },
            async isUsernameAvailable(username) {
                try {
                    let result = await this.$http
                        .get(`${this.urlPrefix}/users/${username}/isAvailable?username=true`);
                    this.loading = false;
                    return !result.data;
                } catch (error) {
                    this.$error.notify(error)
                }
            }
        },
        mounted() {
            this.getWalletList();
        }
    };
</script>
}