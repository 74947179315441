<template>
    <v-container fluid class="app-container">
        <app-title-bar
                :title="page.title" :items="page.items">
        </app-title-bar>

        <v-card>
            <v-row no-gutters v-if="urlPrefix!=='/admin' || this.$route.name === 'adminCodeLog' ">
                <v-col>
                    <v-data-table
                            :headers="headers"
                            :items="logList"
                            :page.sync="query.pagination.currentPage"
                            :items-per-page.sync="query.pagination.limit"
                            :footer-props="tableFooterProps"
                            :server-items-length="query.pagination.totalCount"
                            disable-sort
                            class="app-list"
                    >
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <template v-slot:item.key="{ item }">
                            <span>{{$t('ui.log.'+ item.key , {from: item.data.from , to: item.data.to, request_name:item.data.request_name,
                                balance:item.data.balance, walletName:item.data.wallet_name , api_key: item.data.api_key, price: item.data.price, code:item.data.code, amount: item.data.amount})}}</span>
                        </template>
                        <template v-slot:item.createdAt="{ item }">
                            {{new Date(item.createdAt).toLocaleString()}}
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row no-gutters v-else>
                <v-col>
                    <v-tabs grow v-model="tabs">
                        <v-tab>
                            <span class="font-weight-bold">{{$t('ui.sidebar.walletLogs')}}</span>
                        </v-tab>
                        <v-tab>
                            <span class="font-weight-bold">{{$t('ui.sidebar.calculationLogs')}}</span>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabs" class="base">
                        <v-tab-item>
                            <v-data-table
                                    :headers="headers"
                                    :items="logList"
                                    :page.sync="query.pagination.currentPage"
                                    :items-per-page.sync="query.pagination.limit"
                                    :footer-props="tableFooterProps"
                                    :server-items-length="query.pagination.totalCount"
                                    class="app-list"
                            >
                                <template slot="no-data">
                                    {{$t('ui.dataTable.noData')}}
                                </template>
                                <template v-slot:item.key="{ item }">
                            <span>{{$t('ui.log.'+ item.key , {from: item.data.from , to: item.data.to, request_name:item.data.request_name,
                                balance:item.data.balance, walletName:item.data.walletName, api_key: item.data.api_key, price: item.data.price})}}</span>
                                </template>
                                <template v-slot:item.createdAt="{ item }">
                                    {{new Date(item.createdAt).toLocaleString()}}
                                </template>
                            </v-data-table>
                        </v-tab-item>
                        <v-tab-item>
                            <v-col>
                                <v-data-table
                                        :headers="headers"
                                        :items="logList"
                                        :page.sync="query.pagination.currentPage"
                                        :items-per-page.sync="query.pagination.limit"
                                        :footer-props="tableFooterProps"
                                        :server-items-length="query.pagination.totalCount"
                                        class="app-list"
                                >
                                    <template slot="no-data">
                                        {{$t('ui.dataTable.noData')}}
                                    </template>
                                    <template v-slot:item.key="{ item }">
                            <span>{{$t('ui.log.'+ item.key , {from: item.data.from , to: item.data.to, request_name:item.data.request_name,
                                balance:item.data.balance, walletName:item.data.walletName, api_key: item.data.api_key, price: item.data.price})}}</span>
                                    </template>
                                    <template v-slot:item.createdAt="{ item }">
                                        {{new Date(item.createdAt).toLocaleString()}}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-card>
        <create-sub-user-dialog @refresh="refresh()" @close="close()"
                                :createSubUserDialog="createSubUserDialog"></create-sub-user-dialog>
        <delete-item-dialog
                v-model="deleteItemDialogStatus"
                :loading="deleteItemDialogLoading"
                :title="$t('ui.user.delete')"
                :text="$t('ui.user.deleteText')"
                @delete="deleteUser">
        </delete-item-dialog>
    </v-container>
</template>

<script>
    import Pagination from "@/modules/core/mixins/pagination";
    import CreateSubUserDialog from "@/modules/user/components/dialogs/CreateSubUserDialog";
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import {store} from "ekx-vue-base";

    export default {
        components: {
            CreateSubUserDialog,
            DeleteItemDialog
        },
        mixins: [
            new Pagination({
                methodName: "getLogList",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    name: "=lk="
                },
                searchMirror: {
                    name: {
                        path: "search"
                    }
                }
            })
        ],
        data() {
            return {
                page: {
                    title: this.$route.meta.permission === 'admin' ? this.$t('ui.sidebar.logs') :
                        (this.$route.name === 'userWalletLog' ? this.$t('ui.sidebar.walletLogs')
                            : this.$t('ui.sidebar.calculationLogs')),
                    items: [
                        {
                            text: (this.$route.meta && this.$route.meta.permission === 'admin' && this.$route.name !== 'adminCodeLog') ? this.$t('ui.user.userList') : (this.$route.meta && this.$route.meta.permission === 'admin' && this.$route.name === 'adminCodeLog') ? null : this.$t('ui.sidebar.logs'),
                            exact: !!(this.$route.meta && this.$route.meta.permission === 'admin'),
                            to: (this.$route.meta && this.$route.meta.permission === 'admin') ? '/admin/users' : null
                        },
                    ]
                },
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                logList: [],
                createSubUserDialog: false,
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,
                selectedUser: null,
                adminType: null,
                tabs: null,
                userPid: "",
                //pagination
                searchLabel: this.$t('ui.searchBar.user'),
                //sort
                sortField: "",
                sortOrder: "",
                //search
                nameSearch: "",
                keyList: [{value: "createdAt", name: this.$t('ui.general.createdAt')}],
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                headers: [
                    {
                        text: this.$t('ui.log.user_name'),
                        value: "user.username",
                        align: "start"
                    },
                    {
                        text: this.$t('ui.log.code'),
                        value: "data.code",
                        align: this.$route.name === 'adminCodeLog' ?  null : ' d-none'
                    },
                    {
                        text: this.$t('ui.log.description'),
                        value: "key"
                    },
                    {
                        text: this.$t('ui.log.apiKey'),
                        value: "data.apiKey",
                        align: this.$route.name === 'userQueryLog' ?  null : ' d-none'
                    },

                    {
                        text: this.$t('ui.general.createdAt'),
                        value: "createdAt"
                    },
                ]
            };
        },

        methods: {
            async getLogList() {
                let query = this.createQuery();
                try {
                    let type = this.adminType ? this.adminType : this.$route.name === 'userWalletLog' ? 'WALLET' : this.$route.name === 'userQueryLog' ?  'TASK' : 'CODE';
                    let response = this.$route.name  === 'adminCodeLog' ? await this.$http
                        .get(`/admin/logs?${query}&type=${type}`) : await this.$http
                        .get(`${this.urlPrefix}/logs/${this.userPid}?${query}&type=${type}`);
                    this.logList = response.data.items;
                    this.query.pagination = response.data.meta.pagination
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            cleanAllFilters() {
                this.nameSearch = "";
                this.query.sort.field = "";
                this.getLogList()
            },
            refresh() {
                this.getLogList();
            },

        },
        watch: {
            tabs: async function (val) {
                this.adminType = val === 0 ? "WALLET" : "TASK";
                await this.getLogList();
            },
            "$route.name": function (val) {
                this.page.title = val === 'userWalletLog' ? this.$t('ui.log.walletLogList')
                    : this.$t('ui.log.calculationLogList');
                if (val !== 'userQueryLog') {
                    this.headers[3].align = ' d-none';
                } else {
                    this.headers[3].align = null;
                }
                this.page.items.pop();
                this.page.items.push({
                    text: val === 'userWalletLog' ? this.$t('ui.sidebar.walletLogs') : this.$t('ui.log.calculationLogList'),
                    exact: false,
                });
            }
        },
        mounted() {
            if (this.urlPrefix === '/admin' && this.$route.name !== 'adminCodeLog') {
                this.page.items.push({
                    text: this.$t('ui.user.userDetail'),
                    exact: true,
                    to: `/admin/users/${this.$route.params.userPid}`
                });
            }
else if(this.urlPrefix !== '/admin') {
                this.page.items.push({
                    text: this.$route.name === 'userWalletLog' ? this.$t('ui.sidebar.walletLogs') : this.$t('ui.log.calculationLogList'),
                    exact: false,
                });
            }
            this.userPid = this.urlPrefix === '/admin'  ? this.$route.params.userPid : this.$store.getters["core/getUserInfo"].pid;
            this.query.pagination.limit = this.$store.state.core.pageCount;
            this.getLogList();
        },
    };
</script>
