<template>
    <v-row justify="end">
        <v-dialog v-model="value" persistent max-width="600px">
            <v-card>
                <v-toolbar color="primary" dark class="elevation-1">
                    <v-toolbar-title>{{$t('ui.card.create')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-container class="pa-0">
                    <v-col class="pa-6">
                        <ValidationObserver ref="createPaymentSourceForm">
                            <ValidationProvider rules="required|min:4|whiteSpace|max:50" v-slot="{ errors }">
                                <v-text-field v-model="cardOwnerName"
                                              :maxLength="50"
                                              :error-messages="errors"
                                              :label="$t('ui.card.dialog.ownerName')"
                                              :loading="textLoading"></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider rules="required|min:4|whiteSpace|max:50" v-slot="{ errors }">
                                <v-text-field v-model="cardName"
                                              :error-messages="errors"
                                              :maxLength="50"
                                              :label="$t('ui.card.dialog.name')"
                                              :loading="textLoading"></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider rules="required|cardNumber" v-slot="{ errors }">
                                <v-text-field v-model="cardNumber"
                                              :maxLength="14"
                                              :error-messages="errors"
                                              @keypress="cardNumberControl($event)"
                                              max="14"
                                              :label="$t('ui.card.dialog.number')"></v-text-field>
                            </ValidationProvider>
                            <v-row>
                                <v-col>
                                    <ValidationProvider rules="required|min:3|whiteSpace|max:3|integer|"
                                                        v-slot="{ errors }">
                                        <v-text-field v-model="cvv"
                                                      :maxLength="3"
                                                      :error-messages="errors"
                                                      type="number"
                                                      @keypress="isGreaterThanThree($event)"
                                                      :label="$t('ui.card.dialog.cvv')"></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider rules="required"
                                                        v-slot="{ errors }">
                                        <v-text-field v-model="expirationDate"
                                                      :error-messages="errors"
                                                      :error="dateError ? true : false"
                                                      @keypress="cardDateControl($event)"
                                                      max="7"
                                                      :label="$t('ui.card.dialog.expirationDate')"></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </ValidationObserver>
                    </v-col>
                    <v-divider></v-divider>
                    <v-col>
                        <v-row justify="end" no-gutters>
                            <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
                            <v-btn class="mx-1" color="primary" :loading="loading" @click="createPaymentSource()">
                                {{$t('ui.general.save')}}
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>

    export default {
        props: ['value'],

        data() {
            return {
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                userInfo: this.$store.getters["core/getUserInfo"],
                cardOwnerName: null,
                cardName: null,
                cardNumber: "",
                cvv: null,
                expirationDate: "",
                errorMessage: null,
                loading: false,
                date: null,
                textLoading: false,
                dateError: null
            };
        },

        methods: {
            async close() {
                this.clear();
                this.$emit("close", false);
            },

            async createPaymentSource() {
                if (await this.$refs.createPaymentSourceForm.validate() && this.expirationDateControl(this.expirationDate)) {
                    this.loading = true;
                    let body = {
                        customerId: this.userInfo.customerId,
                        userPid: this.userInfo.pid,
                        card: {
                            cvv: this.cvv,
                            expiry_month: this.expirationDate.substring(0, 2),
                            expiry_year: this.expirationDate.substring(3, 7),
                            fullname: this.cardOwnerName,
                            number: this.cardNumber,
                            alias: this.cardName
                        }
                    };
                    
                    try {
                        let result = await this.$http.post(`${this.urlPrefix}/payment-sources`, body);
                        if(this.userInfo.cardId===null){
                            this.$store.commit("core/setCardId", result.data.id);
                        }
                        this.$snotify.success(this.cardName + ' ' + this.$t('ui.general.snotify.success'));
                        this.$emit("refresh");
                        this.close()
                    } catch (error) {
                        this.loading = false;
                        this.$error.notify(error);
                    }
                } else {
                    this.loading = false;
                    if (!this.expirationDateControl(this.expirationDate)) {
                        this.$snotify.warning(this.$t('ui.card.validDateWarning'));
                    } else {
                        this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                    }
                }
            },

            expirationDateControl(date) {
                let month = parseInt(date.substring(0, 2));
                let year = parseInt(date.substring(3, 7));
                if ((year === (new Date()).getFullYear() && month < 13 && month > (new Date()).getMonth()) || (month > 0 && month < 13 && year < 2099 && year > (new Date()).getFullYear())) {
                    this.dateError = null;
                    return true;
                } else {
                    this.dateError = true;
                    return false
                }
            },

            isGreaterThanThree(event) {
                if (this.cvv && this.cvv.length === 3) {
                    event.preventDefault();
                } else {
                    return true
                }
            },

            cardNumberControl(event) {

                let charCode = (event.which) ? event.which : event.keyCode;

                if (this.cardNumber.length === 19) {
                    event.preventDefault();
                } else {
                    if (this.cardNumber.length === 4) {
                        this.cardNumber += String.fromCharCode(32);
                    }
                    if (this.cardNumber.length === 9) {
                        this.cardNumber += String.fromCharCode(32);
                    }
                    if (this.cardNumber.length === 14) {
                        this.cardNumber += String.fromCharCode(32);
                    }
                    if ((charCode < 48 || charCode > 57) && charCode !== 32) {
                        event.preventDefault();
                    } else {
                        return true
                    }
                }
            },

            cardDateControl(event) {
                let charCode = (event.which) ? event.which : event.keyCode;

                if (this.expirationDate.length === 7) {
                    event.preventDefault();
                } else {
                    if (this.expirationDate.length === 2) {
                        this.expirationDate += String.fromCharCode(47);
                    }
                    if ((charCode < 48 || charCode > 57) && charCode !== 47) {
                        event.preventDefault();
                    } else {
                        return true
                    }
                }
            },

            async clear() {
                this.cvv = null;
                this.expirationDate = "";
                this.cardNumber = "";
                this.cardOwnerName = null;
                this.cardName = null;
                this.loading = false;
                await this.$refs.createPaymentSourceForm.reset();
            },

            formatDate(date) {
                if (!date) return null;
                const [year, month] = date.split('-');
                return `${month}/${year}`
            },
        }
    };
</script>

