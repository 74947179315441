<!---->


<!-- Side Structure -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-navigation-drawer
            app
            fixed
            v-model="sidebar"
            :width="250"
            dark
            class="app-sidebar"
    >

        <v-row no-gutters class="pa-4" align="center">
            <img src="@/assets/rhoova_logo_white_179x32.png" style="height:32px;width:230px" alt="icon">
        </v-row>

        <v-list shaped>
            <template v-for="item in menuItems">
                <template v-if="item.items != null">
                    <v-list-group
                            :key="item.title"
                            v-if="item.visible"
                            v-model="item.active"
                            :prepend-icon="item.icon"
                            :ripple="true"
                            no-action
                    >
                        <template v-slot:activator="">
                            <v-list-item-content>
                                <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                                v-for="child in item.items"
                                :key="child.title"
                                :to="child.path"
                                :ripple="false">
                            <v-list-item-content>
                                <v-list-item-title v-text="$t(child.title)"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </template>
                <template v-else>
                    <v-list-item-group
                            :key="item.title"
                            v-if="item.visible"
                            v-model="item.active"
                            :ripple="false"
                    >
                        <v-list-item
                                :to="item.path"
                                :ripple="false">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </template>
            </template>

        </v-list>
        <div class="navigation-bottom-bar">
            <div style="position:absolute;bottom:0;right:16px">
                <span class="white--text"> {{ $t('ui.general.version') }} : {{ $store.state.core.app.version }}</span>
            </div>
        </div>

    </v-navigation-drawer>

</template>

<script>
    export default {
        props: ['sidebar'],
        computed: {
            isSystemAdmin() {
                return this.$store.getters['core/getUserInfo'].isSystemAdmin
            },
            isParentUser(){
                return this.$store.getters['core/getUserInfo'].parent
            }
        },
        data() {
            return {
                menuItems: []
            }
        },

        mounted(){
            this.menuItems.forEach((item, index) => {
                if(item.visible){
                    if (item.path === this.$route.path) {
                        this.menuItems[index].active = true
                    } else if (item.items) {
                        let flag = false;
                        item.items.forEach(subItem=>{
                            if(subItem.path===this.$route.path){
                                flag = true;
                            }
                        });
                        this.menuItems[index].active = flag;
                    } else {
                        this.menuItems[index].active = false
                    }
                }

            });
        },
        methods: {
            textTruncate(str, length, ending) {
                if (length == null) {
                    length = 100;
                }
                if (ending == null) {
                    ending = '...';
                }
                if (str.length > length) {
                    return str.substring(0, length - ending.length) + ending;
                } else {
                    return str;
                }
            },
        },

        watch: {
            '$route': {
                handler() {
                    this.menuItems.forEach((item, index) => {
                        if(item.visible){
                            if (item.path === this.$route.path) {
                                this.menuItems[index].active = true
                            } else if (item.items) {
                                let flag = false;
                                item.items.forEach(subItem=>{
                                    if(subItem.path===this.$route.path){
                                        flag = true;
                                    }
                                });
                                if(flag){
                                    this.menuItems[index].active = true
                                }else{
                                    this.menuItems[index].active = false
                                }
                            } else {
                                this.menuItems[index].active = false
                            }
                        }

                    });
                }
            },
        },

        created() {
            this.menuItems = [{
                icon: 'mdi-view-dashboard',
                title: 'ui.sidebar.dashboard',
                active: true,
                path: "/",
                visible: true,
            }, {
                icon: 'person',
                title: 'ui.sidebar.users',
                active: !this.isParentUser,
                path: this.isSystemAdmin ? "/admin/users" : "/users",
                visible: !this.isParentUser
            }, {
                icon: 'mdi-wallet',
                title: 'ui.wallet.header',
                active: false,
                visible: !this.isSystemAdmin,
                path: '/wallets'
            }, {
                icon: 'question_answer',
                title: 'ui.calculation.header',
                active: false,
                visible: !this.isSystemAdmin,
                path: '/calculations'
            }, {
                icon: 'storage',
                title: "ui.sidebar.apiKeys",
                active: false,
                path: "/api-keys",
                visible: !this.isSystemAdmin,
            }, {
                icon: 'description',
                title: "ui.sidebar.requests",
                active: false,
                path: "/admin/requests",
                visible: this.isSystemAdmin
            }, {
                icon: 'feedback',
                title: 'ui.sidebar.feedbacks',
                active: false,
                path: "/admin/feedbacks",
                visible: this.isSystemAdmin
            }, {
                icon: 'code',
                title: 'ui.sidebar.codes',
                active: false,
                path: "/admin/codes",
                visible: this.isSystemAdmin
            }, {
                icon: 'data_usage',
                title: 'ui.sidebar.dataSource',
                active: false,
                path: "/data-sources",
                visible: !this.isSystemAdmin
            }, {
                icon: 'data_usage',
                title: 'ui.sidebar.openSource',
                active: false,
                path: "/open-sources",
                visible: !this.isSystemAdmin
            }, {
                icon: 'data_usage',
                title: 'ui.sidebar.openSourceManagement',
                active: false,
                path: "/admin/open-source-management",
                visible: this.isSystemAdmin
            }, {
                    icon: 'history',
                    title: 'ui.sidebar.logs',
                    active: false,
                    visible: !this.isSystemAdmin && !this.isParentUser,
                    items: [
                        {title: 'ui.sidebar.walletLogs', path: '/logs-wallet'},
                        {title: 'ui.sidebar.calculationLogs', path: '/logs-query'}
                    ]
                }, {
                icon: 'history',
                title: 'ui.sidebar.logs',
                active: false,
                path: "/admin/logs-code",
                visible: this.isSystemAdmin,
            },
            ]
        },

    };
</script>
