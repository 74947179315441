//

import {Line, mixins} from 'vue-chartjs'

let LineChart = {
    extends: Line,
    mixins: [mixins.reactiveProp],
    props: ['options'],
    watch: {
    },
    mounted() {
        this.renderChart(this.chartData, this.options)
    }
};

export {
    LineChart
}