module.exports = {
    http: {
        baseURL: process.env.VUE_APP_SERVER_URL
    },
    vuetify: {
        theme: {
            dark: true,
            themes: {
                dark: {
                    primary: "#5D92F4",
                    secondary: "#82b1ff",
                    success: "#00D014",
                    info: "#00D0BD",
                    warning: "#FFB70F",
                    danger: "#FF3739",
                },
                light: {
                    primary: "#5D92F4",
                    secondary: "#82b1ff",
                    success: "#00D014",
                    info: "#00D0BD",
                    warning: "#FFB70F",
                    danger: "#FF3739",
                }
            }
        },
        lang: {
            t: (key, ...params) => {
                const {i18n} = require("ekx-vue-base");
                i18n.t(key, params)
            }
        }
    },
    lang: {
        localization: {
            locale: "en"
        },
        ignoreModuleNames: ["core"]
    }
};