var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-1 base primary",attrs:{"dark":""}},[(_vm.isPromotion)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('ui.code.create')))]):_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('ui.code.creditCreate')))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('ui.general.close'))+" ")])],1),_c('v-card-text',{staticClass:"mt-6"},[_c('ValidationObserver',{ref:"createOrEditCodeForm"},[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required|integer|min:1|max:20|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"min":"1","disabled":!!(_vm.selectedCode && _vm.isPromotion),"maxLength":20,"error-messages":errors,"label":_vm.$t('ui.code.amount'),"type":"number"},on:{"input":function($event){return _vm.typeControl(_vm.discount)},"keypress":function($event){return _vm.numberControl($event)}},model:{value:(_vm.discount.amount),callback:function ($$v) {_vm.$set(_vm.discount, "amount", $$v)},expression:"discount.amount"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[(_vm.isPromotion)?_c('ValidationProvider',{attrs:{"rules":"required|whiteSpace|min:3|max:20|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!!_vm.selectedCode,"maxLength":20,"error-messages":errors,"label":_vm.$t('ui.code.code'),"type":"text"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}],null,false,4007810512)}):_c('ValidationProvider',{attrs:{"rules":"required|whiteSpace|min:3|max:50|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!!_vm.selectedCode,"maxLength":50,"error-messages":errors,"label":_vm.$t('ui.code.code'),"type":"text"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}])})],1),_c('v-btn',{staticClass:"mx-1 mt-6",attrs:{"disabled":!!_vm.selectedCode,"color":"primary","loading":_vm.generateLoading},on:{"click":function($event){return _vm.generateCode()}}},[_vm._v(" "+_vm._s(_vm.$t('ui.general.generate'))+" ")])],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"integer|min:1|max:7|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"min":"1","max":"1000000","label":_vm.$t('ui.code.quantity'),"type":"number"},on:{"keypress":function($event){return _vm.numberControl($event)}},model:{value:(_vm.discount.quantity),callback:function ($$v) {_vm.$set(_vm.discount, "quantity", $$v)},expression:"discount.quantity"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[(!_vm.isPromotion)?_c('ValidationProvider',{attrs:{"rules":"integer|min:1|max:20|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!_vm.discount.quantity,"maxLength":20,"error-messages":errors,"min":"1","max":_vm.discount.quantity,"label":_vm.$t('ui.code.userQuantity'),"type":"number"},on:{"input":function($event){return _vm.userQuantityControl(_vm.discount)}},model:{value:(_vm.discount.userQuantity),callback:function ($$v) {_vm.$set(_vm.discount, "userQuantity", $$v)},expression:"discount.userQuantity"}})]}}],null,false,4149638344)}):_vm._e()],1)],1),(_vm.isPromotion)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"disabled":!!(_vm.selectedCode && _vm.isPromotion),"items":_vm.amountTypes,"label":_vm.$t('ui.code.amountType'),"return-object":""},on:{"input":function($event){return _vm.typeControl(_vm.discount)}},model:{value:(_vm.amountType),callback:function ($$v) {_vm.amountType=$$v},expression:"amountType"}})]}}],null,false,2371144871)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.date),"label":_vm.$t('ui.code.datePicker'),"prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.date = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"warning"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm.$t('ui.general.cancel'))+" ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" "+_vm._s(_vm.$t('ui.general.ok'))+" ")])],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('ui.general.cancel')))]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.createOrEditCode()}}},[_vm._v(" "+_vm._s(_vm.$t('ui.general.save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }