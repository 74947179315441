<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-row justify="center">
    <v-dialog v-model="value" max-width="600px">
        <v-card>
            <v-toolbar class="elevation-1 base primary" dark>
                <v-toolbar-title>{{$t('ui.wallet.create')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon @click="close()" v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    {{$t('ui.general.close')}}
                </v-tooltip>
            </v-toolbar>
            <v-card-text class="mt-6">
                <ValidationObserver ref="createWalletForm">
                    <ValidationProvider rules="required|whiteSpace|min:3|max:50|" v-slot="{ errors }">
                        <v-text-field v-model="name"
                                      :maxLength="50"
                                      :error-messages="errors"
                                      :label="$t('ui.wallet.subName')" type="text"></v-text-field>
                    </ValidationProvider>
                </ValidationObserver>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
                <v-btn class="mx-1" color="primary" :loading="loading" @click="loadBalance()">
                    {{$t('ui.general.save')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
        </v-row>
</template>

<script>
    export default {
        props: ['value', 'wallet', 'userInfo'],
        data() {
            return {
                loading: false,
                name: null,
            };
        },

        methods: {
            close() {
                this.clear();
                this.$emit("close", true);
            },

            async loadBalance() {
                this.loading = true;
                let walletBody = {
                    name: this.name
                };
                if (await this.$refs.createWalletForm.validate()) {
                    try {
                        await this.$http.post(`/wallets`, {wallet: walletBody, parentPid: this.wallet.pid});
                        this.$snotify.success(this.$t('ui.wallet.createSuccess'));
                        this.clear();
                        this.$emit("refresh", true);
                    } catch (error) {
                        this.loading = false;
                        this.$error.notify(error)
                    }
                }else {
                    this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                    this.loading = false
                }
            },

            async clear() {
                this.loading = false;
                this.name = null;
                await this.$refs.createWalletForm.reset();

            },
        },

        mounted() {

        },

    };
</script>
}
