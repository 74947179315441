<template>
    <v-container fluid class="app-container">
        <app-title-bar
                :title="page.title" :items="page.items">
        </app-title-bar>
        <v-card class="pt-4 mt-6">
            <v-col>
                <v-row no-gutters>
                    <v-col class="mr-4">
                        <v-select
                                v-model="selectedType"
                                :items="typeList"
                                :label="$t('ui.openSource.type')"
                                return-object
                        ></v-select>
                    </v-col>
                    <v-col class="mr-4">
                        <v-combobox v-model="selectedSource" :items="openSourceList" item-text="name"
                                    clearable :label="$t('ui.openSource.source')"
                                    :search-input.sync="search"
                                    hide-selected
                                    persistent-hint>
                        </v-combobox>
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="timeInterval"
                                :items="timeIntervals"
                                :label="$t('ui.general.timeInterval')"
                                return-object
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-row class="pa-0">
                <v-container fluid>
                    <v-data-table :headers="headers"
                                  :items="openDataList"
                                  item-key="name"
                                  :footer-props="tableFooterProps"
                                  :page.sync="query.pagination.currentPage"
                                  :items-per-page.sync="query.pagination.limit"
                                  :server-items-length="query.pagination.totalCount"
                                  class="app-list"
                                  @click:row="goSourceDetail">
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <template v-slot:item.createdAt="{ item }">
                            {{new Date(item.createdAt).toLocaleString()}}
                        </template>
                        <template v-slot:item.status="{ item }">
                            <span v-if="item.status===true">
                                <v-icon v-on="on" color="green">mdi-check</v-icon>
                            </span>
                            <span v-else>
                                 <v-icon v-on="on" color="red">mdi-close</v-icon>
                            </span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="getOpenSourceData(item)">
                                        <v-icon>cached</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.getOpenSourceData')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="goSourceDetail(item)">
                                        <v-icon>equalizer</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.detail')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="openDeleteItemDialog(item)">
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span> {{$t('ui.general.delete')}}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-container>
            </v-row>
        </v-card>
        <delete-item-dialog
                v-model="deleteItemDialogStatus"
                :loading="deleteItemDialogLoading"
                :title="$t('ui.openSource.delete')"
                :text="$t('ui.openSource.deleteText')"
                @delete="deleteDataSource">
        </delete-item-dialog>
        <get-open-source-data-dialog @refresh="refresh()" @close="close()"
                                     v-model="getOpenSourceDataDialog"
                                     :openSource="selectedData"></get-open-source-data-dialog>
    </v-container>
</template>

<script>

    import GetOpenSourceDataDialog from "@/modules/admin/components/dialogs/GetOpenSourceDataDialog"
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import Pagination from "@/modules/core/mixins/pagination.js";
    import {store} from "ekx-vue-base";

    export default {
        components: {
            DeleteItemDialog,
            GetOpenSourceDataDialog
        },
        mixins: [
            new Pagination({
                methodName: "getOpenSourceList",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    name: "=lk="
                },
                searchMirror: {
                    name: {
                        path: "nameSearch"
                    }
                }
            })
        ],
        data() {
            return {
                page: {
                    title: this.$t('ui.openSource.header'),
                },

                openDataList: [],

                //selectedData
                selectedData: null,
                selectedSource: 'markit',
                selectedType: null,

                //dataList
                openSourceList: ['markit'],
                timeInterval: {text: this.$t('ui.openSource.thisWeek'), value: 0},
                timeIntervals: [{text: this.$t('ui.openSource.thisWeek'), value: 0},
                    {text: this.$t('ui.openSource.thisMonth'), value: 1},
                    {text: this.$t('ui.general.all'), value: 2}],
                typeList: [
                    {text: this.$t('ui.openSource.allTypes'), value: null},
                    {text: "Yield Curve", value: 0},
                    {text: "Yield Data", value: 1},
                    {text: "Fixed Rate Bond Definition", value: 2},
                    {text: "Vanilla Option Definition", value: 3},
                    {text: "Prices For Vol Market Data", value: 4},
                    {text: "Floating Bond Definition", value: 5}],

                //dialog
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,
                getOpenSourceDataDialog: false,
                rowClick: false,

                headers: [
                    {
                        text: this.$t('ui.openSource.id'),
                        value: 'pid',
                        align: "start",
                        width: "330"
                    }, {
                        text: this.$t('ui.openSource.name'),
                        value: 'name',
                        width: '300'
                    }, {
                        text: this.$t('ui.openSource.type'),
                        value: 'type',
                        width: '250'
                    }, {
                        text: this.$t('ui.openSource.createdDate'),
                        value: 'createdAt',
                        width: '250'
                    }, {
                        text: this.$t('ui.openSource.status'),
                        value: 'status',
                        width: '200'
                    },

                    {text: this.$t('ui.general.header.actionText'), sortable:false, value: 'actions', width: "140"}
                ],
            };
        },

        watch: {
            timeInterval: function () {
                this.getOpenSourceList();
            },

            selectedSource: function () {
                this.getOpenSourceList();
            },

            selectedType: function () {
                this.getOpenSourceList();
            }
        },

        methods: {

            goSourceDetail(item) {
                if (this.rowClick) {
                    this.$router.push(`/admin/open-source-management/${item.pid}`).catch(() => {
                    });
                }
                this.rowClick = true;
            },

            async getOpenSourceList() {
                let type = this.selectedType ? this.selectedType.value : null;

                try {
                    let result = await this.$http.get(`/admin/openSources?timeInterval=${this.timeInterval.value}&source=${this.selectedSource}&type=${type}`);
                    result.data.items.forEach(async (item) => {
                        item.type = await this.getType(item.type);
                        item.status = !!item.data
                    });
                    this.openDataList = result.data.items;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            getType(type) {

                let dataType = {
                    0: "Yield Curve",
                    1: "Yield Data",
                    2: "Fixed Rate Bond Definition",
                    3: "Vanilla Option Definition",
                    4: "Prices For Vol Market Data",
                    5: "Floating Bond Definition",
                };
                return dataType[type];
            },

            openDeleteItemDialog(item) {
                this.rowClick = false;
                this.selectedData = item;
                this.deleteItemDialogStatus = true
            },

            getOpenSourceData(item) {
                this.rowClick = false;
                this.selectedData = item;
                this.getOpenSourceDataDialog = true
            },

            async deleteDataSource() {
                try {
                    this.deleteItemDialogLoading = true;
                    await this.$http.delete(`/admin/openSources/${this.selectedData.pid}`);
                    this.$snotify.success(this.$t('ui.openSource.deleteSuccess'));
                    this.getOpenSourceList();
                } catch (error) {
                    this.$error.notify(error);
                } finally {
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                }
            },

            refresh() {
                this.getOpenSourceDataDialog = false;
                this.rowClick = true;
                this.getOpenSourceList();
            },

            close() {
                this.getOpenSourceDataDialog = false;
            }

        },
        mounted() {
            this.getOpenSourceList()
        },
    };
</script>

<style scoped>
</style>