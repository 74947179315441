<template>
    <v-container fluid class="app-container">
        <app-title-bar
                :title="page.title" :items="page.items">
        </app-title-bar>

        <v-card class="mt-6" v-if="!createDataSourceDialog">
            <v-col>
                <v-row no-gutters align="center">
                    <v-col>
                        <v-btn large color="primary" class="white--text" @click="createDataSource()">
                            {{$t('ui.dataSource.create')}}
                        </v-btn>
                    </v-col>
                    <v-col class="mr-2">
                        <search-bar
                                :searchText.sync="nameSearch"
                                :searchLabel="searchLabel"
                                :noSort="true"
                                @cleanAllFilters="cleanAllFilters"
                                @toggleSortOrder="toggleSortOrder"
                                @refresh="getDataSourceList()"
                        ></search-bar>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row no-gutters>
                    <v-col class="mr-4">
                        <v-combobox
                                v-model="selectedType"
                                :items="typeList"
                                :label="$t('ui.dataSource.type')"
                                :search-input.sync="search"
                                return-object
                                persistent-hint
                        ></v-combobox>
                    </v-col>
                </v-row>
            </v-col>
            <v-row class="pa-0">
                <v-container fluid>
                    <v-data-table :headers="headers"
                                  :items="dataSourceList"
                                  item-key="name"
                                  :items-per-page.sync="limit"
                                  :footer-props="tableFooterProps"
                                  :server-items-length="totalCount"
                                  :page.sync="currentPage"
                                  :options.sync="tableOptions"
                                  class="app-list"
                                  @click:row="goDetail">
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <template v-slot:item.createdAt="{ item }">
                            {{new Date(item.createdAt).toLocaleString()}}
                        </template>
                        <template v-slot:item.pid="{ item }">
                            <v-row no-gutters class="show-on-hover-opacity" style="height:32px;width:350px">
                                <v-col class="mt-2">
                                    <simplebar class="simplebar-force-block" style="height:30px;overflow-y: hidden">
                                        <span style="white-space: nowrap" class="secret-text">{{item.pid}}</span>
                                    </simplebar>
                                </v-col>
                                <div style="width: 40px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon class="show-on-hover-slot" v-on="on"
                                                   @click="onCopyPid(item.pid)">
                                                <v-icon>
                                                    mdi-clipboard-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{$t('ui.dataSource.copyPid')}}</span>
                                    </v-tooltip>
                                </div>
                            </v-row>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="editDataName(item)">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <span> {{$t('ui.general.edit')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="goDetail(item)">
                                        <v-icon>equalizer</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.detail')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="openDeleteItemDialog(item)">
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span> {{$t('ui.general.delete')}}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-container>
            </v-row>
        </v-card>
        <create-data-source-page v-if="createDataSourceDialog" :typeList="dataSourceTypeList" @close="close"
                                 @refresh="refresh"></create-data-source-page>
        <delete-item-dialog
                v-model="deleteItemDialogStatus"
                :loading="deleteItemDialogLoading"
                :title="$t('ui.dataSource.delete')"
                :text="$t('ui.dataSource.deleteText')"
                @delete="deleteDataSource">
        </delete-item-dialog>
        <edit-data-name-dialog v-model="editDataNameDialog" :data="selectedData" @refresh="refresh"
                               @close="close"></edit-data-name-dialog>
    </v-container>
</template>

<script>

    import SearchBar from "@/modules/core/components/SearchBar";
    import CreateDataSourcePage from "@/modules/dataSource/components/CreateDataSourcePage";
    import EditDataNameDialog from "@/modules/dataSource/components/dialogs/EditDataNameDialog";
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import Pagination from "@/modules/core/mixins/pagination.js";
    import {store} from "ekx-vue-base";

    export default {
        components: {
            SearchBar,
            CreateDataSourcePage,
            DeleteItemDialog,
            EditDataNameDialog
        },
        mixins: [
            new Pagination({
                methodName: "getDataSourceList",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    name: "=lk="
                },
            })
        ],
        data() {
            return {
                page: {
                    title: this.$t('ui.dataSource.dataSource'),
                },
                dataSourceTypeList: null,
                typeList: [{
                    text: this.$t('ui.dataSource.allTypes'),
                    value: null
                }, {
                    text: 'Yield Curve',
                    value: 0
                }, {
                    text: 'Yield Data',
                    value: 1
                }, {
                    text: 'Fixed Rate Bond Definition',
                    value: 2
                }, {
                    text: 'Vanilla Option Definition',
                    value: 3
                }, {
                    text: 'Prices For Vol Market Data',
                    value: 4
                }, {
                    text: 'Floating Bond Definition',
                    value: 5
                }, {
                    text: 'Deposits',
                    value: 6
                }, {
                    text: 'ZCIIS Data',
                    value: 7
                }, {
                    text: 'Future Fixing Days',
                    value: 8
                }, {
                    text: 'Fix Data',
                    value: 9
                }
                ],
                selectedType: {text: this.$t('ui.dataSource.allTypes'), value: null},
                rowClick: true,
                userInfo: this.$store.getters["core/getUserInfo"],
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                dataSourceList: [],
                tableOptions: {},
                createDataSourceDialog: false,
                editDataNameDialog: false,
                selectedData: null,
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                //dialog
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,
                //search
                nameSearch: "",
                keyList: ["name"],
                limit: 10,
                totalCount: null,
                currentPage: 1,
                searchLabel: this.$t('ui.searchBar.dataSource'),
                headers: [
                    {
                        text: "id",
                        value: 'pid',
                        align: "start",
                        width: "350"
                    },
                    {
                        text: this.$t('ui.dataSource.name'),
                        value: 'name',
                        width: "200"
                    },
                    {
                        text: this.$t('ui.dataSource.userName'),
                        value: 'user.username',
                        width: "200",
                        sortable: false
                    },
                    {
                        text: this.$t('ui.dataSource.type'),
                        value: 'type',
                        width: "200"
                    },
                    {
                        text: this.$t('ui.general.createdAt'),
                        value: 'createdAt',
                        width: "200"
                    },
                    {text: this.$t('ui.general.action'), value: "actions", width: "120", sortable: false}
                ],

            };
        },
        watch: {
            selectedType: function () {
                this.getDataSourceList();
            },
            "tableOptions.sortBy"() {
                this.query.sort.field = this.tableOptions.sortBy[0];
                this.query.sort.order = this.tableOptions.sortDesc[0] ? "desc" : "asc"
            },
            limit: function (val) {
                this.limit = val;
                this.getDataSourceList();
            },
            currentPage: function (value) {
                this.currentPage = value;
                this.getDataSourceList();
            },
            nameSearch: function (value) {
                this.nameSearch = value;
                this.currentPage = 1;
                this.getDataSourceList();
            },
        },
        methods: {
            async getDataSourceList() {
                try {
                    let searchQuery = this.nameSearch ? "&q=name=lk=" + this.nameSearch : "";
                    let response = await this.$http.get(`${this.urlPrefix}/users/${this.userInfo.pid}/data-sources?type=${this.selectedType.value}&limit=${this.limit}&offset=${this.limit * (this.currentPage - 1)}${searchQuery}`);
                    this.dataSourceList = response.data.items;
                    this.dataSourceList.forEach(async (data) => {
                        data.type = await this.getType(data.type);
                    });
                    this.totalCount = response.data.meta.pagination.totalCount;
                } catch (error) {
                    this.$error.notify(error)
                }
            },
            getType(type) {

                let dataType = {
                    0: "Yield Curve",
                    1: "Yield Data",
                    2: "Fixed Rate Bond Definition",
                    3: "Vanilla Option Definition",
                    4: "Prices For Vol MarketData",
                    5: "Floating Bond Definition",
                    6: "Deposits",
                    7: "ZCIIS Data",
                    8: "Future Fixing Days",
                    9: "Fix Data",
                };

                return dataType[type];
            },

            createDataSource() {
                this.typeList = [{
                    text: this.$t('ui.dataSource.allTypes'),
                    value: null
                }, {
                    text: 'Yield Curve',
                    value: 0
                }, {
                    text: 'Yield Data',
                    value: 1
                }, {
                    text: 'Fixed Rate Bond Definition',
                    value: 2
                }, {
                    text: 'Vanilla Option Definition',
                    value: 3
                }, {
                    text: 'Prices For Vol Market Data',
                    value: 4
                }, {
                    text: 'Floating Bond Definition',
                    value: 5
                }, {
                    text: 'Deposits',
                    value: 6
                }, {
                    text: 'ZCIIS Data',
                    value: 7
                }, {
                    text: 'Future Fixing Days',
                    value: 8
                }, {
                    text: 'Fix Data',
                    value: 9
                }
                ];
                let tempTypeList = this.typeList;
                this.dataSourceTypeList = tempTypeList.splice(1, tempTypeList.length);
                this.createDataSourceDialog = true;
            },

            openDeleteItemDialog(item) {
                this.rowClick = false;
                this.selectedData = item;
                this.deleteItemDialogStatus = true
            },

            editDataName(item) {
                this.rowClick = false;
                this.selectedData = item;
                this.editDataNameDialog = true;
            },

            async deleteDataSource() {
                try {
                    this.deleteItemDialogLoading = true;
                    await this.$http.delete(`${this.urlPrefix}/data-sources/${this.selectedData.pid}`);
                    this.$snotify.success(this.$t('ui.dataSource.deleteSuccess'));
                    this.getDataSourceList();
                } catch (error) {
                    this.$error.notify(error);
                } finally {
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                }
            },
            onCopyPid: function (key) {
                this.rowClick = false;
                const el = document.createElement('textarea');
                el.value = key;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.$snotify.success(this.$t('ui.dataSource.copySuccess'));
            },
            close() {
                this.createDataSourceDialog = false;
                this.editDataNameDialog = false;
                this.selectedData = null;
            },
            goDetail(item) {
                if (this.rowClick) {
                    this.$router.push(`${this.urlPrefix}/data-sources/${item.pid}`).catch(() => {
                    });
                }
                this.rowClick = true;
            },
            refresh() {
                this.createDataSourceDialog = false;
                this.selectedData = null;
                this.getDataSourceList();
                this.editDataNameDialog = false;
            }
        },
        mounted() {
            this.getDataSourceList();
        },
    };
</script>

<style scoped>
</style>