<template>
    <v-container fluid class="app-container">
        <app-title-bar :title="$t('ui.card.myCards')" :items="navBarItems">
        </app-title-bar>
        <v-card class="mt-6">
            <v-col>
                <v-row no-gutters align="center">
                    <v-col>
                        <v-btn large color="primary" class="white--text" @click="createCardDialog=true">
                            {{$t('ui.card.create')}}
                        </v-btn>
                    </v-col>
                    <v-col class="mr-2">
                        <search-bar
                                :searchText.sync="cardNameSearch"
                                :searchLabel="searchLabel"
                                @cleanAllFilters="cleanAllFilters"
                                :noSort="true"
                                @toggleSortOrder="toggleSortOrder"
                                @refresh="getSubWallets()"
                        ></search-bar>
                    </v-col>
                </v-row>
            </v-col>

            <v-row class="pa-0">
                <v-container fluid>
                    <v-data-table :headers="headerCards"
                                  :items="cardList"
                                  :footer-props="tableFooterProps"
                                  :page.sync="query.pagination.currentPage"
                                  :items-per-page.sync="query.pagination.limit"
                                  :server-items-length="query.pagination.totalCount"
                                  :item-class="defaultCard"
                                  class="app-list" disable-sort>
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-row>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="changeName(item)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('ui.card.table.edit')}}</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="goCardDetail(item)">
                                            <v-icon>visibility</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('ui.card.table.detail')}}</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="openDeleteCardDialog(item)">
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('ui.card.table.delete')}}</span>
                                </v-tooltip>
                            </v-row>
                        </template>
                        <template v-slot:item.default="{ item }">
                            <v-tooltip v-if="item.default===true" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="#40C38F">star</v-icon>
                                </template>
                                <span>{{$t('ui.card.table.defaultCard')}}</span>
                            </v-tooltip>
                            <v-btn v-if="item.default===false" small color="primary" outlined
                                   :loading="item.loading"
                                   @click="setDefault(item)">{{$t('ui.card.setDefault')}}
                            </v-btn>
                        </template>
                    </v-data-table>

                </v-container>
            </v-row>
            <delete-item-dialog v-model="deleteItemDialogStatus" :loading="deleteLoading"
                                :title="$t('ui.card.deleteTitle')"
                                :text="(selectedCard && selectedCard.default===true) ? $t('ui.card.deleteTextDefault') : $t('ui.card.deleteText')"
                                @delete="deleteCard"></delete-item-dialog>
            <create-card-dialog v-model="createCardDialog"
                                @close="close()"
                                @refresh="refresh()"></create-card-dialog>
            <card-detail-dialog v-if="selectedCard" v-model="openCardDetailDialog" :card="selectedCard"
                                @close="close()"></card-detail-dialog>
            <change-card-name-dialog v-if="selectedCard"  v-model="changeCardNameDialog"
                                     :card="selectedCard"
                                     :user="userInfo" @close="close()"
                                     @refresh="refresh()"></change-card-name-dialog>
        </v-card>
    </v-container>
</template>
<script>

    import ChangeCardNameDialog from "@/modules/card/components/dialogs/ChangeCardNameDialog"
    import CardDetailDialog from "@/modules/card/components/dialogs/CardDetailDialog"
    import CreateCardDialog from "@/modules/card/components/dialogs/CreateCardDialog"
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import SearchBar from "@/modules/core/components/SearchBar";
    import Pagination from "@/modules/core/mixins/pagination.js";
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";
    import {store} from "ekx-vue-base";

    export default {
        components: {
            DeleteItemDialog,
            CreateCardDialog,
            CardDetailDialog,
            ChangeCardNameDialog,
            SearchBar
        },
        mixins: [
            new Pagination({
                methodName: "getPaymentSources",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    cardName: "=lk="
                },
                searchMirror: {
                    cardName: {
                        path: "cardNameSearch"
                    }
                }
            })
        ],
        data() {
            return {
                userInfo: this.$store.getters['core/getUserInfo'],
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                isSystemAdmin: this.$store.getters["core/getUserInfo"].isSystemAdmin,
                loading: false,
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                selectedCard: null,
                cardList: [],
                cardNameSearch: "",
                searchLabel: this.$t('ui.card.dialog.name'),
                cardClick: false,
                createCardDialog: false,
                openCardDetailDialog: false,
                deleteItemDialogStatus: false,
                changeCardNameDialog: false,
                deleteLoading: false,

                headerCards: [
                    {
                        text: this.$t('ui.card.dialog.name'),
                        value: "alias",
                        align: "start",
                        sortable: false,
                        width: "250"

                    }, {
                        text: this.$t('ui.card.dialog.bankName'),
                        value: "bank_name",
                        align: "start",
                        sortable: false,
                        width: "250"

                    }, {
                        text: this.$t('ui.card.dialog.number'),
                        value: "cardNumber",
                        sortable: false,
                        width: "250"

                    }, {
                        text: this.$t('ui.card.dialog.expirationDate'),
                        value: "expirationDate",
                        align: "start",
                        sortable: false,
                        width: "250"

                    }, {
                        text: this.$t('ui.card.table.defaultCard'),
                        value: "default",
                        align: "start",
                        sortable: false,
                        width: "250"

                    },
                    {
                        text: this.$t('ui.card.table.actionText'),
                        value: "actions",
                        width: "120",
                        sortable: false
                    },
                ],
            };
        },

        created() {
            EkxDebounce.watch(this, "cardNameSearch", 500, () => {
                this.query.search.cardName.value = this.cardNameSearch;

            })
        },
        methods: {

            async getPaymentSources() {
                this.loading = true;
                try {
                    let query = this.createQuery();
                    let result = await this.$http.get(`/customers/${this.userInfo.customerId}/payment-sources?${query}`);
                    result.data.items.forEach(item => {
                        item.expirationDate = `${item.card.expiry_month}/${item.card.expiry_year}`;
                        item.cardNumber = `**** **** **** ${item.last_four}`;
                        item.loading = false;

                    });
                    this.cardList = result.data.items;
                    this.loading = false;
                    this.query.pagination = result.data.meta.pagination;
                } catch (error) {
                    this.loading = false;
                    this.$error.notify(error);
                }
            },

            async deleteCard() {
                this.deleteLoading = true;
                try {
                    await this.$http.delete(`${this.urlPrefix}/customers/${this.userInfo.customerId}/payment-sources/${this.selectedCard.id}`);
                    this.$snotify.success(this.$t('ui.card.deleteSuccess'));
                    this.getPaymentSources();
                    this.deleteLoading = false;
                    this.deleteItemDialogStatus = false;
                } catch (error) {
                    this.$error.notify(error);
                    this.deleteLoading = false;
                    this.deleteItemDialogStatus = false;
                }
            },

            defaultCard: function (item) {
                return item.id === this.userInfo.cardId ? `cardStyle` : null
            },

            goCardDetail(item) {
                this.selectedCard = item;
                this.openCardDetailDialog = true;
            },

            changeName(item) {
                this.selectedCard = item;
                this.changeCardNameDialog = true;
            },

            openDeleteCardDialog(item) {
                this.cardClick = false;
                this.selectedCard = item;
                this.deleteItemDialogStatus = true;
            },

            cleanAllFilters() {
                this.cardNameSearch = "";
                this.getPaymentSources();
            },

            async setDefault(item) {
                this.cardList.forEach(card => {
                    card.id === item.id ? card.loading = true : null
                });
                try {
                    await this.$http.patch(`/users/${this.userInfo.pid}/payment-sources/${item.id}`);
                    this.$store.commit(`core/setCardId`, item.id);
                    this.cardList.forEach(card => {
                        card.id === item.id ? card.loading = false : null
                    });
                    await this.getPaymentSources();
                } catch (error) {
                    this.cardList.forEach(card => {
                        card.id === item.id ? card.loading = false : null
                    });
                    this.$error.notify(error);
                }
            },

            refresh() {
                this.cardClick = true;
                this.createCardDialog = false;
                this.changeCardNameDialog = false;
                this.getPaymentSources();
            },

            close() {
                this.cardClick = true;
                this.createCardDialog = false;
                this.changeCardNameDialog = false;
                this.openCardDetailDialog = false
            }
        },

        mounted() {
            this.getPaymentSources();
        }
    };
</script>