<template>
    <div>

    </div>
</template>

<script>

    import $oidc from "@/utils/OidcService"

    export default {
        beforeRouteEnter(to, from, next) {
            $oidc.mgr
                .signinRedirectCallback()
                .then(user => {
                    let lastLocation = user.state ? user.state.location : null;
                    if (lastLocation) {
                        lastLocation = lastLocation.replace(window.location.origin, '')
                    }

                    window.location.replace(lastLocation || '/')
                })
                .catch((err) => {
                    next({name: 'error', params: {error: err}})
                    // this.$router.replace('/')
                })
        }
    }

</script>

<style scoped>

</style>