<template>
    <v-container fill-height fluid>
        <input class="ghostSelector" type="file" @change="processFile($event)" style="display:none;" />
        <v-row align="start" class="mt-1">
            <v-card width="100%">

        <v-stepper v-model="e1">
            <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">
                    {{$t('ui.excel.importExcel')}}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 2" step="2">
                    {{$t('ui.excel.dataMapping')}}
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-row no-gutters>
                        <v-flex sm12 md6>
                            <v-checkbox v-model="importConfig.firstRowHeader" :label="$t('ui.excel.firstRowHeader')" color="indigo"></v-checkbox>
                        </v-flex>
                        <v-flex sm12 md6>
                            <v-text-field v-model="importConfig.dropLine" :label="$t('ui.excel.dropLine')" type="number"></v-text-field>
                        </v-flex>
                    </v-row>
                    <v-row no-gutters>
                        <v-spacer></v-spacer>
                        <v-btn class="mt-2" @click="parseFile" color="green darken-2" outlined style="top:-8px">
                            {{$t('ui.excel.dataMapping')}}
                            <v-icon right dark>mdi-import</v-icon>
                        </v-btn>
                    </v-row>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-btn text icon v-if="e1 === 2" @click="e1=1">
                        <v-icon>keyboard_backspace</v-icon>
                    </v-btn>
                <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                >
                    <v-container fluid>
                        <v-select required name="sheet" :items="excelData" v-model="selectedSheet" :label="$t('ui.excel.selectSheet')" item-text="name" return-object></v-select>
                    </v-container>
                    <v-divider></v-divider>
                    <v-container grid-list-lg fluid v-if="selectedSheet">
                        <v-row no-gutters>
                            <v-flex xs12 v-for="header in columnSet" :key="header.name">
                                <v-flex class="pa-2">
                                    <v-row pa-3>
                                        <v-autocomplete :required="header.required ? required : ''" :name="header.name" :items="selectedSheet.headers" v-model="header.mapping" return-object item-text="name" :label="header.name" @change="getUniqueValues(header)"></v-autocomplete>
                                        <v-subheader v-if="header.uniques">{{$t('ui.excel.enumMapping')}}</v-subheader>
                                        <v-row v-for="unq in header.uniques" :key="unq.name"  px-4>
                                            <v-autocomplete required :name="unq.name" v-model="unq.mapping" :items="header.enums" item-text="name" return-object :label="unq.name"></v-autocomplete>
                                        </v-row>
                                    </v-row>
                                </v-flex>
                            </v-flex>
                        </v-row>
                        <v-divider></v-divider>
                    </v-container>
                </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="mx-1" v-if="e1 === 2 && selectedSheet" @click="done">
                            {{$t('ui.general.save')}}
                        </v-btn>
                    </v-card-actions>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
            </v-card>
        </v-row>
    </v-container>

</template>

<script>
    import XLSXService from "@/modules/excel/services/XLSXService"

    export default {
        props: ["columnSet","data"],
        data() {
            return {
                file: {
                    name: ""
                },
                importConfig: {
                    firstRowHeader: false,
                    dropLine: 0
                },
                e1: 1,
                excelData: [],
                selectedSheet: null,
            }
        },
        watch: {
            search(val) {
                val && val !== this.select && this.querySelections(val)
            }
        },
        methods: {
            clear() {
                this.excelData = [];
                this.selectedSheet = null;
                this.columnSet.forEach((col) => {
                    delete col.mapping;
                });
            },
            processFile(event) {
                this.file.data = this.data ? this.data : event.target.files[0];
                this.file.name = this.file.data.name;
            },
            parseFile() {
                if (this.file) {
                    this.clear();
                    XLSXService.parseFile(this.file.data, this.importConfig).then((result) => {
                        this.excelData = result;
                        this.e1 = 2;
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            },
            openSelector() {
                this.inputElement.click();
            },
            sheetChange() {
                this.sheetData = this.excelData[this.selectedSheet.index];
            },
            getUniqueValues(header) {
                if (header.type == "enum") {
                    var tmp = [];
                    for (var i = 0, l = this.selectedSheet.data.length; i < l; i++) {
                        if (tmp.length > 50) {
                            alert("Unique enum values can not be bigger than 50");
                            header.mapping = null;
                            return;
                        }
                        if (tmp.indexOf(this.selectedSheet.data[i][header.mapping.index]) === -1) {
                            tmp.push(this.selectedSheet.data[i][header.mapping.index]);
                        }
                    }

                    var uniques = [];
                    tmp.forEach((value) => {
                        if (value != null) {
                            uniques.push({
                                name: value.toString(),
                                value: value,
                                mapping: null,
                            });
                        }
                    });

                    header.uniques = uniques;
                }

            },
            parseData() {
                this.columnSet.forEach((col) => {
                    if (col.type == "enum") {
                        col.uniqueMap = {};
                        if (col.uniques) {
                            col.uniques.forEach((unq) => {
                                col.uniqueMap[unq.value] = unq.mapping;
                            })
                        }
                    }
                });
                var data = [];
                this.selectedSheet.data.forEach((item) => {
                    var tmp = {};
                    this.columnSet.forEach((col) => {
                        if (col.mapping) {
                            if (col.type == "enum") {
                                tmp[col.key] = col.uniqueMap[item[col.mapping.index]]
                            } else {
                                tmp[col.key] = item[col.mapping.index];
                            }
                        }
                    });
                    data.push(tmp)
                });
                return data;
            },
            async done() {
                if(await this.$refs.form.validate()){
                    var data = this.parseData();
                    this.$emit("data", data, this.file.name);
                }
            }
        },
        mounted() {
            this.processFile();
            this.inputElement = this.$el.getElementsByClassName("ghostSelector")[0];
        }

    }
</script>

<style>

</style>