<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-container fluid class="app-container">
        <app-title-bar
                :title="page.title" :items="page.items">
        </app-title-bar>

        <v-card class="pt-4 mt-6">
            <v-tabs grow v-model="tabs">
                <v-tab>
                    <span class="font-weight-bold">{{$t('ui.user.profile')}}</span>
                </v-tab>
                <v-tab>
                    <span class="font-weight-bold">{{$t('ui.user.security')}}</span>
                </v-tab>
                <v-tab v-if="urlPrefix === '/admin' && !isAdminProfilePage && !isSubUser">
                    <span class="font-weight-bold">{{$t('ui.user.subUserList')}}</span>
                </v-tab>
                <v-tab v-if="!isAdminProfilePage && !isSubUserProfilePage && urlPrefix=== '/admin' && !isSubUser">
                    <span class="font-weight-bold">{{$t('ui.home.apiKeys')}}</span>
                </v-tab>
                <v-tab v-if="!isAdminProfilePage">
                    <span class="font-weight-bold">{{$t('ui.user.notifications')}}</span>
                </v-tab>
                <v-tab v-if="!isAdminProfilePage && !isSubUserProfilePage && urlPrefix=== '/admin' && !isSubUser">
                    <span class="font-weight-bold">{{$t('ui.user.calculations')}}</span>
                </v-tab>
                <v-tab v-if="!isAdminProfilePage && !isSubUserProfilePage">
                    <span class="font-weight-bold">{{$t('ui.user.wallets')}}</span>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs" class="base">
                <v-tab-item>
                    <v-container fluid v-if="userInfo">
                        <v-row class="mt-4">
                            <v-col>
                                <v-avatar color="green" size="70">
                                    <span class="white--text headline">{{userInfo.username[0].toUpperCase()}}</span>
                                </v-avatar>
                                <span class="headline ml-3">{{userInfo.username}}</span>
                            </v-col>
                            <v-col v-if="urlPrefix==='/admin' && !userInfo.parent && !isAdminProfilePage"
                                   class="font-weight-bold text-right" align="center">
                                <v-btn @click="goLogs()" color="primary">
                                    {{$t('ui.log.goLog')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider inset></v-divider>
                        <v-spacer></v-spacer>
                        <v-col class="pa-6">
                            <v-row class="mt-5">
                                <v-col sm="6" lg="6">
                                    <p class="font-weight-bold">{{$t('ui.user.username')}} :</p>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col>
                                    <p class="font-weight-bold text-right">
                                        {{userInfo.username}} </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col sm="6" lg="6">
                                    <p class="font-weight-bold">{{$t('ui.user.name')}} :</p>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col>
                                    <p class="font-weight-bold text-right">
                                        {{userInfo.name}} </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col sm="6" lg="6">
                                    <p class="font-weight-bold">{{$t('ui.user.surname')}} :</p>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col>
                                    <p class="font-weight-bold text-right">
                                        {{userInfo.surname}} </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col sm="6" lg="6">
                                    <p class="font-weight-bold">{{$t('ui.user.email')}} :</p>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col>
                                    <p class="font-weight-bold text-right">
                                        {{userInfo.email}} </p>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-divider></v-divider>
                        <v-row class="pa-2 mt-2 mb-0">
                            <v-spacer></v-spacer>
                            <v-btn large color="primary" @click="editUserInfoDialog=true">
                                {{$t('ui.general.edit')}}
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item>
                    <v-container fluid>
                        <v-row class="mt-2">
                            <v-col sm="6" lg="6">
                                <p class="font-weight-bold">{{$t('ui.user.password')}}</p>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="font-weight-bold text-right">
                                <v-btn class="mr-2" width="200px" color="info"
                                       @click="changePasswordDialog=true">
                                    {{$t('ui.user.dialog.changePassword')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mt-4">
                            <v-col sm="6" lg="6">
                                <p class="font-weight-bold">{{$t('ui.user.account')}}</p>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="font-weight-bold text-right">
                                <v-btn class="mr-2" width="200px" color="error"
                                       @click="deleteUserAccountDialog=true">
                                    {{$t('ui.user.deleteAccount')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item v-if="urlPrefix === '/admin' && !isAdminProfilePage && !isSubUser">
                    <user-list :adminUserList="userList" @goUserDetail=goUserDetail(item)></user-list>
                </v-tab-item>
                <v-tab-item v-if="!isAdminProfilePage && !isSubUserProfilePage && urlPrefix=== '/admin' && !isSubUser">
                    <api-key-list adminFlag="true" :user="userInfo"></api-key-list>
                </v-tab-item>
                <v-tab-item v-if="!isAdminProfilePage">
                    <user-notification-options :user="userInfo" @refresh="refresh"></user-notification-options>
                </v-tab-item>
                <v-tab-item v-if="!isAdminProfilePage && !isSubUserProfilePage && urlPrefix=== '/admin' && !isSubUser">
                    <calculation-list isAdmin="true" @refreshWallet="refreshWallet"></calculation-list>
                </v-tab-item>
                <v-tab-item v-if="!isAdminProfilePage && !isSubUserProfilePage">
                    <v-data-table
                            :headers="headersWallet"
                            :items="subWallets"
                            class="app-simple mt-4"
                            :page.sync="query.pagination.currentPage"
                            :items-per-page.sync="query.pagination.limit"
                            :footer-props="tableFooterProps"
                            :server-items-length="query.pagination.totalCount"
                            @click:row="goWalletDetail"
                            disable-sort
                    >
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span class="mt-8">{{item.name}}</span>
                            <v-icon class="ml-2" small v-if="item.parent===null" color="primary">
                                mdi-star
                            </v-icon>
                        </template>
                        <template v-slot:item.balance="{ item }">
                            <span>{{item.balance}} {{$t('ui.wallet.credit')}}</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip v-if="!userInfo.parent" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="goWalletDetail(item)">
                                        <v-icon>equalizer</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.detail')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-if="item.parent" class="ml-4" icon
                                           @click="openDeleteWalletDialog(item)"
                                           v-bind="attrs"
                                           v-on="on">
                                        <v-icon>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.wallet.delete')}}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

        <change-password-dialog @refresh="refresh()" @close="close()" :user="userInfo"
                                v-model="changePasswordDialog"></change-password-dialog>
        <delete-user-account-dialog
                v-if="userInfo"
                v-model="deleteUserAccountDialog"
                @refresh="refresh"
                @close="close"
                :user="userInfo"
                :text="$t('ui.user.deleteAccount')"
                @delete="deleteUser">
        </delete-user-account-dialog>
        <delete-item-dialog
                v-model="deleteWalletDialogStatus"
                :loading="deleteWalletDialogLoading"
                :title="$t('ui.wallet.delete')"
                :text="$t('ui.wallet.deleteText')"
                @delete="deleteSubWallet">
        </delete-item-dialog>
        <edit-user-info-dialog :user="userInfo" v-model="editUserInfoDialog" @refresh="refresh"
                               @close="close"
        ></edit-user-info-dialog>

    </v-container>
</template>

<script>
    import Pagination from "@/modules/core/mixins/pagination.js";
    import ChangePasswordDialog from "@/modules/user/components/dialogs/ChangePasswordDialog";
    import EditUserInfoDialog from "@/modules/user/components/dialogs/EditUserInfoDialog";
    import DeleteUserAccountDialog from "@/modules/user/components/dialogs/DeleteUserAccountDialog";
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import UserNotificationOptions from "@/modules/user/components/UserNotificationOptions"
    import UserList from "@/modules/user/pages/UserList"
    import ApiKeyList from "@/modules/apiKey/pages/ApiKeyList"
    import CalculationList from "@/modules/calculation/pages/CalculationList"
    import {store} from "ekx-vue-base";

    export default {
        components: {
            EditUserInfoDialog,
            ChangePasswordDialog,
            DeleteItemDialog,
            UserNotificationOptions,
            UserList,
            ApiKeyList,
            CalculationList,
            DeleteUserAccountDialog
        },
        mixins: [
            new Pagination({
                methodName: "getWalletList",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    name: "=lk="
                },
                searchMirror: {
                    name: {
                        path: "search"
                    }
                }
            })
        ],
        data() {
            return {
                page: {
                    title: null,
                    items: [
                        {
                            text: this.$t('ui.sidebar.users'),
                            exact: true,
                            to: this.$route.meta.permission === 'admin' ? `/admin/users` : '/users',
                        }, {
                            text: this.$t('ui.user.subUserDetail'),
                            exact: false,
                        }
                    ]
                },
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                tabs: this.$store.getters["core/getUserInfo"].tabs,
                userPid: this.$route.name === 'userProfile' || this.$route.name === 'adminProfile' ? this.$store.getters["core/getUserInfo"].pid : this.$route.params.userPid,
                selectedUser: null,
                selectedSubWallet: null,
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                userInfo: null,
                isAdminProfilePage: this.$route.name === 'adminProfile',
                isSubUserProfilePage: this.$route.name === 'userProfile' && this.$store.getters["core/getUserInfo"].parent,
                isUserProfilePage: this.$route.name === 'userProfile' && !this.$store.getters["core/getUserInfo"].parent,
                isSubUser: false,
                subWallets: [],
                deleteUserAccountDialog: false,
                deleteWalletDialogStatus: false,
                deleteWalletDialogLoading: false,
                editUserInfoDialog: false,
                userList: [],
                rowClick: true,
                changePasswordDialog: false,
                headers: [
                    {
                        text: this.$t('ui.user.username'),
                        value: "username",
                        align: "start",
                        width: "200"
                    },
                    {
                        text: this.$t('ui.user.name'),
                        value: "name",
                        width: "200"
                    },
                    {
                        text: this.$t('ui.user.surname'),
                        value: "surname",
                        width: "200"
                    },
                    {
                        text: this.$t('ui.user.email'),
                        value: "email",
                        width: "200"
                    },
                    {text: this.$t('ui.general.action'), value: "actions", width: "120", sortable: false}
                ],
                headersWallet: [
                    {
                        text: this.$t('ui.wallet.name'),
                        value: "name",
                        align: "start",
                    },
                    {
                        text: this.$t('ui.wallet.balance'),
                        value: "balance",
                        width: "850"
                    }
                ]
            };

        },
        methods: {
            async getUserDetail() {
                try {
                    let response = await this.$http.get(`${this.urlPrefix}/users/${this.userPid}`);
                    this.userInfo = response.data;
                    this.isSubUser = this.userInfo.parent && !this.userInfo.isSystemAdmin;
                    this.page.title = this.userInfo.name;
                } catch (error) {
                    this.$error.notify(error);
                }
            },
            async getSubUserList() {
                if (this.urlPrefix === '/admin' && !this.isAdminProfilePage) {
                    try {
                        let response = await this.$http.get(`${this.urlPrefix}/users/${this.userPid}/sub-users`);
                        this.userList = response.data.items;
                    } catch (error) {
                        this.$error.notify(error);
                    }
                }
            },

            async getWalletList() {
                try {
                    let query = this.createQuery();
                    let response = this.userInfo.parent ? await this.$http.get(`${this.urlPrefix}/users/${this.userPid}/wallets?permission=true&${query}`)
                        : await this.$http.get(`${this.urlPrefix}/users/${this.userPid}/wallets?${query}`);
                    this.subWallets = response.data.items;
                    this.query.pagination = response.data.meta.pagination;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            openDeleteItemDialog(item) {
                this.rowClick = false;
                this.selectedUser = item;
                this.deleteItemDialogStatus = true
            },

            async deleteUser() {
                try {
                    this.deleteItemDialogLoading = true;
                    await this.$http.delete(`${this.urlPrefix}/users/${this.selectedUser.pid}`);
                    this.$snotify.success(this.$t('ui.user.deleteSuccess'));
                    this.getUsersList();
                } catch (error) {
                    this.$error.notify(error);
                } finally {
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                }
            },

            openDeleteWalletDialog(item) {
                this.rowClick = false;
                this.selectedSubWallet = item;
                this.deleteWalletDialogStatus = true
            },

            async deleteSubWallet() {
                try {
                    this.deleteItemDialogLoading = true;
                    await this.$http.delete(`${this.urlPrefix}/wallets/${this.selectedSubWallet.pid}`);
                    this.$snotify.success(this.$t('ui.wallet.deleteSuccess'));
                    this.getWalletList();
                } catch (error) {
                    this.$error.notify(error);
                } finally {
                    this.rowClick = true;
                    this.deleteWalletDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                }
            },

            //TODO:GRAFANA ILE DUZENLENECEK
            goLogs() {
                this.$router.push(`${this.urlPrefix}/users/${this.userPid}/logs`).catch(() => {
                });
            },

            goWalletDetail(item) {
                if(!this.userInfo.parent || (this.userInfo.parent && this.userInfo.parent.pid!==(this.$store.getters['core/userInfo']).pid)) {
                    if (this.rowClick) {
                        if(this.urlPrefix === '/admin') {
                            this.$router.push(`${this.urlPrefix}/users/${this.userPid}/wallets/${item.pid}`).catch(() => {
                            });
                        }else {
                            this.$router.push(`${this.urlPrefix}/wallets/${item.pid}`).catch(() => {
                            });
                        }
                    }
                }

            },

            refresh() {
                this.rowClick = true;
                this.changePasswordDialog = false;
                this.editUserInfoDialog = false;
                this.deleteUserAccountDialog= false;
                this.getUserDetail();
            },

            refreshWallet() {
                this.getWalletList();
            },

            close() {
                this.deleteUserAccountDialog= false;
                this.changePasswordDialog = false;
                this.editUserInfoDialog = false;
            },

        },

        async mounted() {
            if (this.urlPrefix === '/admin' && !this.isAdminProfilePage) {
                this.getSubUserList();
            }
            // else{
                // this.headersWallet.push({text: this.$t('ui.general.action'), value: "actions", width: "10", sortable: false})

            // }
            await this.getUserDetail();
            await this.getWalletList();
        },
        watch: {
            '$route.params': {
                async handler() {
                    this.userPid = this.$route.params.userPid ? this.$route.params.userPid : this.$store.getters["core/getUserInfo"].pid;
                    this.userInfo = null;
                    this.userList = [];
                    if (this.urlPrefix === '/admin' && !this.isAdminProfilePage) {
                        this.getSubUserList();
                    }
                    await this.getUserDetail();
                    await this.getWalletList();
                }
            },
            tabs: function (val) {
                this.$store.commit(`core/setTab`, val);
            }
        }
    };
</script>
<style scoped>
</style>