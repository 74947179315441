<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-row justify="center">
        <v-dialog v-model="value" max-width="1000px" height="1000" :persistent="true">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title> {{$t('ui.pdp.approvalDialog')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="closeFlag">
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-card>
                    <v-stepper v-model="curr" color="green">
                        <v-stepper-header>
                            <v-stepper-step
                                    v-for="(step,n) in versionList"
                                    :key="n"
                                    :complete="stepComplete(n+1)"
                                    :step="n+1">
                                {{ step.agreement_name }}
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-content v-for="(step,n) in versionList" :step="n+1" :key="n">
                            {{ step.name }}
                            <v-card  class="pic-container" v-html="step.content">
                            </v-card>
                            <v-divider>
                            </v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn  v-if="!step.required" color="primary" @click="versionShowSelection(n , step, true )" :loading="loadingShowOption" >{{$t('ui.pdp.dontShow')}}</v-btn>
                                <v-btn  v-if="!step.required && n === lastIndex" color="warning" @click="close()"  :loading="loadingShowOption" >{{$t('ui.pdp.show')}}</v-btn>
                                <v-btn  v-if="!step.required && n < lastIndex " color="warning" @click="curr= n+2" :loading="loadingShowOption">{{$t('ui.pdp.show')}}</v-btn>
                                <v-btn color="success" @click="createApproval(n, step)" :loading="loading">{{$t('ui.general.approve')}}</v-btn>
                            </v-card-actions>

                        </v-stepper-content>
                    </v-stepper>
                </v-card>

                <v-divider></v-divider>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>

    export default {
        props: ["value", "versionList"],
        data() {
            return {
                curr: 1,
                loading: false,
                loadingShowOption: false,
                lastIndex: 0,
                closeFlag: true
            }
        },
        watch: {
            value: function () {
               this.lastIndex = this.versionList.length-1;
               this.versionList.forEach((version)=> {
                   if (version.required) {
                       return this.closeFlag = false
                   }
               })
            },
            panel: function (val) {
                console.log(val)
            }
        },
        methods: {
            createApproval: async function (n, step) {
                this.loading = true;
                try {
                    await this.$http.post(`/approvals`, {agreement_pid: step.agreement_pid});
                    this.$snotify.success(this.$t('ui.pdp.success'));
                    if (n === this.lastIndex) {
                        this.$emit("close", true);
                    } else {
                        this.curr= n+2
                    }
                } catch (error) {
                    this.$error.notify(error)
                }finally {
                    this.loading = false;
                }
            },
            async versionShowSelection(n, step, show) {
                this.loadingShowOption = true;
                try {
                    await this.$http.patch(`/agreement-versions`, {agreement_pid: step.agreement_pid, dont_show: show});
                    this.$snotify.success(this.$t('ui.pdp.success'));
                    if (n === this.lastIndex) {
                        this.$emit("close", true);
                    } else {
                        this.curr = n + 2
                    }
                } catch (error) {
                    this.$error.notify(error)
                }finally {
                    this.loadingShowOption = false;
                }
            },
            done(n){
              this.curr= n+2
            },
            stepComplete(step) {
                return this.curr > step
            },
            close() {
                this.$emit("close", true);
            }
        },
    };
</script>
<style scoped>
    .pic-container {
        max-height: 1000px;
        overflow: auto;
        height: 500px;
    }
</style>