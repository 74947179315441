<template>
    <v-container fluid class="app-container">
        <app-title-bar
                :title="page.title" :items="page.items">
        </app-title-bar>
        <v-card class="pt-4 mt-6">
            <v-col>
                <v-row no-gutters>
                    <!--                    <v-col class="mr-4">-->
                    <!--                        <v-select-->
                    <!--                                v-model="selectedType"-->
                    <!--                                :items="typeList"-->
                    <!--                                :label="$t('ui.openSource.type')"-->
                    <!--                                return-object-->
                    <!--                        ></v-select>-->
                    <!--                    </v-col>-->
                    <v-col class="mr-4">
                        <v-select v-model="selectedSource" :items="openSourceList" item-text="name"
                                    :label="$t('ui.openSource.source')"
                                    :search-input.sync="search"
                                    hide-selected
                                    persistent-hint>
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="timeInterval"
                                :items="timeIntervals"
                                :label="$t('ui.general.timeInterval')"
                                return-object
                        ></v-select>
                    </v-col>

                </v-row>
            </v-col>
            <v-row class="pa-0">
                <v-container fluid>
                    <v-data-table :headers="headers"
                                  :items="openDataList"
                                  item-key="name"
                                  :items-per-page.sync="limit"
                                  :footer-props="tableFooterProps"
                                  :server-items-length="totalCount"
                                  :page.sync="currentPage"
                                  :options.sync="tableOptions"
                                  class="app-list"
                                  @click:row="goSourceDetail">
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <template v-slot:item.createdAt="{ item }">
                            {{new Date(item.createdAt).toLocaleString()}}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="goSourceDetail(item)">
                                        <v-icon>equalizer</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.detail')}}</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:item.pid="{ item }">
                            <v-row no-gutters class="show-on-hover-opacity" style="height:32px;width:350px">
                                <v-col class="mt-2">
                                    <simplebar class="simplebar-force-block" style="height:30px;overflow-y: hidden">
                                        <span style="white-space: nowrap" class="secret-text">{{item.pid}}</span>
                                    </simplebar>
                                </v-col>
                                <div style="width: 40px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon class="show-on-hover-slot" v-on="on"
                                                   @click="onCopyPid(item.pid)">
                                                <v-icon>
                                                    mdi-clipboard-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{$t('ui.dataSource.copyPid')}}</span>
                                    </v-tooltip>
                                </div>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-container>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>

    import Pagination from "@/modules/core/mixins/pagination.js";
    import {store} from "ekx-vue-base";

    export default {

        mixins: [
            new Pagination({
                methodName: "getOpenSourceList",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    name: "=lk="
                },
                searchMirror: {
                    name: {
                        path: "nameSearch"
                    }
                }
            })
        ],

        data() {
            return {
                page: {
                    title: this.$t('ui.openSource.header'),
                },
                rowClick: false,
                openDataList: [],
                tableOptions: {},

                //selectedData
                selectedData: null,
                selectedSource: 'Markit',
                selectedType: null,

                //pagination
                keyList: ["name"],
                limit: 10,
                totalCount: null,
                currentPage: 1,

                //selectLists
                openSourceList: ['Markit'],
                timeInterval: {text: this.$t('ui.openSource.thisWeek'), value: 0},
                timeIntervals: [{text: this.$t('ui.openSource.thisWeek'), value: 0},
                    {text: this.$t('ui.openSource.thisMonth'), value: 1},
                    {text: this.$t('ui.general.all'), value: 2}],
                typeList: [
                    {text: this.$t('ui.openSource.allTypes'), value: null},
                    {text: "Yield Curve", value: 0},
                    {text: "Yield Data", value: 1},
                    {text: "Fixed Rate Bond Definition", value: 2},
                    {text: "Vanilla Option Definition", value: 3},
                    {text: "Prices For Vol Market Data", value: 4},
                    {text: "Floating Bond Definition", value: 5}],
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                headers: [
                    {
                        text: this.$t('ui.openSource.id'),
                        value: 'pid',
                        align: "start",
                        width: "400",
                        sortable: false
                    }, {
                        text: this.$t('ui.openSource.name'),
                        value: 'name',
                        width: '400',
                        sortable: false
                    }, {
                        text: this.$t('ui.openSource.type'),
                        value: 'type',
                        width: '300',
                        sortable: false
                    }, {
                        text: this.$t('ui.openSource.createdDate'),
                        value: 'createdAt',
                        sortable: false,
                        width: '300'
                    },

                    {text: this.$t('ui.general.header.actionText'), sortable:false, value: 'actions', width: "100"}
                ],
            };
        },

        watch: {

            timeInterval: function () {
                this.getOpenSourceList();
            },

            selectedSource: function () {
                this.getOpenSourceList();
            },

            selectedType: function () {
                this.getOpenSourceList();
            },
            "tableOptions.sortBy"() {
                this.query.sort.field = this.tableOptions.sortBy[0];
                this.query.sort.order = this.tableOptions.sortDesc[0] ? "desc" : "asc"
            },
            limit: function (value) {
                this.limit = value;
                this.getOpenSourceList();
            },
            currentPage: function (value) {
                this.currentPage = value;
                this.getOpenSourceList();
            },
        },

        methods: {

            goSourceDetail(item) {
                if (this.rowClick) {
                    this.$router.push(`/open-sources/${item.pid}`).catch(() => {
                    });
                }

                this.rowClick = true;
            },

            getType(type) {

                let dataType = {
                    0: "yieldCurve",
                    1: "yieldData",
                    2: "fixedRateBondDefinition",
                    3: "vanillaOptionDefinition",
                    4: "pricesForVolMarketData",
                    5: "floatingBondDefinition",
                };

                return dataType[type];
            },

            onCopyPid: function (key) {
                this.rowClick = false;
                const el = document.createElement('textarea');
                el.value = key;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.$snotify.success(this.$t('ui.openSource.copySuccess'));
            },

            async getOpenSourceList() {
                this.openDataList = [];
                let type = this.selectedType ? this.selectedType.value : null;
                try {
                    let result = await this.$http.get(`/openSources?timeInterval=${this.timeInterval.value}&source=${this.selectedSource}&type=${type}&limit=${this.limit}&offset=${this.limit * (this.currentPage - 1)}`);

                    result.data.items.forEach(async (data) => {
                        data.type = await this.getType(data.type);
                        // if (data.data) {
                            this.openDataList.push(data);
                        // }
                    });
                    this.totalCount = result.data.meta.pagination.totalCount;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

        },
        mounted() {
            this.getOpenSourceList()
        },
    };
</script>

<style scoped>
</style>