<template>
    <v-row justify="center">
        <v-dialog v-model="value" persistent max-width="600px">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title>{{ $t('ui.calculation.refundBalanceDialog') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-container class="pa-0">
                    <v-col class="pa-6">
                        <v-row no-gutters>
                            <v-col>
                                {{$t('ui.calculation.refundBalanceText')}}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-divider></v-divider>
                    <v-col>
                        <v-row justify="end" no-gutters>
                            <v-btn class="mx-1" color="warning" @click="close">
                                {{$t('ui.general.cancel')}}
                            </v-btn>
                            <v-btn class="mx-1"
                                   color="success"
                                   @click="refundBalance()"
                                   :loading="loading"
                                   style="color: #FFFFFF;">
                                <span>{{$t('ui.general.refund')}}</span>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        props: ['value', 'calculation'],
        data() {
            return {
                loading: false,
                userPid: this.$route.params.userPid
            }
        },
        methods: {
            close() {
                this.$emit("close", true)
            },

            async refundBalance() {
                this.loading = true;
                try {
                    await this.$http.patch(`/admin/tasks/${this.calculation.pid}`, {userPid: this.userPid});
                    this.$snotify.success(this.$t('ui.calculation.refundSuccess'));
                    this.$emit("refresh", true);
                    this.loading = false;
                } catch (error) {
                    this.$error.notify(error);
                    this.loading = false
                }
            },

        },
    }
</script>

<style scoped>

</style>