var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"800px","height":"1900px","persistent":true},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-1 base primary",attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('ui.wallet.permissionDialog')))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('ui.general.close'))+" ")])],1),(_vm.walletOrUserList.length>0 && !_vm.walletDetail)?_c('v-col',{staticClass:"mt-2 pl-6 pr-6"},[(_vm.wallet)?_c('span',{staticStyle:{"font-size":"large"}},[_vm._v(" "+_vm._s(_vm.$t('ui.wallet.authorizedUsers'))+" : ")]):_c('span',{staticStyle:{"font-size":"large"}},[_vm._v(" "+_vm._s(_vm.$t('ui.wallet.authorizedWallets'))+" : ")]),_c('v-divider',{staticClass:"mt-4"}),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.walletOrUserList),function(data){return _c('v-card',{key:data,staticClass:"mr-4 mt-4"},[_c('v-list-item',{key:data.name},[(_vm.wallet)?_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"primary"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account ")])],1)],1):_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"primary"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-wallet-outline ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t(data.name))}})],1),(!_vm.userInfo.isSystemAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openDeleteItemDialog(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.wallet.deletePermission')))])]):_vm._e()],1)],1)}),1)],1):_vm._e(),(!_vm.userInfo.isSystemAdmin)?_c('v-col',{staticClass:"pl-6 pr-6"},[(_vm.user)?_c('v-alert',{attrs:{"text":"","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('ui.wallet.permissionWallet'))+" ")]):_c('v-alert',{attrs:{"text":"","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('ui.wallet.permissionUser'))+" ")]),_c('ValidationObserver',{ref:"permissionForm"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-autocomplete',{attrs:{"items":_vm.user ? _vm.walletList : _vm.userList,"search-input":_vm.searchText,"loading":_vm.searching,"label":_vm.user ?  _vm.$t('ui.wallet.header') : _vm.$t('ui.user.header'),"chips":"","deletable-chips":"","item-text":"name","no-filter":"","return-object":"","multiple":"","hide-no-data":""},on:{"update:searchInput":function($event){_vm.searchText=$event},"update:search-input":function($event){_vm.searchText=$event}},scopedSlots:_vm._u([(_vm.user)?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"success"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-wallet-outline ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.balance)+" TL ")])],1)]}}:{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"success"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.username))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.surname)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedList),callback:function ($$v) {_vm.selectedList=$$v},expression:"selectedList"}})]}}],null,false,4260968027)})],1)],1):_vm._e(),(_vm.userInfo.isSystemAdmin && _vm.walletOrUserList.length===0 && !_vm.walletDetail)?_c('v-col',{staticClass:"pl-6 pr-6 mt-4"},[(_vm.user)?_c('v-alert',{attrs:{"text":"","outlined":"","color":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('ui.wallet.noUserPermission'))+" ")]):_c('v-alert',{attrs:{"text":"","outlined":"","color":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('ui.wallet.noWalletPermission'))+" ")])],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('ui.general.close')))]),(!_vm.userInfo.isSystemAdmin)?_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","loading":_vm.saveButtonLoading},on:{"click":function($event){return _vm.savePermission()}}},[_vm._v(" "+_vm._s(_vm.$t('ui.general.save'))+" ")]):_vm._e()],1)],1)],1),_c('delete-item-dialog',{attrs:{"loading":_vm.deleteItemDialogLoading,"title":_vm.$t('ui.wallet.deletePermission'),"text":_vm.$t('ui.wallet.deletePermissionText')},on:{"delete":_vm.deletePermission},model:{value:(_vm.deleteItemDialogStatus),callback:function ($$v) {_vm.deleteItemDialogStatus=$$v},expression:"deleteItemDialogStatus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }