var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"app-container",attrs:{"fluid":""}},[_c('app-title-bar',{attrs:{"title":_vm.page.title,"items":_vm.page.items}}),_c('v-card',{staticClass:"pt-4 mt-6"},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-4"},[_c('v-select',{attrs:{"items":_vm.openSourceList,"item-text":"name","label":_vm.$t('ui.openSource.source'),"search-input":_vm.search,"hide-selected":"","persistent-hint":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.selectedSource),callback:function ($$v) {_vm.selectedSource=$$v},expression:"selectedSource"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.timeIntervals,"label":_vm.$t('ui.general.timeInterval'),"return-object":""},model:{value:(_vm.timeInterval),callback:function ($$v) {_vm.timeInterval=$$v},expression:"timeInterval"}})],1)],1)],1),_c('v-row',{staticClass:"pa-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"app-list",attrs:{"headers":_vm.headers,"items":_vm.openDataList,"item-key":"name","items-per-page":_vm.limit,"footer-props":_vm.tableFooterProps,"server-items-length":_vm.totalCount,"page":_vm.currentPage,"options":_vm.tableOptions},on:{"update:itemsPerPage":function($event){_vm.limit=$event},"update:items-per-page":function($event){_vm.limit=$event},"update:page":function($event){_vm.currentPage=$event},"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.goSourceDetail},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.goSourceDetail(item)}}},on),[_c('v-icon',[_vm._v("equalizer")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.general.detail')))])])]}},{key:"item.pid",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"show-on-hover-opacity",staticStyle:{"height":"32px","width":"350px"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-2"},[_c('simplebar',{staticClass:"simplebar-force-block",staticStyle:{"height":"30px","overflow-y":"hidden"}},[_c('span',{staticClass:"secret-text",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.pid))])])],1),_c('div',{staticStyle:{"width":"40px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"show-on-hover-slot",attrs:{"icon":""},on:{"click":function($event){return _vm.onCopyPid(item.pid)}}},on),[_c('v-icon',[_vm._v(" mdi-clipboard-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.dataSource.copyPid')))])])],1)],1)]}}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('ui.dataTable.noData'))+" ")])],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }