<template>
    <v-container fluid class="app-container">
        <app-title-bar
                :title="page.title" :items="page.items">
        </app-title-bar>
        <v-row class="mt-4">
            <v-col>
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2>{{$t('ui.code.code')}} :</h2>
                            <span>{{code.code}}</span>
                        </v-col>
                        <v-icon x-large color="primary">
                            sort_by_alpha
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-4">
            <v-col cols="12" sm="6" lg="4">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2>{{$t('ui.code.amount')}} :</h2>
                            <span>{{code.amount}}</span>
                        </v-col>
                        <v-icon x-large color="success">
                            local_atm
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2>{{$t('ui.code.quantity')}} :</h2>
                            <span>{{code.quantity}}</span>
                        </v-col>
                        <v-icon x-large color="warning">
                            dialpad
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="4" v-if="code.userQuantity">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2>{{$t('ui.code.userQuantity')}} :</h2>
                            <span>{{code.userQuantity}}</span>
                        </v-col>
                        <v-icon x-large color="error">
                            recent_actors
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" lg="4" v-if="code.type">
                <v-card class="pa-6">
                    <v-row no-gutters>
                        <v-col>
                            <h2>{{$t('ui.code.codeType')}} :</h2>
                            <span v-if="code.type==='percent'">{{$t('ui.code.percent')}}</span>
                            <span v-else>{{$t('ui.code.flat')}}</span>
                        </v-col>
                        <v-icon v-if="code.type==='flat'" x-large color="error">
                            trending_flat
                        </v-icon>
                        <v-icon v-else x-large color="error">
                            donut_small
                        </v-icon>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="mt-4" v-if="!loading" elevation="2">
            <v-card-text>
                <v-row class="mt-4">
                    <div style="width:100%">
                        <line-chart :height="372" :chart-data="chartTotal.data" :options="chartTotal.options"></line-chart>
                    </div>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-8">
            <ekx-loading class="mt-8" v-model="loading"></ekx-loading>
        </v-card>
    </v-container>
</template>

<script>
    import {LineChart} from "@/utils/Charts"
    import EkxLoading from "@/modules/core/components/EkxLoading"

    export default {

        components: {
            LineChart,
            EkxLoading
        },

        data() {
            return {
                codePid: this.$route.params.codePid,
                isPromotion: this.$route.query.isPromotion,
                code: null,
                loading: false,

                //graph
                chartTotal: {
                    data: {
                        labels: [],
                        datasets: [
                            {
                                label: this.$t('ui.code.graph'),
                                borderColor: "rgb(255,152,0)",
                                borderWidth: 4,
                                backgroundColor: 'rgb(255,152,0,0.2)',
                                data: []
                            }
                        ]
                    },
                    options: {
                        maintainAspectRatio: false,
                        elements: {
                            line: {
                                tension: 0
                            }
                        }
                    }
                },

                page: {
                    title: this.$t('ui.code.detail'),
                    items: [
                        {
                            text: this.$t('ui.code.codes'),
                            exact: true,
                            to: '/admin/codes'
                        }, {
                            text: this.$t('ui.code.detail'),
                        }
                    ]
                },
            }
        },

        methods: {

            async getCodeDetail() {
                this.loading = true;
                try {
                    let result = JSON.parse(this.isPromotion) ? await this.$http.get(`admin/promotionCodes/${this.codePid}`) : await this.$http.get(`admin/creditCodes/${this.codePid}`);
                    this.code = result.data;
                    this.getCodeUsage();
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.$error.notify(error)
                }
            },

            async getCodeUsage() {
                this.loading = true;
                try {
                    let result = JSON.parse(this.isPromotion) ? await this.$http.get(`admin/promotionCodes/${this.codePid}/statistic`) : await this.$http.get(`admin/creditCodes/${this.codePid}/statistic`);

                    let labels = [];
                    let monthDays = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15",
                        "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27"];

                    for (let i = 0; i < new Date().getDate(); i++) {
                        labels.push(await this.monthDayControl(monthDays)[i]);
                    }

                    let data = [];
                    let labelList = [];

                    result.data.codeTimeList.forEach(time => {
                        labels.forEach((item, index) => {
                            if (isNaN(data[index])) {
                                data[index] = 0;
                            }
                            let condition = new Date(time).getDate() === index + 1;
                            if (condition) {
                                if (this.period === 0) {
                                    data[index] += 1;
                                } else {
                                    data[index] += 1;
                                }
                            }
                        });
                    });

                    let dateList =  await this.monthDayControl(monthDays);
                    for (let i = 0; i < dateList.length; i++) {
                        labelList.push(dateList[i]);
                    }
                    this.loading = false;
                    this.renderLineChart(labelList, data)
                } catch (error) {
                    this.loading = false;
                    this.$error.notify(error)
                }
            },

            async monthDayControl(monthDays) {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async resolve => {
                    let monthDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();

                    if (monthDay > monthDays.length - 1) {
                        monthDays.push(monthDays.length.toString());
                        monthDays = await this.monthDayControl(monthDays);
                    } else {
                        resolve(monthDays);
                    }
                })
            },

            renderLineChart(labels, data) {
                this.chartTotal = {
                    data: {
                        labels: labels,
                        datasets: [
                            {
                                label: this.$t('ui.code.graph'),
                                borderColor: "rgb(255,152,0)",
                                borderWidth: 4,
                                backgroundColor: 'rgb(255,152,0,0.2)',
                                data: data
                            }
                        ]
                    },
                    options: {
                        maintainAspectRatio: false,
                        elements: {
                            line: {
                                tension: 0
                            }
                        }
                    }
                }
            }
        },
        mounted() {
            this.getCodeDetail()
        }
    }

</script>