var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"app-container",attrs:{"fluid":""}},[_c('app-title-bar',{attrs:{"title":_vm.$t('ui.sidebar.feedbacks')}}),_c('v-card',{staticClass:"mt-6"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{staticClass:"app-list b-r-3",attrs:{"headers":_vm.headers,"items":_vm.feedbackList,"item-key":"pid","page":_vm.query.pagination.currentPage,"items-per-page":_vm.query.pagination.limit,"footer-props":_vm.tableFooterProps,"sort-by":_vm.query.sort.field,"sort-desc":_vm.query.sort.order,"server-items-length":_vm.query.pagination.totalCount},on:{"update:page":function($event){return _vm.$set(_vm.query.pagination, "currentPage", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.query.pagination, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.query.pagination, "limit", $event)},"update:sortBy":function($event){return _vm.$set(_vm.query.sort, "field", $event)},"update:sort-by":function($event){return _vm.$set(_vm.query.sort, "field", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.query.sort, "order", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.query.sort, "order", $event)},"click:row":_vm.feedbackDetail},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString())+" ")]}},{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"width":"125px","color":item.key === 'BUG_FIX'? "error" : item.key === 'FEATURE' ? "info" : "success"}},[(item.key === 'BUG_FIX')?_c('span',[_vm._v(_vm._s(_vm.$t('ui.feedback.bugFix')))]):_vm._e(),(item.key === 'FEATURE' )?_c('span',[_vm._v(_vm._s(_vm.$t('ui.feedback.feature')))]):_vm._e(),(item.key === 'IMPROVEMENT' )?_c('span',[_vm._v(_vm._s(_vm.$t('ui.feedback.improvement')))]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.feedbackDetail(item)}}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.general.detail')))])])]}}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('ui.dataTable.noData'))+" ")])],2)],1)],1),_c('feedback-detail',{attrs:{"feedback":_vm.selectedFeedback},on:{"close":_vm.close},model:{value:(_vm.feedbackDetailDialog),callback:function ($$v) {_vm.feedbackDetailDialog=$$v},expression:"feedbackDetailDialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }