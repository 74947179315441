<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-row justify="center">
        <v-dialog v-model="value" max-width="600px" :persistent="true">
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title> {{$t('ui.user.dialog.changePassword')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-container class="pa-0" v-if="user">
                    <v-col class="pa-6">
                        <ValidationObserver ref="changePasswordForm">
                            <ValidationProvider :rules="currentUser.parent || user.pid === currentUser.pid ? 'required|min:5|max:12' : false" v-slot="{ errors }">
                                <v-text-field v-if="currentUser.parent || user.pid === currentUser.pid"
                                              v-model="currentPassword"
                                              :error-messages="errors"
                                              :maxLength="12"
                                              :type="showCurrent ? 'text' : 'password'"
                                              :label="$t('ui.user.dialog.currentPassword')">
                                    <template slot="append-outer">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{on}">
                                                <v-icon @click="showCurrent = !showCurrent" v-on="on"> {{showCurrent ? 'mdi-eye' :
                                                    'mdi-eye-off'}}
                                                </v-icon>
                                            </template>
                                            <span>{{showCurrent ? $t('ui.general.show') : $t('ui.general.dontShow') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                            <ValidationProvider rules="required|min:4|max:64" v-slot="{ errors }">
                                <v-text-field v-model="newPassword"
                                              :error-messages="errors"
                                              :maxLength="12"
                                              :type="showNew ? 'text' : 'password'"
                                              :rules="newPassword ? passwordRules : false"
                                              :label="$t('ui.user.dialog.newPassword')">
                                    <template slot="append-outer">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{on}">
                                                <v-icon @click="showNew = !showNew" v-on="on"> {{showNew ? 'mdi-eye' :
                                                    'mdi-eye-off'}}
                                                </v-icon>
                                            </template>
                                            <span>{{showNew ? $t('ui.general.show') : $t('ui.general.dontShow') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                            <ValidationProvider rules="required|min:4|max:12" v-slot="{ errors }">
                                <v-text-field v-model="confirmPassword"
                                              :maxLength="12"
                                              :rules="confirmPassword ? passwordRules : false"
                                              :error-messages="errorMessage ? errorMessage : errors"
                                              :type="showConfirm ? 'text' : 'password'"
                                              :label="$t('ui.user.dialog.confirmPassword')">
                                    <template slot="append-outer">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{on}">
                                                <v-icon @click="showConfirm = !showConfirm" v-on="on"> {{showConfirm ? 'mdi-eye' :
                                                    'mdi-eye-off'}}
                                                </v-icon>
                                            </template>
                                            <span>{{showConfirm ? $t('ui.general.show') : $t('ui.general.dontShow') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-text-field>

                            </ValidationProvider>
                        </ValidationObserver>
                    </v-col>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" color="warning" @click="close()"> {{$t('ui.general.cancel')}}</v-btn>
                    <v-btn class="mx-1" color="primary" :loading=loading @click="changePassword()">
                        {{$t('ui.general.change')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>

    export default {
        props: ["value", "user"],
        data() {
            return {
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                currentUser: this.$store.getters["core/getUserInfo"],
                newPassword: null,
                confirmPassword: null,
                currentPassword: null,
                loading: null,
                showCurrent: false,
                showNew: false,
                showConfirm: false,
                errorMessage: null,
                passwordRules: [
                    v => (v && v.length >= 4) || this.$t('ui.user.passwordRules.min'),
                    v => (v && v.length <= 12) || this.$t('ui.user.passwordRules.max'),
                    v => /(?=.*[A-Z])/.test(v) || this.$t('ui.user.passwordRules.uppercase'),
                    v => /(?=.*[a-z])/.test(v) || this.$t('ui.user.passwordRules.lowercase'),
                    v => /(?=.*\d)/.test(v) || this.$t('ui.user.passwordRules.number'),
                    v => /([!@$%*./-])/.test(v) || this.$t('ui.user.passwordRules.special')
                ]
            }
        },
        watch: {
            confirmPassword: function (val) {
                this.confirmPassword = val;
                this.errorMessage = null;
            },
            newPassword: function (val) {
                this.newPassword = val;
                this.errorMessage = null;
            },
            currentPassword: function (val) {
                this.currentPassword = val;
                this.errorMessage = null;
            },
        },
        methods: {
            changePassword: async function () {
                if (await this.$refs.changePasswordForm.validate() && !this.errorMessage) {
                    let user = {
                        password: this.newPassword,
                        currentPassword: this.currentPassword
                    };
                    this.loading = true;
                    let available = await this.isSame();
                    if (available) {
                        this.errorMessage = null;
                        try {
                            await this.$http.patch(`${this.urlPrefix}/users/${this.user.pid}`, {userInfo:user});
                            this.$snotify.success(this.$t('ui.general.snotify.changePassword'));
                            this.$refs.changePasswordForm.reset();
                            this.close();
                            this.$emit('refresh');
                        } catch (error) {
                            this.$error.notify(error)
                        } finally {
                            this.loading = false;
                        }
                    } else {
                        this.errorMessage = this.$t('ui.general.snotify.passwordError');
                        this.loading = false
                    }
                } else {
                    this.$snotify.warning(this.$t('ui.general.snotify.warning'));
                    this.loading = false
                }
            },
            async isSame() {
                return this.confirmPassword === this.newPassword
            },
            close() {
                this.clear();
                this.$refs.changePasswordForm.reset();
                this.$emit("close", true);
            },
            clear() {
                this.currentPassword = null;
                this.newPassword = null;
                this.confirmPassword = null;
                this.errorMessage = null;
            },
        },
    };
</script>