<template>
    <v-container fluid class="app-container">
        <app-title-bar v-if="!adminUserList"
                       :title="page.title">
        </app-title-bar>

        <v-card :class="adminUserList ? 'elevation-0' : 'mt-6' ">
            <v-col>
                <v-row no-gutters align="center">
                    <v-col v-if="urlPrefix !=='/admin'">
                        <v-btn large class="elevation-3" color="primary" @click="createSubUserDialog=true">
                          <span class="white--text" align="center">
                           {{$t('ui.user.dialog.createSubUser')}}
                          </span>
                        </v-btn>
                    </v-col>
                    <v-col class="mr-2">
                        <search-bar
                                :searchText.sync="userSearch"
                                :searchLabel="searchLabel"
                                :noSort="true"
                                @cleanAllFilters="cleanAllFilters"
                                @refresh="getUserList()"
                        ></search-bar>
                    </v-col>
                </v-row>
            </v-col>
            <v-row>
                <v-col>
                    <v-data-table
                            id="table"
                            :headers="headers"
                            :items="userList"
                            item-key="name"
                            :items-per-page.sync="query.pagination.limit"
                            :server-items-length="query.pagination.totalCount"
                            :page.sync="query.pagination.currentPage"
                            :options.sync="tableOptions"
                            :footer-props="tableFooterProps"
                            :loading="loading"
                            class="app-simple"
                            @click:row="goUserDetail"
                    >
                        <template slot="no-data">
                            {{$t('ui.dataTable.noData')}}
                        </template>
                        <template v-slot:item.parent.name="{ item }">
                            <span v-if="item.parent"> {{item.parent.name}}</span>
                            <span v-else>-</span>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="assignPermission(item)">
                                        <v-icon>mdi-wallet-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.wallet.authorizedWallets')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="goUserDetail(item)">
                                        <v-icon>equalizer</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.detail')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="openDeleteItemDialog(item)">
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span> {{$t('ui.general.delete')}}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
        <wallet-permission-dialog :user="selectedUser" v-model="walletPermissionDialog"
                                  @close="close()"></wallet-permission-dialog>

        <create-sub-user-dialog @refresh="refresh()" @close="close()"
                                v-model="createSubUserDialog"></create-sub-user-dialog>
        <delete-item-dialog
                v-model="deleteItemDialogStatus"
                :loading="deleteItemDialogLoading"
                :title="$t('ui.user.delete')"
                :text="$t('ui.user.deleteText')"
                @delete="deleteUser">
        </delete-item-dialog>
    </v-container>
</template>

<script>
    import SearchBar from "@/modules/core/components/SearchBar";
    import Pagination from "@/modules/core/mixins/pagination.js";
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";
    import WalletPermissionDialog from "@/modules/wallet/components/dialogs/WalletPermissionDialog";
    import CreateSubUserDialog from "@/modules/user/components/dialogs/CreateSubUserDialog";
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import {store} from "ekx-vue-base";

    export default {
        props: ['adminUserList'],
        components: {
            SearchBar,
            CreateSubUserDialog,
            DeleteItemDialog,
            WalletPermissionDialog
        },
        mixins: [
            new Pagination({
                methodName: "getUserList",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    userSearch: "=lk="
                },
                searchMirror: {
                    userSearch: {
                        path: "userSearch"
                    }
                }
            })
        ],
        data() {
            return {
                page: {
                    title: this.$t('ui.sidebar.users')
                },
                sortField: "",
                sortOrder: "",
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                userList: [],
                totalCount: null,
                createSubUserDialog: false,
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,
                walletPermissionDialog: false,
                selectedUser: null,
                rowClick: true,

                //pagination
                searchLabel: this.$t('ui.searchBar.user'),
                tableOptions: {},

                //search
                userSearch: "",
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                headers: [
                    {
                        text: this.$t('ui.user.username'),
                        value: "username",
                        width: "200"
                    },
                    {
                        text: this.$t('ui.user.name'),
                        value: "name",
                        align: "start",
                        width: "200"
                    },
                    {
                        text: this.$t('ui.user.surname'),
                        value: "surname",
                        width: "200"
                    },
                    {
                        text: this.$t('ui.user.parent'),
                        value: "parent.name",
                        sortable: false,
                        width: "200"
                    },
                    {
                        text: this.$t('ui.user.email'),
                        value: "email",
                        width: "200"
                    },
                    {text: this.$t('ui.general.action'), value: "actions", width: "120", sortable: false}
                ]
            };
        },

        created() {
            EkxDebounce.watch(this, "userSearch", 500, () => {
                this.query.search.userSearch.value = this.userSearch;
            });
        },
        watch: {
            "tableOptions.sortBy"() {
                this.query.sort.field = this.tableOptions.sortBy[0];
                this.query.sort.order = this.tableOptions.sortDesc[0] ? "desc" : "asc"
            }
        },
        methods: {

            async getUserList() {
                if (this.adminUserList) {
                    this.userList = this.adminUserList
                }else {
                    let query = this.createQuery();
                    try {
                        let response = await this.$http
                            .get(`${this.urlPrefix}/users?${query}`);
                        this.userList = response.data.items;
                        this.totalCount = response.data.meta.pagination.totalCount;
                        this.query.pagination = response.data.meta.pagination
                    } catch (error) {
                        this.$error.notify(error)
                    }
                }
            },

            goUserDetail(item) {
                if (this.rowClick) {
                    if (this.adminUserList) {
                        this.$emit("goUserDetail", item)
                    }
                    this.$router.push(`${this.urlPrefix}/users/${item.pid}`).catch(() => {
                    });
                }
                this.rowClick = true;
            },

            openDeleteItemDialog(item) {
                this.rowClick = false;
                this.selectedUser = item;
                this.deleteItemDialogStatus = true
            },

            async deleteUser() {
                try {
                    this.deleteItemDialogLoading = true;
                    await this.$http.delete(`${this.urlPrefix}/users/${this.selectedUser.pid}`);
                    this.$snotify.success(this.$t('ui.user.deleteSuccess'));
                    this.getUserList();
                } catch (error) {
                    this.$error.notify(error);
                } finally {
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                }
            },

            async assignPermission(item) {
                this.rowClick = false;
                this.selectedUser = item;
                this.walletPermissionDialog = true;
            },

            cleanAllFilters() {
                this.userSearch = "";
                this.query.sort.field = "";
                this.getUserList()
            },

            refresh() {
                this.rowClick = true;
                this.createSubUserDialog = false;
                this.getUserList();
            },

            close() {
                this.createSubUserDialog = false;
                this.walletPermissionDialog = false;
            },

        },

        mounted() {
            this.query.pagination.limit = this.$store.state.core.pageCount || 10;
            this.getUserList();
        }
    };
</script>