

export default {
    parseFile: (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
                reader.onload = e =>{
                   resolve(e.target.result);
            };
            return reader.readAsText(file);
        })
    }
};