<template>
    <v-card class="mt-6">
        <v-col>
            <v-row no-gutters align="center">
                <v-col v-if="userInfo && !userInfo.parent">
                    <v-btn large color="primary" class="white--text" @click="createSubWalletDialog=true">
                        {{$t('ui.wallet.create')}}
                    </v-btn>
                </v-col>
                <v-col class="mr-2">
                    <search-bar
                            :searchText.sync="nameSearch"
                            :searchLabel="searchLabel"
                            :noSort="true"
                            @cleanAllFilters="cleanAllFilters"
                            @toggleSortOrder="toggleSortOrder"
                            @refresh="getSubWallets()"
                    ></search-bar>
                </v-col>
            </v-row>
        </v-col>
        <v-row class="pa-0">
            <v-container fluid>
                <v-data-table :headers="headers"
                              :items="subWallets"
                              item-key="name"
                              :footer-props="tableFooterProps"
                              :page.sync="query.pagination.currentPage"
                              :items-per-page.sync="query.pagination.limit"
                              :server-items-length="query.pagination.totalCount"
                              :options.sync="tableOptions"
                              class="app-list" @click:row="subWalletDetail">
                    <template slot="no-data">
                        {{$t('ui.dataTable.noData')}}
                    </template>
                    <template v-slot:item.balance="{ item }">
                        <span>{{item.balance}} {{$t('ui.wallet.credit')}}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-row>
                            <v-tooltip v-if="userInfo && !userInfo.parent" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="assignPermission(item)">
                                        <v-icon>mdi-account</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.wallet.authorizedUsers')}}</span>
                            </v-tooltip>
                            <v-tooltip v-if="userInfo && !userInfo.parent" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="loadBalance(item)">
                                        <v-icon>file_upload</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.wallet.loadBalance')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="subWalletDetail(item)">
                                        <v-icon>equalizer</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.general.detail')}}</span>
                            </v-tooltip>
                            <v-tooltip v-if="userInfo && !userInfo.parent" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="balanceReset(item)" v-bind="attrs"
                                           v-on="on">
                                        <v-icon>
                                            sync
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.wallet.resetBalance')}}</span>
                            </v-tooltip>
                            <v-tooltip v-if="userInfo && !userInfo.parent" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="openDeleteItemDialog(item)" v-bind="attrs"
                                           v-on="on">
                                        <v-icon>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ui.wallet.delete')}}</span>
                            </v-tooltip>
                        </v-row>
                    </template>
                </v-data-table>
            </v-container>
        </v-row>

        <load-balance-dialog @refresh="refresh()" @close="close()" :wallet="selectedSubWallet" :userInfo="userInfo"
                             :parentWallet="wallet"
                             v-model="loadBalanceDialog"></load-balance-dialog>
        <create-sub-wallet-dialog @refresh="refresh()" @close="close()" :wallet="wallet" :userInfo="userInfo"
                                  v-model="createSubWalletDialog"></create-sub-wallet-dialog>
        <reset-balance-dialog v-model="resetBalanceDialog" :wallet="selectedSubWallet" @close="close()"
                              @refresh="refresh()"></reset-balance-dialog>
        <delete-item-dialog
                v-model="deleteItemDialogStatus"
                :loading="deleteItemDialogLoading"
                :title="$t('ui.wallet.delete')"
                :text="$t('ui.wallet.deleteText')"
                @delete="deleteSubWallet">
        </delete-item-dialog>
        <wallet-permission-dialog :wallet="selectedSubWallet" v-model="walletPermissionDialog"
                                  @close="close()"></wallet-permission-dialog>
    </v-card>

</template>

<script>

    import SearchBar from "@/modules/core/components/SearchBar";
    import CreateSubWalletDialog from "@/modules/wallet/components/dialogs/CreateSubWalletDialog";
    import LoadBalanceDialog from "@/modules/wallet/components/dialogs/LoadBalanceDialog";
    import ResetBalanceDialog from "@/modules/wallet/components/dialogs/ResetBalanceDialog";
    import WalletPermissionDialog from "@/modules/wallet/components/dialogs/WalletPermissionDialog";
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import Pagination from "@/modules/core/mixins/pagination.js";
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";
    import {store} from "ekx-vue-base";

    export default {
        components: {
            CreateSubWalletDialog,
            LoadBalanceDialog,
            DeleteItemDialog,
            ResetBalanceDialog,
            WalletPermissionDialog,
            SearchBar
        },
        mixins: [
            new Pagination({
                methodName: "getSubWallets",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    name: "=lk="
                },
                searchMirror: {
                    name: {
                        path: "nameSearch"
                    }
                }
            })
        ],
        data() {
            return {
                userPid: this.$route.meta.permission === 'admin' ? this.$route.params.userPid
                    : (this.$store.getters['core/getUserInfo']).pid,
                userInfo: null,
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                rowClick: true,

                //wallet
                subWallets: [],
                wallet: null,
                selectedSubWallet: null,

                //dialogs
                createSubWalletDialog: false,
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,
                loadBalanceDialog: false,
                walletPermissionDialog: false,
                resetBalanceDialog: false,
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                tableOptions: {},

                //search
                nameSearch: "",
                searchLabel: this.$t('ui.searchBar.wallet'),

                headers: [
                    {
                        text: this.$t('ui.general.header.name'),
                        value: 'name',
                        align: "start",
                        width: "500"
                    },
                    {
                        text: this.$t('ui.general.header.balance'),
                        value: 'balance',
                        width: "500"

                    },
                    {text: this.$t('ui.general.header.actionText'), sortable: false, value: 'actions', width: "200"}
                ],

            };
        },
        created() {
            EkxDebounce.watch(this, "nameSearch", 500, () => {
                if (this.nameSearch !== "") {
                    this.query.search.name.value = this.nameSearch;
                } else {
                    this.query.search.name.value = "";
                }
            });
        },
        watch: {
            "tableOptions.sortBy"() {
                this.query.sort.field = this.tableOptions.sortBy[0];
                this.query.sort.order = this.tableOptions.sortDesc[0] ? "desc" : "asc"
            }
        },
        methods: {
            async getSubWallets() {
                await this.getUser();
                try {
                    let query = this.createQuery();
                    this.subWallets = [];
                    let response = this.userInfo.parent ? await this.$http.get(`${this.urlPrefix}/users/${this.userInfo.pid}/wallets?permission=true&${query}`) :
                        await this.$http.get(`${this.urlPrefix}/users/${this.userInfo.pid}/wallets?only-subWallets=true&${query}`);
                    this.subWallets = response.data.items;
                    this.query.pagination = response.data.meta.pagination;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async getUser() {
                try {
                    this.userInfo = (await this.$http.get(`${this.urlPrefix}/users/${this.userPid}`)).data;
                    if (this.userInfo.parent) {
                        this.headers[2].width = "10";
                    }
                    this.wallet = this.userInfo.wallet.filter(function (item) {
                        return item.parentId === null
                    })[0];
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            subWalletDetail(item) {
                if (this.rowClick) {
                    this.$router.push(`${this.urlPrefix}/wallets/${item.pid}`).catch(() => {
                    });
                }
                this.rowClick = true;
            },

            async balanceReset(item) {
                this.rowClick = false;
                if (item.balance > 0) {
                    this.resetBalanceDialog = true;
                    this.selectedSubWallet = item
                } else {
                    this.$snotify.warning(this.$t('ui.wallet.balanceWarning'));
                }
            },

            async loadBalance(item) {
                this.rowClick = false;
                this.loadBalanceDialog = true;
                this.selectedSubWallet = item
            },

            openDeleteItemDialog(item) {
                this.rowClick = false;
                this.selectedSubWallet = item;
                this.deleteItemDialogStatus = true
            },

            async deleteSubWallet() {
                try {
                    this.deleteItemDialogLoading = true;
                    await this.$http.delete(`${this.urlPrefix}/wallets/${this.selectedSubWallet.pid}`);
                    this.$snotify.success(this.$t('ui.wallet.deleteSuccess'));
                    this.getSubWallets();
                    this.$emit('refresh');
                } catch (error) {
                    this.$error.notify(error);
                } finally {
                    this.rowClick = true;
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                }
            },

            async assignPermission(item) {
                this.rowClick = false;
                this.selectedSubWallet = item;
                this.walletPermissionDialog = true;
            },

            cleanAllFilters() {
                this.nameSearch = "";
                this.getSubWallets();
            },

            refresh() {
                this.rowClick = true;
                this.createSubWalletDialog = false;
                this.resetBalanceDialog = false;
                this.loadBalanceDialog = false;
                this.getSubWallets();
                this.$emit('refresh');
            },
            close() {
                this.createSubWalletDialog = false;
                this.resetBalanceDialog = false;
                this.loadBalanceDialog = false;
                this.walletPermissionDialog = false;
            },
        },
        mounted() {
            this.getSubWallets();
        },
    };
</script>

<style scoped>
</style>