<template>
    <v-row justify="center">
        <v-dialog v-model="value" width="800px" height="800px" persistent>
            <v-card>
                <v-toolbar class="elevation-1 base primary" dark>
                    <v-toolbar-title>{{$t('ui.feedback.feedback')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="close()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{$t('ui.general.close')}}
                    </v-tooltip>
                </v-toolbar>
                <v-card-text class="mt-4" style="max-height: 700px; overflow: auto;">
                {{message}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" color="warning" @click="close()">{{$t('ui.general.cancel')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    export default {
        props: ['value', 'feedback'],
        data() {
            return {
                subject: null,
                message: null,
            }
        },
        watch: {
            value:function () {
                this.message = this.feedback.message;
                this.subject = this.feedback.key
            }
        },
        methods: {
           close(){
               this.$emit("close", true);
           }
        },
    };
</script>
