<template>
    <v-card class="mt-6">
        <v-col>
            <v-row no-gutters align="center">
                <v-col v-if="urlPrefix !== '/admin'">
                    <v-btn large color="primary" class="white--text" @click="walletPermissionDialog=true">
                        {{$t('ui.wallet.addUser')}}
                    </v-btn>
                </v-col>
                <v-col class="mr-2">
                    <search-bar
                            :searchText.sync="nameSearch"
                            :searchLabel="searchLabel"
                            :noSort="true"
                            @cleanAllFilters="cleanAllFilters"
                            @toggleSortOrder="toggleSortOrder"
                            @refresh="getWalletUsers()"
                    ></search-bar>
                </v-col>
            </v-row>
        </v-col>
        <v-row class="pa-0">
            <v-container fluid>
                <v-data-table  :headers="userHeaders"
                              :items="walletUserList"
                              item-key="name"
                              :footer-props="tableFooterProps"
                              :page.sync="query.pagination.currentPage"
                              :items-per-page.sync="query.pagination.limit"
                              :server-items-length="query.pagination.totalCount"
                              :options.sync="tableOptions"
                              class="app-list">
                    <template slot="no-data">
                        {{$t('ui.dataTable.noData')}}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="openDeleteItemDialog(item)" v-bind="attrs"
                                       v-on="on">
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('ui.wallet.deletePermission')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-container>
        </v-row>
        <delete-item-dialog
                v-model="deleteItemDialogStatus"
                :loading="deleteItemDialogLoading"
                :title="$t('ui.wallet.deletePermission')"
                :text="$t('ui.wallet.deletePermissionText')"
                @delete="deletePermission">
        </delete-item-dialog>
        <wallet-permission-dialog :wallet="wallet" :walletDetail="true" v-model="walletPermissionDialog"
                                  @refresh="refresh()" @close="close()"></wallet-permission-dialog>
    </v-card>

</template>

<script>

    import SearchBar from "@/modules/core/components/SearchBar";
    import WalletPermissionDialog from "@/modules/wallet/components/dialogs/WalletPermissionDialog";
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import Pagination from "@/modules/core/mixins/pagination.js";
    import EkxDebounce from "@/modules/core/utils/EkxDebounce";
    import {store} from "ekx-vue-base";

    export default {
        props: ['wallet'],
        components: {
            DeleteItemDialog,
            WalletPermissionDialog,
            SearchBar
        },
        mixins: [
            new Pagination({
                methodName: "getWalletUsers",
                pagination: {
                    limit: store.state.core.pageCount
                },
                search: {
                    name: "=lk="
                },
                searchMirror: {
                    name: {
                        path: "nameSearch"
                    }
                }
            })
        ],
        data() {
            return {
                //wallet
                userList: [],
                walletUserList: [],
                selectedItem: null,
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',

                //dialogs
                createSubWalletDialog: false,
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,
                walletPermissionDialog: false,
                searchText: "",
                tableFooterProps: {
                    "items-per-page-options": [5, 10, 15, 100],
                    'items-per-page-text': this.$t('$vuetify.dataTable.itemsPerPageText'),
                    'page-text': this.$t('$vuetify.dataFooter.pageText')
                },
                tableOptions: {},

                //search
                nameSearch: "",
                searchLabel: this.$t('ui.searchBar.user'),

                userHeaders: [
                    {
                        text: this.$t('ui.general.header.name'),
                        value: 'name',
                        align: "start",
                        width: "500"
                    },
                    {
                        text: this.$t('ui.user.surname'),
                        value: 'surname',
                        width: "500"

                    },
                    {
                        text: this.$t('ui.user.email'),
                        value: 'email',
                        width: "500"

                    },
                    {
                        text: this.$t('ui.general.header.actionText'),
                        sortable: false,
                        value: 'actions',
                        width: "200",
                        align: this.$route.meta.permission !== 'admin' ?  null : ' d-none'
                    }
                ],

            };
        },
        watch: {

            "tableOptions.sortBy"() {
                this.query.sort.field = this.tableOptions.sortBy[0];
                this.query.sort.order = this.tableOptions.sortDesc[0] ? "desc" : "asc"
            }
        },
        created() {
            EkxDebounce.watch(this, "nameSearch", 500, () => {
                if (this.nameSearch !== "") {
                    this.query.search.name.value = this.nameSearch;
                } else {
                    this.query.search.name.value = "";
                }
            });
            EkxDebounce.watch(this, 'searchText', 500, () => {
                this.getUserList(this.searchText);
            });

        },

        methods: {
            async getWalletUsers() {
                try {
                    let query = this.createQuery();
                    let response = await this.$http.get(`${this.urlPrefix}/wallets/${this.wallet.pid}/users?${query}`);
                    this.walletUserList = response.data.items;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async getUserList(searchText) {
                await this.getWalletUsers();
                try {
                    let response = await this.$http.get(`${this.urlPrefix}/users?limit=100&q=${(searchText ? "name==" + encodeURIComponent(searchText) : "")}`);
                    response.data.items.forEach(item => {
                        if (!this.walletUserList.find(user => user.pid === item.pid)) {
                            this.userList.push(item);
                        }
                    });
                } catch (error) {
                    this.$error.notify(error);
                }
            },

            async deletePermission() {
                try {
                    await this.$http.delete(`${this.urlPrefix}/wallets/${this.wallet.pid}/users/${this.selectedItem.pid}`);
                    this.$snotify.success(this.$t('ui.wallet.successDeletePermission'));
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                    this.getUserList();
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            openDeleteItemDialog(item) {
                this.selectedItem = item;
                this.deleteItemDialogStatus = true
            },

            cleanAllFilters() {
                this.nameSearch = "";
                this.getUserList();
            },

            refresh() {
                this.walletPermissionDialog = false;
                this.getUserList();
            },

            close() {
                this.walletPermissionDialog = false;
            }
        },
        mounted() {
            this.getUserList();
        },
    };
</script>

<style scoped>
</style>