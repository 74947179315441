<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-container fluid class="app-container">
        <app-title-bar v-if="showPlans"
                       :title="$t('ui.packet.header')">
        </app-title-bar>
        <v-row no-gutters class="mt-6"
               v-if="showPlans">
            <v-col class="mr-4">
                <v-card class="elevation-4" height="300">
                    <v-card-title class="justify-center">
                        <span style="font-size: xx-large;">{{$t('ui.packet.first')}}</span>
                    </v-card-title>
                    <v-card-title class="justify-center mt-4">
                        <span><b style="font-size: xx-large;"
                                 :class="firstPacketPrice!==firstPacket.unit_price ? 'text-decoration-line-through' : ''">{{firstPacketPrice}}</b> TRY</span>
                    </v-card-title>
                    <v-card-title v-if="firstPacketPrice!==firstPacket.unit_price" class="justify-center mt-4">
                        <span><b style="font-size: xx-large;">{{firstPacket.unit_price}}</b> TRY</span>
                    </v-card-title>
                    <v-card-text class="text-center mt-4"
                                 style="position: absolute; bottom: 10px">
                        <v-btn class="elevation-3" color="primary" :loading="firstPacketLoading"
                               @click="loadBalance(firstPacket.id, firstPacket.unit_price, firstPacketPrice)">
                                <span class="white--text mt-1">
                                    {{$t('ui.packet.get')}}
                                </span>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="mr-4">
                <v-card class=" elevation-4" height="300">
                    <v-card-title class="justify-center">
                        <span style="font-size: xx-large;">{{$t('ui.packet.second')}}</span>
                    </v-card-title>
                    <v-card-title class="justify-center mt-4">
                        <span><b style="font-size: xx-large;"
                                 :class="secondPacketPrice!==secondPacket.unit_price ? 'text-decoration-line-through' : ''">{{secondPacketPrice}}</b> TRY</span>
                    </v-card-title>
                    <v-card-title v-if="secondPacketPrice!==secondPacket.unit_price " class="justify-center mt-4">
                        <span><b style="font-size: xx-large;">{{secondPacket.unit_price}}</b> TRY</span>
                    </v-card-title>
                    <v-card-text class="text-center mt-4"
                                 style="position: absolute; bottom: 10px">
                        <v-btn class="elevation-3" color="primary" :loading="secondPacketLoading"
                               @click="loadBalance(secondPacket.id, secondPacket.unit_price, secondPacketPrice)">
                                <span class="white--text mt-1">
                                    {{$t('ui.packet.get')}}
                                </span>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card class=" elevation-4" height="300">
                    <v-card-title class="justify-center">
                        <span style="font-size: xx-large;">{{$t('ui.packet.third')}}</span>
                    </v-card-title>
                    <v-card-title class="justify-center mt-4">
                        <span><b style="font-size: xx-large;"
                                 :class="thirdPacketPrice!==thirdPacket.unit_price  ? 'text-decoration-line-through' : ''">{{thirdPacketPrice}}</b> TRY</span>
                    </v-card-title>
                    <v-card-title class="justify-center mt-4" v-if="thirdPacketPrice!==thirdPacket.unit_price ">
                        <span><b style="font-size: xx-large;">{{thirdPacket.unit_price}}</b> TRY</span>
                    </v-card-title>
                    <v-card-text class="text-center mt-4"
                                 style="position: absolute; bottom: 10px">
                        <v-btn class="elevation-3" color="primary" :loading="thirdPacketLoading"
                               @click="loadBalance(thirdPacket.id, thirdPacket.unit_price, thirdPacketPrice)">
                                <span class="white--text mt-1">
                                    {{$t('ui.packet.get')}}
                                </span>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-card class="mt-6 pa-6" v-if="showPlans">
            <v-row>
                <v-col lg="2" md="2" sm="1" xs="2">
                    <span>{{$t('ui.wallet.promotionCode')}}</span>
                </v-col>
                <v-col>
                    <v-row>
                        <v-text-field
                                v-model="promotionCode"
                                :maxLength="50"
                                :label="$t('ui.wallet.code')"
                                :disabled="cancelPromotion"
                                @input="promotionCodeControl"
                                dense
                        ></v-text-field>
                        <v-btn width="160px" class="elevation-3 ml-4" color="primary" :disabled="cancelPromotion"
                               @click="applyDiscount()">
                                <span :class="cancelPromotion ? 'primary--text mt-1' : 'white--text mt-1'">
                                    {{$t('ui.packet.applyDiscount')}}
                                </span>
                        </v-btn>
                    </v-row>
                    <v-row v-if="cancelPromotion" class="mt-4">
                        <v-spacer></v-spacer>
                        <v-btn class="elevation-3 ml-4" color="primary"
                               @click="cancelDiscount()">
                                <span class="white--text mt-1">
                                    {{$t('ui.general.cancel')}}
                                </span>
                        </v-btn>
                    </v-row>

                </v-col>
            </v-row>
            <v-row v-if="showPlans" class="mt-6">
                <v-col lg="2" md="2" sm="1" xs="2">
                    <span>{{$t('ui.code.creditCode')}}</span>
                </v-col>
                <v-col>
                    <v-row>
                        <v-text-field
                                v-model="creditCode"
                                :maxLength="50"
                                :label="$t('ui.wallet.code')"
                                dense
                                @input="creditCodeControl"
                        ></v-text-field>
                        <v-btn width="160px" class="elevation-3 ml-4" color="primary"
                               @click="openUploadDialog">
                                <span class="white--text mt-1">
                                    {{$t('ui.packet.uploadCredit')}}
                                </span>
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>

        </v-card>
        <v-dialog v-model="paymentFormDialog" fullscreen
                  style="overflow-y: hidden">
            <v-card height="100%"
                    style="position: relative;  padding-bottom: 50px; overflow-y: auto">
                <v-toolbar style="text-align: center" dark color="primary" class="elevation-1">
                    <v-toolbar-title
                            v-text="$t('ui.wallet.paymentDialog')"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="closePayment()" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{ $t('ui.general.close') }}
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <payment-form :paymentFormDialog="paymentFormDialog" :paymentId="paymentId" :customerId="customerId"
                                :cards="cards" :amount="amount" :balance="balance"
                                :promotionCode="promotionCodeObject" :wallet="walletInfo"
                                :planId="planId" @refresh="refreshPayment()" @failed="paymentFailed()"
                    ></payment-form>                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="mr-4" @click="closePayment()">
                        {{$t('ui.general.cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <upload-credit-dialog v-if="uploadCreditDialog" v-model="uploadCreditDialog" @submit="uploadCredit"
                              @close="closeUplaodDialog"
                              :credit="creditCodeObject"></upload-credit-dialog>
        <v-snackbar v-if="userInfo.isNewUser && showPlans && userOwnCode"
                    :timeout="-1"
                    :value="true"
                    bottom
                    color="primary"
                    outlined
                    left>

            <h4>{{$t('ui.code.popUp')}}</h4>
            <span class="text-font">{{userOwnCode.code}}</span>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on"
                           @click="onCopyCode(userOwnCode.code)">
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                </template>
                <span>{{$t('ui.code.copy')}}</span>
            </v-tooltip>
        </v-snackbar>
        <v-card v-if="!showPlans">
            <ekx-loading v-model="loading"></ekx-loading>
        </v-card>
    </v-container>
</template>

<script>
    import EkxLoading from "@/modules/core/components/EkxLoading"
    import PaymentForm from "@/modules/wallet/components/dialogs/PaymentForm"
    import UploadCreditDialog from "@/modules/code/components/dialogs/UploadCreditDialog"

    export default {
        components: {
            PaymentForm,
            UploadCreditDialog,
            EkxLoading
        },

        data() {
            return {
                userInfo: this.$store.getters["core/getUserInfo"],
                urlPrefix: this.$route.meta.permission === 'admin' ? '/admin' : '',
                buttonLoading: false,
                loading: true,
                showPlans: false,
                uploadCreditDialog: false,
                codePopUP: true,
                userOwnCode: null,
                amount: null,
                cards: [],
                cardId: null,
                customerId: null,
                walletInfo: null,
                //prices
                firstPacketPrice: null,
                secondPacketPrice: null,
                thirdPacketPrice: null,
                firstPacketLoading: false,
                secondPacketLoading: false,
                thirdPacketLoading: false,

                //packets
                firstPacket: null,
                secondPacket: null,
                thirdPacket: null,
                packets: null,
                subscription: null,
                paymentFormDialog: false,
                planId: null,
                balance: null,

                //codes
                cancelPromotion: false,
                availablePromotionCode: false,
                availableCreditCode: false,
                promotionCodeObject: null,
                creditCodeObject: null,
                promotionCode: null,
                creditCode: null,
                balanceCode: null
            };
        },

        methods: {

            async getUserWalletInfo(){
                let wallet = this.userInfo.wallet.filter(function (item) {
                    return item.parentId === null
                })[0];

                this.walletInfo = (await this.$http.get(`/wallets/${wallet.pid}`)).data;
            },

            async getLoadPackets() {

                try {
                    let result = await this.$http.get(`${this.urlPrefix}/packets`);
                    this.loading = false;
                    this.firstPacket = result.data.firstPlan;
                    this.secondPacket = result.data.secondPlan;
                    this.thirdPacket = result.data.thirdPlan;

                    this.firstPacketPrice = result.data.firstPlan.unit_price;
                    this.secondPacketPrice = result.data.secondPlan.unit_price;
                    this.thirdPacketPrice = result.data.thirdPlan.unit_price;

                    this.showPlans = true;
                    this.$emit("loading");
                } catch (error) {
                    this.loading = false;
                    this.$error.notify(error);
                }
            },

            async applyDiscount() {

                if (this.availablePromotionCode) {

                    try {
                        let result = await this.$http.post('/discounts', {
                            discount: this.promotionCodeObject,
                            prices: {
                                firstPacketPrice: this.firstPacketPrice,
                                secondPacketPrice: this.secondPacketPrice,
                                thirdPacketPrice: this.thirdPacketPrice
                            }
                        });
                        this.firstPacket.unit_price = result.data.newFirstPrice < 0 ? 0 : result.data.newFirstPrice;
                        this.secondPacket.unit_price = result.data.newSecondPrice < 0 ? 0 : result.data.newSecondPrice;
                        this.thirdPacket.unit_price = result.data.newThirdPrice < 0 ? 0 : result.data.newThirdPrice;
                    } catch (error) {
                        this.$error.notify(error);
                    }

                    this.cancelPromotion = true;
                } else {
                    this.$snotify.warning(this.$t('ui.wallet.promotionWarning'));
                }
            },

            cancelDiscount() {

                this.firstPacket.unit_price = this.firstPacketPrice;
                this.secondPacket.unit_price = this.secondPacketPrice;
                this.thirdPacket.unit_price = this.thirdPacketPrice;

                this.promotionCodeObject = null;
                this.promotionCode = null;
                this.availablePromotionCode = false;

                this.cancelPromotion = false;
            },

            openUploadDialog() {
                if (this.availableCreditCode) {
                    this.uploadCreditDialog = true;
                } else {
                    this.$snotify.warning(this.$t('ui.wallet.creditWarning'));
                }
            },

            async uploadCredit() {
                this.uploadCreditDialog = false;
                try {
                    //upload price to wallet with code
                    await this.$http.patch(`/wallets/${this.walletInfo.pid}`, {
                        balance: this.creditCodeObject.amount,
                        creditCodePid: this.creditCodeObject.pid
                    });

                    this.$snotify.success(this.$t('ui.wallet.loadBalanceSuccess'));
                    this.creditCodeObject = null;
                    this.creditCode = null;
                    this.availableCreditCode = false;
                    this.$router.push(`/wallets`).catch(() => {
                    });
                } catch (error) {
                    this.$error.notify(error);
                }
            },

            async promotionCodeControl(item) {
                try {
                    let result = await this.$http.get('/promotionCodes');
                    let tempThis = this;
                    result.data.items.filter(function (discount) {
                        if (discount.code === item) {
                            tempThis.availablePromotionCode = true;
                            tempThis.promotionCodeObject = discount;
                        }
                    });
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async creditCodeControl(item) {
                try {
                    let result = await this.$http.get('/creditCodes');
                    let _this = this;
                    result.data.items.filter(function (credit) {
                        if (credit.code === item) {
                            _this.availableCreditCode = true;
                            _this.creditCodeObject = credit;
                        }
                    });

                } catch (error) {
                    this.$error.notify(error)
                }
            },

            async getPaymentSources() {
                try {
                    this.cards = [];
                    let result = await this.$http.get(`/customers/${this.userInfo.customerId}/payment-sources`);
                    this.cards = result.data.items;
                } catch (error) {
                    this.$error.notify(error);
                }
            },

            async loadBalance(id, price, balance) {
                id === this.firstPacket.id ? this.firstPacketLoading = true : (this.secondPacket.id === id ? this.secondPacketLoading = true :
                    (this.thirdPacket.id === id ? this.thirdPacketLoading = true : null));
                this.planId = id;
                this.buttonLoading = true;
                this.subscription = null;
                this.paymentId = null;
                this.balance = balance;
                try {
                    this.customerId = this.userInfo.customerId;
                    this.$store.commit("core/setCustomer", this.customerId);

                    this.subscription = await this.$http.post(`/subscriptions`, {
                        planId: id,
                        customerId: this.customerId,
                        promotionCode: this.promotionCodeObject,
                        userPid: this.userInfo.pid
                    });

                    this.firstPacketLoading = false;
                    this.secondPacketLoading = false;
                    this.thirdPacketLoading = false;

                    if(!this.subscription.data.latest_payment_id){
                        await this.$http.patch(`/wallets/${this.walletInfo.pid}`, {
                            balance: balance
                        });
                        this.$snotify.success(this.$t('ui.wallet.loadSuccess'));
                        this.$router.push(`/wallets`).catch(() => {
                        });
                    }else{
                        this.amount = this.subscription.data.last_calculated_total;

                        let payment = (await this.$http.post(`/payments`, {
                            currency: 'TRY',
                            customerId: this.customerId,
                            userPid: this.userInfo.pid,
                            walletPid: this.walletInfo.pid,
                            total: this.amount
                        })).data;

                        this.buttonLoading = false;
                        this.paymentFormDialog = true;
                        this.paymentId = payment.id;
                    }

                } catch (error) {
                    this.$error.notify(error);
                    this.firstPacketLoading = false;
                    this.secondPacketLoading = false;
                    this.thirdPacketLoading = false;
                }

            },

            async getUserOwnCode() {
                try {
                    let result = await this.$http.get(`users/${this.userInfo.pid}/code`);
                    this.userOwnCode = result.data;
                } catch (error) {
                    this.loading = false;
                    this.$error.notify(error)
                }
            },

            onCopyCode: function (key) {
                const el = document.createElement('textarea');
                el.value = key;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.$snotify.success(this.$t('ui.code.copySuccess'));
            },

            async refreshPayment() {
                this.closePayment();
                this.$snotify.success(this.$t('ui.wallet.loadSuccess'));
                this.$router.push(`/wallets`).catch(() => {
                });

            },

            paymentFailed() {
                this.$snotify.error(this.$t('ui.subscription.packet.paymentError'));
                this.paymentFormDialog = false;
                this.paymentId = null
            },

            async closePayment() {
                this.$bus.$emit('closePayment', this.cards);
                this.paymentId = null;
                this.paymentFormDialog = false;
                this.subscription = null;
                this.planId = null;
            },

            closeUplaodDialog() {
                this.uploadCreditDialog = false;
            },

        },
        mounted() {
            this.getUserWalletInfo();
            this.getLoadPackets();

            if (this.userInfo.isNewUser) {
                this.getUserOwnCode();
            }
            if (this.userInfo.customerId) {
                this.getPaymentSources();
            }
        }
    };
</script>

<style scoped>
</style>
