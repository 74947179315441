<template>
    <v-container fluid class="app-container">
        <app-title-bar
                :title="page.title" :items="page.items">
        </app-title-bar>
        <v-row class="mt-4" v-if="dataSourceInfo && dataSourceInfo.type === 1 ">
            <v-col cols="8" sm="8" lg="8">
                <v-card class="pa-8" height="500px">
                    <v-row class="mt-5">
                        <div style="width:100%">
                            <line-chart :height="372" :chart-data="chart.data" :options="chart.options"></line-chart>
                        </div>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="4" sm="4" lg="4">
                <v-card class="pa-6" height="500px">
                    <v-row no-gutters>
                        <span>Yield Data</span>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row no-gutters class="mt-6">
                        <v-col>
                            <v-simple-table fixed-header
                                            height="400px">
                                <thead>
                                <tr>
                                    <th>{{$t('ui.dataSource.valuationDate')}}</th>
                                    <th>{{$t('ui.dataSource.value')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in fileData" :key="item.value">
                                    <td>{{ item.valuationDate }}</td>
                                    <td>{{ item.value }}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-card class="mt-6">
            <v-col>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mb-4 primary" style="color: #FFFFFF" icon @click="jsonDownload()"
                               v-bind="attrs"
                               v-on="on">
                            <v-icon>
                                mdi-download
                            </v-icon>
                        </v-btn>
                    </template>
                    {{$t('ui.general.downloadJSON')}}
                </v-tooltip>
                <ace-editor v-model="fileDataObj" :plus="false" :readOnly="true" lang="json" theme="chrome"
                            height="800px" @init="editorInit"
                            class="jsonEditor " :options="editorOptions"></ace-editor>
            </v-col>
        </v-card>

        <delete-item-dialog
                v-model="deleteItemDialogStatus"
                :loading="deleteItemDialogLoading"
                :title="$t('ui.dataSource.delete')"
                :text="$t('ui.dataSource.deleteText')"
                @delete="deleteDataSource">
        </delete-item-dialog>

    </v-container>
</template>

<script>
    import DeleteItemDialog from "@/modules/core/components/dialogs/DeleteItemDialog"
    import AceEditor from 'vue2-ace-editor';
    import {LineChart} from "@/utils/Charts"

    export default {

        components: {
            DeleteItemDialog,
            AceEditor,
            LineChart,
        },

        data() {
            return {
                urlPrefix: (this.$route.meta && this.$route.meta.permission === 'admin') ? '/admin' : '',
                deleteItemDialogStatus: false,
                deleteItemDialogLoading: false,
                dataSourceInfo: null,
                fileData: null,
                fileDataObj: null,
                options: {
                    readOnly: true,
                    autoScrollEditorIntoView: true
                },
                data: [],
                page: {
                    title: null,
                    items: [
                        {
                            text: this.$t('ui.dataSource.dataSource'),
                            exact: true,
                            to: `/data-sources`,
                        },
                        {
                            text: this.$t('ui.dataSource.dataSourceDetail'),
                            exact: false
                        },
                    ]
                },

                //chart
                chart: {
                    data: {
                        labels: [],
                        datasets: [
                            {
                                label: this.$t('ui.dataSource.value'),
                                borderColor: "rgb(255,152,0)",
                                borderWidth: 4,
                                backgroundColor: 'rgb(255,152,0,0.2)',
                                style: {
                                    fontSize: '14px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 'bold',
                                    colors: 'rgb(0,0,0)'
                                },
                                data: []
                            }
                        ]
                    },
                    options: {
                        maintainAspectRatio: false,
                        elements: {
                            line: {
                                tension: 0
                            }
                        }
                    }
                }
            }
        },

        methods: {

            async getDataSource() {
                try {
                    let response = await this.$http.get(`${this.urlPrefix}/data-sources/${this.$route.params.data_source_pid}`);
                    this.dataSourceInfo = response.data;
                    // file data converted from "buffer" type to "json" type
                  //   let b = Buffer.from(this.dataSourceInfo.data, 'utf8');
                  //   let bufferData = JSON.parse(b).data;
                    this.fileData = JSON.parse(this.dataSourceInfo.data.toString('utf-8'));
                  // this.fileDataObj = JSON.stringify(this.fileData, null, '\t');
                  this.fileDataObj =  JSON.stringify(this.fileData, null, '\t');
                    if (this.dataSourceInfo.type === 1) {
                        await this.getField();
                    }
                    this.fileData.forEach((data) => {
                        if (typeof  data.valuationDate === 'number'){
                            data.valuationDate = new Date(Math.round((data.valuationDate - 25569)*86400*1000));
                            let date = JSON.stringify(data.valuationDate);
                            data.valuationDate = date.slice(1,11)
                        }else {
                          data.issueDate = new Date(Math.round((data.issueDate - 25569)*86400*1000));
                          let date = JSON.stringify(data.issueDate);
                          data.issueDate = date.slice(1,11)
                        }
                    });
                    this.page.title = response.data.name;
                } catch (error) {
                    this.$error.notify(error)
                }
            },

            getField() {
                this.chart.data.labels = [];
                if (this.fileData) {
                  this.fileData.map((data, index) => {
                        this.chart.data.datasets[0].data[index] = data.value;
                        this.chart.data.labels.push(data.valuationDate || data.issueDate)
                    })
                }
            },

            jsonDownload() {

                const blob = new Blob([this.fileDataObj], {type: 'json/plain'});
                const e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = `${ this.dataSourceInfo.name}-${this.dataSourceInfo.createdAt}.json`;
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['json', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
            },

            async deleteDataSource() {
                try {
                    this.deleteItemDialogLoading = true;
                    await this.$http.delete(`${this.urlPrefix}/data-sources/${this.dataSourceInfo.pid}`);
                    this.$snotify.success(this.$t('ui.dataSource.deleteSuccess'));
                    this.$router.push(`${this.urlPrefix}/data-sources`).catch(() => {
                    });
                } catch (error) {
                    this.$error.notify(error);
                } finally {
                    this.deleteItemDialogStatus = false;
                    this.deleteItemDialogLoading = false;
                }
            },

            refresh() {
                this.getDataSource();
            },
            editorInit:function (editor) {
                require("brace/mode/json");
                require("brace/theme/chrome");
                require('brace/ext/searchbox');
                editor.focus();
                editor.setShowPrintMargin(false);
                editor.setOptions({
                    fontSize: "18px",
                });
            },
        },
        mounted() {
            this.getDataSource();
        }
    }


</script>
