<template>
        <v-card class="dragFileUploadContainer"
                @drop.prevent="onDrop($event)"
                @dragover.prevent="dragover = true"
                @dragenter.prevent="dragover = true"
                @dragleave.prevent="dragover = false"
                :class="{hover:dragover}"
        >
            <v-card-text>
                <v-row v-if="uploadedFiles.length === 0" class="d-flex flex-column" dense align="center" justify="center">
                    <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60" @click="document.getElementById('fileInput').click()">
                        mdi-cloud-upload
                    </v-icon>
                    <p>
                        {{$t('ui.dataSource.dragDropText')}}
                    </p>
                    <p> {{$t('ui.dataSource.or')}} </p>
                    <input id="fileInput" ref="file" :accept="onlyJson ? '.json' : '.xlsx, .xls, .json'" type="file" style="display:none" v-on:change="handleFileUpload($event)"/>
                    <button id="fileInputButton" onclick="document.getElementById('fileInput').click()">{{$t('ui.dataSource.uploadFileText')}}</button>

                </v-row>
                <v-virtual-scroll
                        v-if="uploadedFiles.length > 0"
                        :items="uploadedFiles"
                        height="150"
                        item-height="50"
                >
                    <template v-slot:default="{ item }">
                        <v-list-item :key="item.name">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.name }}
                                    <span class="ml-3 text--secondary">
                    {{ item.size }} bytes</span
                                    >
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn @click.stop="removeFile(item.name)" icon>
                                    <v-icon> mdi-close-circle </v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-virtual-scroll>
            </v-card-text>
        </v-card>
</template>

<script>
    export default {
        name: "Upload",
        props: ['onlyJson'],
        data() {
            return {
                dragover: false,
                uploadedFiles: [],
                flag: false,
                file : null,
                isOverContainer: false,
            };
        },
        methods: {
            handleFileUpload (files) {
                this.isOverContainer = true;
                this.uploadedFiles.push(files.target.files[0]);
                this.$emit("fileUploaded", this.uploadedFiles);
            },
            removeFile(fileName) {
                // Find the index of the
                const index = this.uploadedFiles.findIndex(
                    file => file.name === fileName
                );
                // If file is in uploaded files remove it
                if (index > -1) this.uploadedFiles.splice(index, 1);
            },
            onDrop(e) {
                this.dragover = false;
                if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
                if (!this.multiple && e.dataTransfer.files.length > 1) {
                    this.$snotify.warning(this.$t('ui.dataSource.onlyOneFile'));
                }
                else{
                    e.dataTransfer.files.forEach(element =>
                        this.uploadedFiles.push(element)
                    );
                    this.$emit("fileUploaded", this.uploadedFiles);
                }
            }
        }
    }
</script>

<style scoped>
     .dragFileUploadContainer {
         position: center;
         top: 16px;
         right: 16px;
         left: 0;
         bottom: 0;
         border: 1px dashed #3F51B5;
         background-color: white;
         width: 100%;
         height: 250px;
     }

    .dragFileUploadContainer.hover {
        position: absolute;
        background-color: #C8E6C9;
        border: 2px dashed #E07408;
        width: 100%;
        height: 700px;
    }
</style>